import { Button, styled } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditActiveScene, RemoveLayer, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import WebsiteStore from '../../WebsiteStore';
import useCheckDpi from '../_hooks/useCheckDpi';
import useCheckWarningPoint from '../_hooks/useCheckWarningPoint';
import useLabData from '../_hooks/useLabData';
import ValidIndicator from '../_components/ValidIndicator';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import SceneTab from './SceneTab';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import useRenderingTrace from '../../Common/_hooks/useRenderingTrace';
import { useTranslation } from 'react-i18next';

export const SceneTabsContainer = styled('div')({
  padding: '10px 20px 0',
  position: 'relative',
  zIndex: 20,
  paddingTop: '10px',
  marginTop: '-10px',
  overflow: 'hidden',
  display: 'flex',
})

type Props = {}

export default function SceneSelector(props: Props) {
  const [t] = useTranslation('design-lab')
  const dispatch = useAppDispatch();

  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));
  const layers = useAppSelector(state => state.get('UIData').get('designLab').get('layers'));
  const labData = useLabData();

  const listSceneNames = useMemo(() => {
    return {
      front: t('Front'),
      back: t('Back'),
      inside: t('Inside'),
      outside: t('Outside'),
      pockets: t('Pockets'),
      legs: t('Legs'),
      band: t('Band'),
      gusset: t('Gusset'),
      elastic: t('Elastic'),
      cover: t('Cover'),
      firstpage: t('First page'),
      sock: t('Sock'),
      foldover: t('Foldover'),
      backside: t('Backside'),
    } as Record<string, string>
  }, [t])

  //Cleanup layers for scenes that don't exist when switching to a new product
  useEffect(() => {
    if (!labData?.variants[activeVariant]) return;

    const tmpScenes = Object.keys(labData.variants[activeVariant].scenes);

    layers.forEach(layer => {
      if (!tmpScenes.includes(layer.scene)) {
        dispatch(RemoveLayer(layer.get('id')));
      }
    });
  }, [labData]);

  const scenes = useMemo(() => {
    if (!productData || !labData || !labData.variants[activeVariant]) return [];

    const tmpScenes = Object.keys(labData.variants[activeVariant].scenes);

    if (tmpScenes.length === 1) {
      return [{
        slug: 'main',
        name: productData.get('name'),
      }];
    } else {
      return tmpScenes.map((scene, index) => {
        const sceneData = labData.variants[activeVariant].scenes[scene];
        let name = sceneData.name ? listSceneNames[sceneData.name] : undefined;

        return {
          slug: scene,
          name: name,
        }
      });
    }
  }, [labData, listSceneNames, activeVariant]);

  useEffect(() => {
    if (scenes.length < 1) {
      dispatch(EditActiveScene(undefined));
      return;
    }

    dispatch(EditActiveScene(scenes[0].slug));
  }, [scenes]);

  const renderScene = useCallback((scene: { slug: string, name: string }) => {
    return <SceneTab
      key={scene.slug}
      slug={scene.slug}
      name={scene.name}
    />
  }, [])

  return !productData?.get('labData')?.has_subproducts ? <SceneTabsContainer>
    {scenes.map(renderScene)}
  </SceneTabsContainer> : null
}