import { Box, Divider, Stack, styled, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Hidden from '../Common/_components/Hidden';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import FilterCard from './FilterCard';
import { useAppSelector } from '../Common/_hooks/useAppSelector';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ViewProductAttributeTypeStore from '../AppData/_stores/ViewProductAttributeTypeStore';

const StyledDrawer = styled(Drawer)({
  '.MuiDrawer-paperAnchorBottom': {
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    margin: '0 auto',
    width: '95%',
    maxHeight: '85%',
  },

  '.drawer-content': {
    padding: '22px 20px',
  }
});

export default function Filters() {
  const [t] = useTranslation('products')
  const viewProductAttributeTypes = useAppSelector(state => state.get('appData').get('viewProductAttributeTypes'));
  const viewProductAttributeValues = useAppSelector(state => state.get('appData').get('viewProductAttributeValues'));

  const [displayDrawer, setDisplayDrawer] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();

  const getTypeFilters = useCallback((typeId: number) => {
    const type = viewProductAttributeTypes.get(String(typeId));
    if (!type) return [];

    const filterSearchParam = searchParams.get(type.get('slug'));
    return filterSearchParam ? filterSearchParam.split(',') : [];
  }, [searchParams, viewProductAttributeTypes])

  const onFilterClick = (typeId: number, valueId: number) => {
    const type = viewProductAttributeTypes.get(String(typeId));
    if (!type) return;

    const valueIdString = String(valueId);

    let filters = getTypeFilters(typeId)

    if (!filters.includes(valueIdString)) {
      filters.push(valueIdString)
    } else {
      filters.splice(filters.indexOf(valueIdString), 1)
    }

    if (filters.length === 0) {
      searchParams.delete(type.get('slug'));
    } else {
      searchParams.set(type.get('slug'), filters.join(','))
    }

    setSearchParams(searchParams)
  }

  const renderType = useCallback((type: ViewProductAttributeTypeStore) => {
    return <Stack spacing={2} key={type.get('id')}>
      <Typography variant="h4">{type.get('name')}</Typography>
      <Stack spacing={1}>
        {viewProductAttributeValues.filter(v => v.get('type') == type.get('id')).valueSeq().map(value => {
          const filters = getTypeFilters(type.get('id'));
          const selected = filters.includes(String(value.get('id')));
          return <div key={value.get('id')} onClick={(e: React.MouseEvent) => onFilterClick(type.get('id'), value.get('id'))}>
            <FilterCard name={value.get('name')} image={value.get('slug')} selected={selected} />
          </div>
        })}
      </Stack>
    </Stack>
  }, [viewProductAttributeValues, getTypeFilters])

  return <div className="filters-opacity">
    <Hidden only={['xs', 'sm', 'md']}>
      <Stack spacing={2} sx={{ px: 2, py: 4 }}>
        <Typography variant="h3">{t('Filter by')}</Typography>
        <Stack spacing={2} divider={<Divider />}>
          {viewProductAttributeTypes.valueSeq().map(renderType)}
        </Stack>
      </Stack>
    </Hidden>
    <Hidden only={['lg', 'xl']}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" onClick={() => setDisplayDrawer(true)} sx={{ p: 2 }}>
        <Typography variant="h3">{t('Filter by')}</Typography>
        <ArrowForwardIcon />
      </Stack>
      <StyledDrawer anchor="bottom" open={displayDrawer} onClose={() => setDisplayDrawer(false)}>
        <Stack spacing={3} sx={{ p: 2 }}>
          <Typography variant="h3">{t('Filter by')}</Typography>
          <Stack spacing={2} divider={<Divider />}>
            {viewProductAttributeTypes.valueSeq().map(renderType)}
          </Stack>
        </Stack>
      </StyledDrawer>
    </Hidden>
  </div>
}