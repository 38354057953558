import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Grid, IconButton, Menu, MenuItem, Paper, Stack, Tooltip, styled } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { CSSProperties, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { AddImgLayer, AddPatternLayer, AddTextLayer, EditLayer, RemoveLayer, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import RepeatTool from './RepeatTool';
import UpscaleTool from './UpscaleTool/UpscaleTool';
import useCheckDpi from '../_hooks/useCheckDpi';
import useSceneData from '../_hooks/useSceneData';
import PatternBrowser from '../_components/PatternBrowser';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import DeleteIcon from '../../Common/Icons/DeleteIcon';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import PropertyPanel, { PanelCloseButton, PropertyH5, PropertyLabel, PropertyPanelType, PropertyTextInput } from './PropertyPanel';
import TransformPropertyPanel from './TransformPropertyPanel';
import LayerMainTools from './LayerMainTools';
import ColorPanel from './ColorPanel';
import LayerText from './LayerText';
import LayerThumbnail from './LayerThumbnail';
import LayerDPI from './LayerDPI';
import LayerPattern from './LayerPattern';
import DuplicateButton from './DuplicateButton';
import LayerTextInput from './LayerTextInput';

export const SideButton = styled(Button)({
  border: 0,
  outline: 0,
  width: '40px',
  minWidth: 0,
  flex: 1,
  borderRadius: 0,
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',

  '&:first-of-type': {
    borderTop: 0,
  }
})

const SideButtonsList = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
  height: '122px',
  overflow: 'hidden',
  borderRadius: '0 15px 15px 0',
})

const LayerContainer = styled('div')({
  position: 'relative',
  width: '230px',
  flexShrink: 0,

  '&.dragging': {
    zIndex: 20,
  },

  '&:focus': {
    outline: 0,
  }
})

const LayerCard = styled(Paper)(({ theme }) => ({
  marginBottom: '10px',
  border: '1px solid transparent',
  borderRadius: '15px',
  flexShrink: '0',
  transition: 'border-color 150ms',

  '&.selected': {
    borderColor: '#ea008a',

    [`${SideButtonsList}`]: {
      borderRadius: '0 15px 0 0',
    }
  },

  ['@media (max-width: 1510px) and (min-height: ' + theme.height_breakpoints.values.lab_mobile + 'px), (max-height: 1070px) and (max-width: 953px)']: {
    marginBottom: 0,
  },
}));

const LayerCardContent = styled('div')({
  padding: '0 10px 10px',
  marginRight: '40px',
  display: 'flex',
  flexDirection: 'column',
  height: '120px',
  justifyContent: 'space-between',
})

const DragHandle = styled('div')({
  width: '40px',
  height: '40px',
  flexShrink: 0,
  marginLeft: '-10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'grab',

  '&.dragging': {
    cursor: 'grabbing',
  }
})

type Props = {
  layerId: string
}

export default function Layer(props: Props) {
  const [t] = useTranslation('design-lab');
  const dispatch = useAppDispatch();
  const layerType = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId)?.get('type') ?? 'img');
  const isSelected = useAppSelector(state => state.get('UIData').get('designLab').get('selectedLayer') === props.layerId);
  const [openedPanel, setOpenedPanel] = useState<PropertyPanelType>('none');

  useHotkeys('delete', (e) => {
    if (!isSelected) return;
    e.preventDefault();

    dispatch(RemoveLayer(props.layerId));
    dispatch(SelectLayer(null));
  }, {
    scopes: ['design-lab']
  }, [props.layerId, isSelected]);

  useEffect(() => {
    if (!isSelected) {
      setOpenedPanel('none');
    }
  }, [isSelected])

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: props.layerId });

  if (transform) {
    transform.scaleY = 1;
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  } as CSSProperties;

  useEffect(() => {
    if (isDragging) {
      setOpenedPanel('none');
    }
  }, [isDragging])

  const onDelete = useCallback(() => {
    dispatch(RemoveLayer(props.layerId));
    if (isSelected) {
      dispatch(SelectLayer(null));
    }
  }, [props.layerId, isSelected])

  const onOpenTransformPropertyPanel = useCallback(() => {
    setOpenedPanel(value => value === 'properties' ? 'none' : 'properties');
  }, [])

  const onOpenWarningPanel = useCallback(() => {
    setOpenedPanel(value => value === 'warning' ? 'none' : 'warning');
  }, [])

  const onClosePanel = useCallback(() => {
    setOpenedPanel('none')
  }, [])

  const onMouseDown = useCallback((e: MouseEvent<HTMLDivElement>) => {
    if (e.target instanceof Element && e.target.closest('.btn-delete')) {
      return;
    }

    dispatch(SelectLayer(props.layerId));
  }, [props.layerId, isSelected])

  return <LayerContainer
    ref={setNodeRef}
    style={style}
    {...attributes}
    className={isDragging ? 'dragging' : ''}
    onMouseDown={onMouseDown}
  >
    <UpscaleTool
      layerId={props.layerId}
      opened={isSelected && openedPanel === 'warning'}
      onOpen={onOpenWarningPanel}
      onClose={onClosePanel}
    />

    <TransformPropertyPanel
      layerId={props.layerId}
      opened={isSelected && openedPanel === 'properties'}
      onClose={onClosePanel}
    />

    <ColorPanel
      layerId={props.layerId}
      opened={isSelected && openedPanel === 'color'}
      onClose={onClosePanel}
    />
    <LayerCard
      elevation={4}
      className={isSelected ? 'selected ' : ''}
    >
      <LayerCardContent>
        <div style={{
          display: 'flex'
        }}>
          <Stack direction="row" alignItems="flex-start" sx={{width: '100%'}}>
            <DragHandle
              className={isDragging ? 'dragging' : ''}
              {...listeners}
            >
              <DragHandleIcon />
            </DragHandle>
            {layerType === 'img' || layerType === 'pattern' ? <LayerThumbnail layerId={props.layerId} /> : null}

            {layerType === 'text' ? <LayerText layerId={props.layerId} /> : null}
            {layerType === 'img' ? <LayerDPI layerId={props.layerId} /> : null}
            {layerType === 'pattern' ? <LayerPattern layerId={props.layerId} /> : null}
          </Stack>
        </div>

        {layerType === 'text' ? <LayerTextInput layerId={props.layerId} /> : null}

        <div>
          {['img', 'pattern'].includes(layerType) ? <RepeatTool
            layerId={props.layerId}
            opened={isSelected && openedPanel === 'repeat'}
            onOpen={() => setOpenedPanel(openedPanel === 'repeat' ? 'none' : 'repeat')}
            onClose={onClosePanel}
          /> : null}
        </div>
      </LayerCardContent>
      <SideButtonsList>
        <Tooltip title={t('Delete')} placement="right">
          <SideButton
            color="secondary"
            className={"btn-delete"}
            onClick={onDelete}
          ><DeleteIcon /></SideButton>
        </Tooltip>

        <DuplicateButton layerId={props.layerId} />

        <Tooltip title={t('Properties')} placement="right">
          <SideButton
            color="secondary"
            className={"btn-properties"}
            onClick={onOpenTransformPropertyPanel}
          >
            <img src={require('@resources/img/create/design-lab-v4/icons/layer-properties.svg?url')} style={{ height: '18px' }} />
          </SideButton>
        </Tooltip>
      </SideButtonsList>

      <LayerMainTools
        layerId={props.layerId}
        opened={isSelected && !isDragging}
        onOpenPanel={setOpenedPanel}
      />

    </LayerCard>
  </LayerContainer>
}