import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../../Common/_components/CheckBoxes';


const PRODUCT_ID = 129;
const VIEW_PRODUCT_ID = 151;

export default function DeskMatPage() {
  const [t] = useTranslation('page/products/desk-mats');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Desk Mats | Products')}
      description={t('Design and create your own custom printed Desk Mats featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#d5cef3"
      bgStyle={{
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      fullPageBg
      tag={t('The all-over print')}
      title={t('Desk Mats')}
      text={t('Transform your workspace with custom printed desk mats. Desk mats feature a cushioned super-smooth printed front and rubberized non-slip back. Protect your workspace and personalize it! Available in a variety of sizes, desk mats are perfect to add to your print on-demand product line, or to create for your own office space.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/desk-mats/banner-1-' + locale + '.png')}
      alt={t('Picture of Desk Mats')}
      details={[
        {
          img: require('@resources/img/pages/products/desk-mats/icon-1.svg?url'),
          alt: t('Cushion icon'),
          text: t('Cushion super-smooth printed front'),
        },
        {
          img: require('@resources/img/pages/products/desk-mats/icon-2.svg?url'),
          alt: t('Rounded corners icon'),
          text: t('Rounded corners'),
        },
        {
          img: require('@resources/img/pages/products/desk-mats/icon-3.svg?url'),
          alt: t('Rubber texture icon'),
          text: t('Non-slip rubberized back'),
        },
        {
          img: require('@resources/img/pages/products/desk-mats/icon-4.svg?url'),
          alt: t('Easy cleaning icon'),
          text: t('Durable and vibrant sublimation print won’t fade with use or wash, easy to clean'),
        },
        {
          img: require('@resources/img/pages/products/desk-mats/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Digitally printed in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/desk-mats/gallery_0.jpg'),
      require('@resources/img/pages/products/desk-mats/gallery_1.jpg'),
      require('@resources/img/pages/products/desk-mats/gallery_2.jpg'),
      require('@resources/img/pages/products/desk-mats/gallery_3.jpg'),
      require('@resources/img/pages/products/desk-mats/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Desk Mats <br/> <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Desk Mats! At prices designed for reselling, Desk Mats made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
      listOptions
    />

    <SectionSizes
      productId={PRODUCT_ID}
      title={t('Desk Mats Sizing')}
      text={t('Available in a variety of sizes including:')}
    >
      <img
        src={require('@resources/img/pages/products/desk-mats/measurement.svg?url')}
        style={{
          marginRight: 0,
        }}
      />
    </SectionSizes>

    <SectionBranding
      title={t('Brand your Desk Mats')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Desk Mats with these great products to create your own unique styles.')}
      productSlugs={['large-notebooks', 'art-prints', 'carry-alls']}
    />
  </div>
}
