import { styled } from "@mui/material";
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import ErrorBoundary from "../../Common/_components/ErrorBoundary";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import ProductImage from "./ProductImage";

const StyledButton = styled('div')({
  height: '64px',
  lineHeight: '18px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 10px 0px 15px',
  backgroundColor: 'transparent',
  transition: 'background-color 150ms',

  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },

  '&.active': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
})

const StyledButtonText = styled('div')({
  marginRight: '10px',

  '@media (max-width: 599px)': {
    display: 'none',
  }
})

interface Props {
  opened: boolean
  onClick: () => void
}

export default function ProductSelectorButton(props:Props) {
  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));

  return productData ? <StyledButton
    className={props.opened ? 'active' : ''}
    onClick={props.onClick}
  >
    <div
      style={{
        width: '36px',
        height: '36px',
        borderRadius: '24px',
        overflow: 'hidden',
        marginRight: '15px',
        flexShrink: 0,
      }}
    >
      <ErrorBoundary fallback={<></>}>
        <ProductImage
          slug={productData.get('slug')}
          width={48}
          height={48}
        />
      </ErrorBoundary>
    </div>
    <StyledButtonText>{productData.get('name')}</StyledButtonText>
    <ArrowDropDownIcon
      style={{
        transform: props.opened ? 'rotate(180deg)' : '',
        transition: 'transform 250ms',
      }}
    />
  </StyledButton> : null
}