import { styled } from "@mui/material";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { ChangeEvent, useCallback } from "react";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { EditLayer } from "../../UIData/_actions/DesignLabStoreActions";
import { useTranslation } from "react-i18next";

interface Props {
  layerId: string
}

export default function LayerText(props: Props) {
  const [t] = useTranslation('design-lab');
  const dispatch = useAppDispatch()

  const layerColor = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId)?.get('color') ?? '#000000');
  const layerFont = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId)?.get('font') ?? '');
  const layerText = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId)?.get('text') ?? '');

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(EditLayer(props.layerId, {
      text: e.target.value,
      width: 0, //This is used to force recalculating width/height of text
      height: 0,
    }))
  }, [props.layerId])

  return <h4 style={{
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textShadow: 'rgba(0, 0, 0, 0.5) 0px 2px 3px',
    padding: '0',
    margin: '4px -5px 0 0',
    color: layerColor,
    fontFamily: '"' + layerFont + '", sans-serif',
    fontSize: '20px',
    flex: 1,
    lineHeight: '32px',
    height: '32px',
  }}>{layerText}</h4>
}