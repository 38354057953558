import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { useCallback, useMemo } from "react";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { SetLayerOrder } from "../../UIData/_actions/DesignLabStoreActions";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import Layer from "./Layer";

export default function LayerList() {
  const dispatch = useAppDispatch()
  const activeScene = useAppSelector(state => state.get('UIData').get('designLab').get('activeScene'));
  const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));

  const visibleLayerIds = useAppSelector(state => state.get('UIData').get('designLab').get('layers')
    .filter(layer => layer.get('scene') === activeScene && (!activeSubproduct || layer.get('subproduct') === activeSubproduct))
    .reverse()
    .valueSeq()
    .map(layer => layer.get('id'))
  , (a, b) => a.equals(b));

  const arrayVisibleLayerIds = useMemo(() => {
    return visibleLayerIds.toArray()
  }, [visibleLayerIds]);

  const renderLayer = useCallback((layerId: string) => {
    return <Layer
      key={layerId}
      layerId={layerId}
    />
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 100,
      }
    }),
    /*useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })*/
  );

  const onDragEnd = useCallback((e: DragEndEvent) => {
    const { active, over } = e;

    if (active && over && active.id !== over.id) {
      const oldIndex = arrayVisibleLayerIds.indexOf(String(active.id));
      const newIndex = arrayVisibleLayerIds.indexOf(String(over.id));
      dispatch(SetLayerOrder(arrayMove(arrayVisibleLayerIds, oldIndex, newIndex)));
    }
  }, [arrayVisibleLayerIds])

  return <DndContext
    sensors={sensors}
    onDragEnd={onDragEnd}
  >
    <SortableContext items={arrayVisibleLayerIds}>
      {visibleLayerIds.map(renderLayer)}
    </SortableContext>
  </DndContext>
}