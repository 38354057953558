import { type GLTF } from 'three-stdlib';
import { useGLTF } from '@react-three/drei';
import { useMemo } from 'react';
import { Group, Mesh, MeshStandardMaterial, Object3D } from 'three';
//@ts-ignore
//import EXT_KHR_materials_variants from 'three-gltf-extensions/loaders/KHR_materials_variants/KHR_materials_variants.js'
import EXT_KHR_materials_variants from '../KHR_materials_variants'
import { ObjectMap } from '@react-three/fiber';

export type HasStandardMaterial = {
    material: MeshStandardMaterial
} & Object3D

export type GLTFMesh = GLTF & ObjectMap & {
    scene: Group & {
        children: HasStandardMaterial[]
    }
}

const isMesh = (object: Object3D): object is Mesh => object instanceof Mesh

export function hasStandardMaterial(x: Object3D): x is HasStandardMaterial {
    return 'material' in x && x.material instanceof MeshStandardMaterial
}

const cache: {[key: string]:GLTFMesh} = {}

export default function useGLB(url: URL, viewerId: string): GLTFMesh {
    const model = useMemo(() => {
        const path = url.pathname;
        const cacheKey = path+'_'+viewerId;

        if(cache[cacheKey]) return cache[cacheKey];

        const gltf = useGLTF(url.href+'?viewer='+viewerId, undefined, undefined, (loader) => {
            loader.register(parser => new EXT_KHR_materials_variants(parser));
        })

        for(const e in gltf.scene.children){
            //Very important to CLONE the element so the gltf can be reused in other viewers
            gltf.scene.children[e] = gltf.scene.children[e].clone()
            
            const element = gltf.scene.children[e]
            
            if(isMesh(element)){            
                element.castShadow = true
                element.receiveShadow = true

                if(hasStandardMaterial(element)) {
                    if('envMapIntensity' in element.material) element.material.envMapIntensity = 1.0
                    if('toneMapped' in element.material) element.material.toneMapped = false
                }
            }
        }

        cache[cacheKey] = gltf as GLTFMesh;

        return cache[cacheKey];
    }, [url, viewerId])

    return model
}