//old aow-lib
require('./aow-lib.js');

//old dashboard and create
require('./dashboard/Dashboard.js');

//old 3dviewer
require('./3d-viewer/lib/three.js');
require('./3d-viewer/lib/GLTFLoader.js');
require('./3d-viewer/lib/MTLLoader.js');
require('./3d-viewer/lib/OBJLoader.js');
require('./3d-viewer/lib/TrackballControls.js');
require('./3d-viewer/lib/OrbitControls.js');
require('./3d-viewer/lib/EffectComposer.js');
require('./3d-viewer/lib/RenderPass.js');
require('./3d-viewer/lib/ShaderPass.js');
require('./3d-viewer/lib/CopyShader.js');
require('./3d-viewer/lib/SMAAPass.js');
require('./3d-viewer/lib/SMAAShader.js');
require('./3d-viewer/lib/TexturePass.js');
require('./3d-viewer/SupportDetector.js');
require('./3d-viewer/ClothSimulator.js');
require('./3d-viewer/LUTShader.js');


var aow;

$(document).ready(function () {
	$('body').addClass('body-loaded');

	$('.slider').each(function () {
		var indicators = typeof $(this).data('indicators') != "undefined" ? $(this).data('indicators') : true;
		var interval = typeof $(this).data('interval') != "undefined" ? $(this).data('interval') : 8000;
		var transition = typeof $(this).data('transition') != "undefined" ? $(this).data('transition') : 500;
		$(this).slider({
			full_width: true,
			height: '100%',
			interval: interval,
			indicators: indicators,
			transition: transition
		});
		if (interval == 0) $(this).slider('pause');
	});

	$('.slider-btns').each(function () {
		var slider = $(this).closest('.slider');
		var interval = typeof slider.data('interval') != "undefined" ? slider.data('interval') : 8000;

		$(this).find('.slider-btn-prev').on('click', function () {
			slider.slider('prev');
			slider.slider('pause');

			if (interval > 0) {
				clearTimeout(slider.data('timeout'));
				slider.data('timeout', setTimeout(function () { slider.slider('start'); }, 5000));
			}
		});
		$(this).find('.slider-btn-next').on('click', function () {
			slider.slider('next');
			slider.slider('pause');

			if (interval > 0) {
				clearTimeout(slider.data('timeout'));
				slider.data('timeout', setTimeout(function () { slider.slider('start'); }, 5000));
			}
		});
	});

	$('.button-collapse').sideNav({
		menuWidth: 300, // Default is 240
		edge: 'left', // Choose the horizontal origin
		closeOnClick: true, // Closes side-nav on <a> clicks, useful for Angular/Meteor
		onClose: function () {
			$('body').removeClass('hide-overflow');
		},
		onOpen: function () {
			$('body').addClass('hide-overflow');
		}
	});

	//Triple bar menu click
	$('.button-collapse').on('click', function () {
		$(this).find('#triple-bar-menu').toggleClass('open');
	});

	//Mobile shop menu arrows
	$('.side-nav').find('.main-pink, .main-blue').find('.category').on('click', function () {
		var arrow = $(this).find('i').text();
		//console.log(arrow);
		if (arrow == 'arrow_drop_down') {
			$(this).find('i').text('arrow_drop_up');
		} else {
			$(this).find('i').text('arrow_drop_down');
		}
	});

	window.aow = new AOW();

	// //Init some stuff based on URL
	Routes();

	//Contact-us: form handling
	window.aow.addUITask(function (selector) {
		$(selector).find('#contact-us-form').find('#contact_category').on('change', function () {
			if ($(this).find('option:selected').val() == 'order_status_and_tracking' ||
				$(this).find('option:selected').val() == 'shipping_problem_order_not_received' || 
				$(this).find('option:selected').val() == 'problem_with_my_order'
			) {
				$('#contact_orderid').attr('required', true).closest('.input-field').show();
			} else {
				$('#contact_orderid').attr('required', false).closest('.input-field').hide();
			}
		});
	});

	//Dialog triggers
	window.aow.addUITask(function (selector) {
		$(selector).on('click', '.dialog-trigger', function (e) {
			e.preventDefault();
			var btn = $(this);

			if (btn.hasClass('disabled'))
				return;
			btn.addClass('disabled');

			//Try to remove focus from btn
			var elem = $(":focus").get()[0];
			if (typeof elem !== 'undefined' && typeof elem.blur !== 'undefined') {
				elem.blur();
			}

			var options = {
				url: isset($(this).attr('href')) ? $(this).attr('href') : $(this).attr('data-dialog'),
				data: $(this).attr('data-dialog-data'),
				dataType: 'json',
				complete: function () {
					btn.removeClass('disabled');
				}
			};

			//Check for a success function
			if (typeof $(this).attr('data-dialog-module') !== 'undefined') {
				var parts = $(this).attr('data-dialog-module').split('.');
				var module = AOW;
				for (var part in parts) {
					if (!parts.hasOwnProperty(part)) continue;

					if (isset(module[parts[part]]))
						module = module[parts[part]];
					else throw ('AOW: Specified module not found.');
				}

				if (isset(module[$(this).attr('data-dialog-success')]))
					options.success = module[$(this).attr('data-dialog-success')];
			}

			AOW.UI.dialog(options);
		});
	});

	window.aow.addUITask(ProductDesignView.initUiTasks);

	//Image gallery product color (like pillows)
	window.aow.addUITask(function (selector) {
		$('#productInfo-productOption-color').on('change', function () {
			if (typeof window.aow.modelViewer !== 'undefined' && typeof window.aow.modelViewer.textureCanvas_ctx !== 'undefined') {
				// Actually only do this on pillows
				if ([8, 10, 31, 32, 33].indexOf(window.aow.modelViewer.product.static.id) == -1) return;

				var color = $(this).find('option:selected').attr('data-slug');

				var hexColor = '#ffffff';

				//Back printed
				if (color === 'designed') {
					window.aow.modelViewer.onTextureLoad = function () {
						window.aow.modelViewer.textureCanvas_ctx.drawImage(window.aow.modelViewer.textureImage, 0, 0);
						window.aow.modelViewer.texture.needsUpdate = true;
					};
					if(window.aow.modelViewer.textureImage) {
						window.aow.modelViewer.onTextureLoad();
					}
				}
				else if (color === 'printed') {
					window.aow.modelViewer.onTextureLoad = function () {
						window.aow.modelViewer.textureCanvas_ctx.drawImage(window.aow.modelViewer.textureCanvas_ctx.canvas, 1024, 0);
						window.aow.modelViewer.texture.needsUpdate = true;
					};
					if(window.aow.modelViewer.textureImage) {
						window.aow.modelViewer.onTextureLoad();
					}
				}

				//Color back
				else {
					switch (color) {
						case 'white': hexColor = '#ffffff'; break;
						case 'natural-white': hexColor = '#ffffff'; break;
						case 'black': hexColor = '#050505'; break;
						case 'red': hexColor = '#952122'; break;
						case 'navy-blue': hexColor = '#34375e'; break;
						case 'dark-brown': hexColor = '#49321c'; break;
						case 'fuschia': hexColor = '#a41759'; break;
						case 'bronze': hexColor = '#421c08'; break;
						case 'dark-grey': hexColor = '#5c7587'; break;
						case 'deep-purple': hexColor = '#3f0c34'; break;
					}

					window.aow.modelViewer.onTextureLoad = function () {
						window.aow.modelViewer.textureCanvas_ctx.fillStyle = hexColor;
						window.aow.modelViewer.textureCanvas_ctx.fillRect(1024, 0, 1024, 1024);
						window.aow.modelViewer.texture.needsUpdate = true;
					};
					if(window.aow.modelViewer.textureImage) {
						window.aow.modelViewer.onTextureLoad();
					}
				}
			}
		});

		//Trigger initial change
		$('#productInfo-productOption-color').trigger('change');
	});

	//Image gallery kimono belt color
	window.aow.addUITask(function (selector) {
		$('#productInfo-productOption-trim-belt').on('change', function () {
			var color = $(this).find('option:selected').attr('data-slug');

			var threeColor;

			if (typeof window.aow.modelViewer !== 'undefined' && typeof window.aow.modelViewer.object_belt !== 'undefined') {
				switch (color) {
					case 'cream': threeColor = new THREE.Color(0.85, 0.85, 0.85); break;
					case 'dark-gray': threeColor = new THREE.Color(0.2, 0.2, 0.2); break;
					default: threeColor = new THREE.Color(0.08, 0.08, 0.08); break;
				}

				window.aow.modelViewer.object_belt.children[0].children[0].material.color = threeColor;
			}
		});

		//Trigger initial change
		$('#productInfo-productOption-trim-belt').trigger('change');
	});


	//Fabric disclaimers
	window.aow.addUITask(function (selector) {
		$('#productInfo-productOption-fabric').on('change', function () {
			var fabric = $(this).find('option:selected').attr('data-slug');

			$('#fabric-disclaimer').remove();

			if (fabric === 'cotton-linen-canvas') {
				$('#productInfo-productOption-fabric').after('<div id="fabric-disclaimer" style="padding: .5rem;background: #ddd;border-radius: 2px;font-size: .9rem;">' +
					__('account_ns_messages.cottonLinenDisclaimer') +
					'</div>');

				$('#fabric-disclaimer').find('a').on('click', function (e) {
					e.preventDefault();
					AOW.UI.addTutorialVideo('https://www.youtube.com/embed/7BHrX0Twltk');
					return false;
				});
			}
		});

		//Trigger initial change
		$('#productInfo-productOption-fabric').trigger('change');
	});

	//Product option events
	window.aow.addUITask(function (selector) {
		//Check if some options should be unavailable due to dependencies
		$('.productDesign-info select.product-option').each(function () {
			//First check if each of this select's options contains dependencies. We can ignore it if some don't have dependencies
			var fullDependencies = true;
			var listDependencies = [];

			$(this).children('option').each(function () {

				var dependencies = $(this).attr('data-dependencies') ? $(this).attr('data-dependencies').split(',') : null;

				//ignore if there's no dependency
				if (!dependencies || !dependencies.length) {
					fullDependencies = false;
					return false;
				}

				for (var di in dependencies) {
					if (!dependencies.hasOwnProperty(di)) continue;
					listDependencies.push(di);
				}
			});

			//Stop here if some options don't have dependencies, we're ok
			if (!fullDependencies)
				return true;

			var findUnavailableValue = function () {
				//Value not in dependencies, disable it
				if (listDependencies.indexOf($(this).val()) === -1) {
					$(this).addClass('unavailable');
				}
			};

			for (var i = 0; i < listDependencies.length; i++) {
				//Find unavailable options that are siblings to that dependency
				var $select = $('.productDesign-info .product-option option[value="' + listDependencies[i] + '"]').parent();

				$select.children('option').each(findUnavailableValue);
			}

			$(this).material_select();
		});

		//Dependency check
		$('.productDesign-info .product-option').on('change', function () {
			$('.productDesign-info select.product-option').each(function () {
				var removed_elems = [];

				var $previous = $(this).find(':selected');

				$(this).children('option').each(function () {
					var me = this;
					var dependencies = $(this).attr('data-dependencies') ? $(this).attr('data-dependencies').split(',') : null;

					//ignore if there's no dependency
					if (!dependencies || !dependencies.length) return true;

					//Get options
					var options = {};
					$(this).closest('.productDesign-info').find('.product-option').each(function () {
						if ($(this).is('select'))
							options[$(this).attr('name')] = $(this).val();
						else if ($(this).is('input[type="checkbox"]') && $(this).is(':checked'))
							options[$(this).attr('name')] = $(this).val();
					});

					//Find dependency in options
					for (var option_name in options) {
						if (dependencies.indexOf(options[option_name]) !== -1) {
							$(me).removeClass('unavailable');
							return true;
						}
					}

					//Didn't find dependency as active, hide the option
					$(me).addClass('unavailable');
					removed_elems.push(me);
					$(me).remove();
				});

				$(this).material_select();

				//Add back the removed elements
				for (var i = 0; i < removed_elems.length; i++) {
					$(this).append(removed_elems[i]);
				}

				// Make sure we don't have an unavailable one selected
				if ($(this).val() && $(this).find('option[value="' + $(this).val() + '"]')) {
					if ($(this).find('option[value="' + $(this).val() + '"]').hasClass('unavailable'))
						$(this).val(null);
				}

				//Make sure select is not null
				if ($(this).val() === null && $(this).find('option:not(.unavailable)').length > 0) {
					$(this).find('option:not(.unavailable)').first().prop('selected', true);
					$(this).trigger('change');
				}

				// Select other option if there's still one with the same slug as the previous one
				$(this).find('option:not(.unavailable)').each(function () {
					if ($(this).val() != $previous.val() && $(this).data('slug') == $previous.data('slug')) {
						$(this).prop('selected', true);
						$(this).trigger('change');
					}
				});
			});
		});

		//Price change
		$('.productDesign-info select.product-option, .productDesign-info input[name="quantity"]').on('change', function () {
			var productDesign_elem = $(this).closest('.productDesign-info');

			var quantity = productDesign_elem.find('input[name="quantity"]').val();
			var priceAttribute = quantity >= 6 ? 'price-wholesale' : 'price-one';

			//Calculate price
			var total_price = parseFloat(productDesign_elem.find('.price-value').data(priceAttribute), 10) || 0;

			var isOutofStock = false;
			productDesign_elem.find('select.product-option').each(function () {
				//Update price on options
				$(this).find('option').each(function () {
					var price = parseFloat($(this).data(priceAttribute), 10) || 0;

					var name = $(this).data('name');

					if (price !== 0) {
						name += ' [$' + price.toFixed(2) + ']';
					}
					$(this).html(name);
				});

				//Check for out of stock
				if ($(this).find('option:selected').data('outofstock')) {
					isOutofStock = true;
				}

				var selectedPrice = parseFloat($(this).find('option:selected').data(priceAttribute), 10) || 0;
				total_price += selectedPrice;
			});

			//Prevent adding if it's out of stock
			if (isOutofStock) {
				$('.productDesign-info .offer').hide();
				$('.productDesign-info .section.out-of-stock').show();

				//Used for edit options in integrations > order
				$('#choose-product-form').find('button[type="submit"]').prop('disabled', true);
			} else {
				$('.productDesign-info .offer').show();
				$('.productDesign-info .section.out-of-stock').hide();

				//Used for edit options in integrations > order
				$('#choose-product-form').find('button[type="submit"]').prop('disabled', false);
			}

			productDesign_elem.find('input[type="checkbox"].product-option').each(function () {
				//Ignore if not checked
				if (!$(this).is(':checked'))
					return true;

				var price = parseFloat($(this).data(priceAttribute), 10) || 0;
				total_price += price;
			});

			productDesign_elem.find('.price-value').text(total_price.toFixed(2));

			//Trigger change on the options if this is not an option
			if (!$(this).is('.product-option')) {
				$('.productDesign-info .product-option').trigger('change');
			}
		});

		//Trigger change for first update
		$('.productDesign-info select.product-option, .productDesign-info input[name="quantity"]').first().trigger('change');
	});

	//HiDPI images for HiDPI big screens
	var isHiDpi = (window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia("(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)").matches));

	if ($(window).width() > 1280 && isHiDpi) {
		window.aow.addUITask(function (selector) {
			$(selector).find('img[data-hdpi]').each(function () {
				var obj = this;

				//Check if HDPI is already loaded
				if ($(this).data('hdpi-loaded')) {
					return true;
				}

				var path = $(this).attr('src');
				var extension = path.substr(path.lastIndexOf('.'));
				var newPath = path.substr(0, path.lastIndexOf('.')) + '@2x' + extension;

				//Load new image in RAM and set it on the html tag when loaded
				var hdpi_img = new Image();
				hdpi_img.onload = function () {
					$(obj).attr('src', hdpi_img.src);
				};
				hdpi_img.src = newPath;

				//Mark as loaded
				$(this).data('hdpi-loaded', true);
			});
		});
	}

	window.aow.addUITask(function(selector) {
		//Fix for dropdowns z-index in cards
		$(selector).find('.card-wrap .dropdown-button').on('click', function () {
			var card = $(this).closest('.card-wrap');
			$('.card-wrap').removeClass('active');
			$(card).addClass('active');
		});
	});

	window.aow.initUI();

	//Shorten text
	//TODO: Improve it so it doesn't run every time but only on resize
	$('.shorten-text').on('mouseenter', function () {
		var $e = $(this), h = $e.height(), ow = this.offsetWidth, sw = this.scrollWidth;
		$e.css({ 'overflow': 'visible', 'white-space': 'normal', 'word-break': 'break-all', 'height': 'auto' });

		if (ow < sw || $e.height() > h) {
			$(this).attr('title', $(this).text());
		}
		$e.css({ overflow: '', 'white-space': '', 'word-break': '', 'height': '' });
		if ($e.attr('style') === '') {
			$e.removeAttr('style');
		}
	});


	//Color selector on pillow fabric page
	$('.color-selector .color-block').on('click', function (e) {
		e.preventDefault();

		$(this).siblings('.selected').removeClass('selected');

		$(this).addClass('selected');

		$(this).closest('.card').find('.color-selector-images').find('.selected').removeClass('selected');
		$(this).closest('.card').find('.color-selector-images').find('img').eq($(this).index()).addClass('selected');

		return false;
	});

	//Init resize actions
	$(window).resize(resize_ui);

	resize_ui();

	var blocksArr = $('.container div.content'),
		currentEl,
		content,
		maxIndex = blocksArr.length - 1;

	// Popup Start
	function changeSlide(content) {
		$('#popup-cont .item').css('display', 'none');
		$('#popup-cont .item').fadeIn('slow');
		//$('#popup').fadeOut();
		$('#popup .item').html('');
		$('#popup .item').append(content);
		//setTimeout(function () {
		$('#popup').fadeIn('slow');

		//}, 500);

		function elHeight() {
			$('#myElement').css('height', $('#popup .item').innerHeight() + 'px');
		}
		if ($('#popup').is('visible')) {
			elHeight();
		} else {
			setTimeout(function () {
				elHeight();
			}, 500);
		}
	}

	$('.fabric-page .showPopUp').each(function (index) {

		$(this).on('click', function () {
			currentEl = index;

			if (currentEl === 0) {
				$('#prev').addClass('hide');
			} else {
				$('#prev').removeClass('hide');
			}
			if (currentEl === maxIndex) {
				$('#next').addClass('hide');
			} else {
				$('#next').removeClass('hide');
			}

			content = $(blocksArr[currentEl]).clone();
			changeSlide(content);
		});

	});

	$('#next').on('click', function (e) {
		if (currentEl < maxIndex) {
			$(this).removeClass('hide');
			$('#prev').removeClass('hide');

			currentEl++;
			if (currentEl === maxIndex) {

				$(this).addClass('hide');
				e.preventDefault();
			}
		}
		content = $(blocksArr[currentEl]).clone();
		changeSlide(content);
	});

	$('#prev').on('click', function (e) {
		if (currentEl > 0) {
			$(this).removeClass('hide');
			$('#next').removeClass('hide');

			currentEl--;

			if (currentEl === 0) {
				e.preventDefault();
				$(this).addClass('hide');
			}
		}

		content = $(blocksArr[currentEl]).clone();
		changeSlide(content);
	});

	$('#close-btn-popup').on('click', function (e) {
		$('#popup').fadeOut('slow');
	});

	if ($('.product-page-v3').length) {
		//Blur on scroll (shorts page)
		$(window).on('scroll', function () {
			var scrollPercent = $(window).scrollTop() / $(window).height();

			$('.bg-vector[data-blur-target]').each(function () {
				var blurValue = parseFloat($(this).attr('data-blur-target')) * scrollPercent;

				$(this).css('filter', 'blur(' + blurValue + 'px)');
			});
		});

		//Bg vector scroll
		$(window).on('scroll', function () {
			$('.bg-vector').each(function (i) {
				var scrollPercent = $(window).scrollTop() / $(window).height();

				$(this).css('padding-top', (scrollPercent * (i % 2 + 1) * 100) + 'px');
			});
		});

		//Staggered list
		Materialize.scrollFire([
			{
				selector: '.staggered-list',
				offset: 200,
				callback: function (el) {
					Materialize.showStaggeredList($('.staggered-list'));
				}
			},
			{
				selector: '#ways-to-wear-1.zoom-in-animation',
				offset: 100,
				callback: function (el) {
					$(el).addClass('animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
						$(this).removeClass('zoom-in-animation animated');
					});
				}
			},
			{
				selector: '#ways-to-wear-2.zoom-in-animation',
				offset: 100,
				callback: function (el) {
					$(el).addClass('animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
						$(this).removeClass('zoom-in-animation animated');
					});
				}
			},
			{
				selector: '#ways-to-wear-3.zoom-in-animation',
				offset: 100,
				callback: function (el) {
					$(el).addClass('animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
						$(this).removeClass('zoom-in-animation animated');
					});
				}
			},
			{
				selector: '#ways-to-wear-4.zoom-in-animation',
				offset: 100,
				callback: function (el) {
					$(el).addClass('animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
						$(this).removeClass('zoom-in-animation animated');
					});
				}
			},
			{
				selector: '#findit-1.zoom-in-animation',
				offset: 100,
				callback: function (el) {
					$(el).addClass('animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
						$(this).removeClass('zoom-in-animation animated');
					});
				}
			},
			{
				selector: '#findit-2.zoom-in-animation',
				offset: 100,
				callback: function (el) {
					$(el).addClass('animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
						$(this).removeClass('zoom-in-animation animated');
					});
				}
			}
		]);
	}


	//Banner carousel
	$('#homepage').find('.carousel-slider:not(.standalone)').each(function () {
		let $carousel = $(this);
		let count = $carousel.find('.carousel-item').length;

		$carousel.find('.carousel-item').each(function (index) {
			$(this).data('index', index);
		});

		let timeout = $carousel.data('timeout') || 5000;
		let sliderFunc = function () {

			$carousel.find('.carousel-item').each(function () {
				let prevIndex = $(this).data('index'),
					newIndex = -1;

				if (prevIndex == 0) {
					newIndex = count - 1;
				} else {
					newIndex = prevIndex - 1;
				}

				if (newIndex == 1) {
					$(this).css('opacity', 1);
				} else {
					$(this).css('opacity', 0);
				}

				$(this).data('index', newIndex);
			});

			setTimeout(sliderFunc, timeout);
		};

		sliderFunc();
	});

	//Testimony carousel
	$('#homepage').find('.carousel:not(.carousel-slider)').each(function () {
		let $carousel = $(this);
		let count = $carousel.find('.carousel-item').length;

		$carousel.find('.carousel-item').each(function (index) {
			let offset = -100 + index * 100;
			$(this).css('left', String(offset) + '%');
			$(this).data('index', index);
		});

		let timeout = $carousel.data('timeout') || 5000;
		let sliderFunc = function () {

			$carousel.find('.carousel-item').each(function () {
				let prevIndex = $(this).data('index'),
					newIndex = -1;

				if (prevIndex == 0) {
					newIndex = count - 1;
				} else {
					newIndex = prevIndex - 1;
				}

				let offset = -100 + newIndex * 100;
				$(this).css('left', String(offset) + '%');

				if (newIndex == 1) {
					$(this).css('opacity', 1);
				} else {
					$(this).css('opacity', 0);
				}

				$(this).data('index', newIndex);
			});

			setTimeout(sliderFunc, timeout);
		};

		sliderFunc();
	});

	// Previously called automatically in help.js
	AOW.Help.init();

	// Previously called automatically in drophsip.js
	AOW.Dropship.init();

	// Previously called automatically in ProductionAndShipping.js
	AOW.ProductionAndShipping.init();

	AOW.Fabrics.init();

	$('.btn-change-preferences').on('click', function (e) {
		e.preventDefault();

		AOW.UI.dialog({
			url: 'select-preferences',
			success: function () {
				var $dialog = $(this);
				$dialog.find('form').on('submit', function (e) {
					e.preventDefault();

					var currency = $(this).find('input[name="select-currency"]:checked').val()
					var locale = $(this).find('input[name="select-language"]:checked').val();

					AOW.request({
						method: 'POST',
						url: 'change-preferences',
						data: {
							currency: currency,
							locale: locale,
						},
						success: function () {
							window.location.reload();
						}
					});

					return false;
				});
			}
		});
	});

	//404
	if ($('#ghost-img').length) {
		$('#ghost-img').on('click', function (e) {
			e.preventDefault();

			$('body').addClass('ghost');
			setTimeout(function () {
				$('body').removeClass('ghost');
			}, 500);
		});
	}

	//Init pricing-modals and their tabs
	$('.modal.pricing-modal').modal({
		ready: function () { $('.pricing-modal').find('.tabs').tabs(); }
	});

	$('#page-snapback, #page-shirts-unisex, #page-shirts-men, #page-shirts-women, #page-racerback-tank-tops, #page-wall-tapestry, #page-long-sleeve-tshirts, #page-lounge-pants').find('input#retail-price').on('input', function () {
		var retail = $(this).val();
		var price = $('input#dropship-price').val();
		let number = parseFloat(retail - price, 2).toFixed(2);
		$('input#profit-price').val(isNaN(number) ? '' : number);
	});

	$('.product-page-v4').find('input.retail-price').on('input', function () {
		let [retail, price] = [$(this).val(), $(this).closest('tr').find('input.dropship-price').val()];
		let number = retail - price;
		$(this).closest('tr').find('input.profit-price').val(isNaN(number) ? '' : parseFloat(number, 2).toFixed(2));
	});

	$('.product-page-v4').find('.section-pricing-button').on('click', function (e) {
		$(this).closest('.container').find('.section-pricing-prices').slideToggle();
		$(this).toggleClass('opened');
	});

	//Product page sizes toggle (such as art prints)
	$('.product-page-v4').find('#product-page-custom-sizes').find('.ratios-toggle > span').on('click', function (e) {
		if($(this).hasClass('active')) {
			return;
		}

		var index = $(this).closest('.ratios-toggle').find('> span').index(this);

		var $ratiosWrapper = $(this).closest('#product-page-custom-sizes').find('.ratios-wrapper');

		$(this).closest('.ratios-toggle').find('> span').removeClass('active');
		$(this).addClass('active');

		if(index === 0) {
			$ratiosWrapper.removeClass('inverted');
		} else {
			$ratiosWrapper.addClass('inverted');
		}
		
	});

	$('.product-page-v4').find('#product-page-pricing').find('.price-table .retail-price').on('input', function () {
		let [retail, dropshipPrice, wholesalePrice] = [$(this).val(), $(this).closest('tr').find('.dropship-price').text(), $(this).closest('tr').find('.wholesale-price').text()];
		let profitDropship = retail - dropshipPrice;
		let profitWholesale = retail - wholesalePrice;
		$(this).closest('tr').find('.profit-dropship-price').text(isNaN(profitDropship) ? '' : parseFloat(profitDropship, 2).toFixed(2));
		$(this).closest('tr').find('.profit-wholesale-price').text(isNaN(profitWholesale) ? '' : parseFloat(profitWholesale, 2).toFixed(2));
	});
	$('.product-page-v4').find('#product-page-pricing').find('.price-table .retail-price').trigger('input');

	$('#product-price-calculator').find('.product-variant .retail-price').on('change', function () {
		var $productVariant = $(this).closest('.product-variant');

		var retail = $(this).val();
		var price = $productVariant.find('.dropship-price').val();
		var profit = $productVariant.find('.profit-price').val();

		$productVariant.find('.profit-price').val(parseFloat(retail - price, 2).toFixed(2));
	});

	$('.product-page-v3 .goal-card .info-btn').on('click', function (e) {
		//TODO: open dialog about goals
		e.preventDefault();

		AOW.UI.dialog({
			url: 'info/goals-rewards-summary'
		});
	});

	$('#page-cotton-linen-pillows').find('.open-video-btn').on('click', function (e) {
		e.preventDefault();
		AOW.UI.addTutorialVideo($(this).data('url'));
		return false;
	});

	//Init video-modals
	$('.modal.video-modal').modal();

	$('#btn-holiday-deadlines').on('click', function (e) {
		e.preventDefault();

		AOW.UI.dialog({
			url: 'info/holiday-deadlines'
		});
	});


	AOW.AccountSettings.init();

	$('.account-open-goals, .account-open-rewards').on('click', function (e) {
		var toRewards = $(this).hasClass('account-open-rewards');

		e.preventDefault();

		AOW.UI.dialog({
			url: 'account/rewards/goals-and-rewards',
			success: function () {
				var tabs = $(this).find('.tabs');
				tabs.tabs();

				tabs.find('a').on("click", function (e) {
					var target = this.href.split('#').slice(-1).pop();
					if (target == "page-goals") {
						$(this).closest(".modal-content").find(".modal-content-left").removeClass("hidden");
					} else if (target == "page-rewards") {
						$(this).closest(".modal-content").find(".modal-content-left").addClass("hidden");
					}
				});

				// Timeout hack necessary otherwise the tab layout breaks, you can thank materialize
				tabs.tabs('select_tab', 'page-goals');
				setTimeout(function () {
					tabs.tabs('select_tab', 'page-goals');

					if (toRewards) tabs.find('a').each(function (e) {
						var target = this.href.split('#').slice(-1).pop();
						if (target == "page-rewards") $(this).trigger('click');
					});
				}, 500);
			}
		});
	});

	$('.aow-simple-link').on('click', function () {
		if ($(this).data('aow-simple-link')) {
			window.location = $(this).data('aow-simple-link');
		}
	});

	$('#blackFridayPopupToggle').on('click', function () {
		$('#blackFridayPopup').addClass('opened');
		$('#overlayFriday').addClass('opened');
	});

	$('#blackFridayPopupClose, #overlayFriday').on('click', function() {
		$('#blackFridayPopup').removeClass('opened');
		$('#overlayFriday').removeClass('opened');
	});

	
});

//JavaScript Error tracking with GA
window.onerror = function (message, file, line) {
	if (typeof gtag !== 'undefined') {
		gtag('event', 'exception', {
			description: file + "(" + line + "): " + message,
			fatal: false
		});
	}
};

window.fixImgRatio = function (img) {
	if (isImgLoaded(img)) {
		var ratio = parseInt($(img).attr('width'), 10) / parseInt($(img).attr('height'), 10);

		var newHeight = $(img).width() / ratio;
		$(img).parent().height(newHeight);
	} else {
		img.onload = function () {
			var ratio = parseInt($(img).attr('width'), 10) / parseInt($(img).attr('height'), 10);

			var newHeight = $(img).width() / ratio;
			$(img).parent().height(newHeight);
		};
	}
};

window.resize_ui = function () {

	//Image fixed ratio
	$('img.fixed-ratio').each(function () {
		fixImgRatio(this);
	});

	if ($('.button-collapse').find('#triple-bar-menu').hasClass('open')) {
		$('.button-collapse').sideNav('hide');
		$('.button-collapse').find('#triple-bar-menu').removeClass('open');
	}

	if ($('.side-nav').find('.collapsible .active'))
		$('.side-nav').find('.collapsible').collapsible();
};