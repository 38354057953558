AOW.Dashboard = function(slug) {
	var me = this;

	this.$el	= $('body').find('#main-dashboard');
	
	var $window = $(window);

	//Set dashboard product-sync-view height
	$(window).on('resize.sync-product', function(){
		var navHeight = $('#main-dashboard').find('#nav-desktop').height();
		$('#product-sync-view').css('height', ($(window).height()-navHeight));
		$('#product-sync-view').css('width', ($('.main-page').width()));
	});
	$(window).trigger('resize.sync-product');

	//Init AccountSettings.js
	AOW.AccountSettings.init();

	$('#connect').find('#removeStore-btn').on('click', AOW.Dashboard.prototype.onRemoveStoreBtnClick.bind(this));
};

AOW.Dashboard.prototype = Object.assign({}, AOW.Dashboard.prototype, {

	onRemoveStoreBtnClick: function(e) {
		e.preventDefault();
		var me = this;

		//Show confirm dialog
		AOW.UI.dialog({
			url: 'd/'+me.slug+'/confirm-remove-store',
			success: function() {
				var $dialog = $(this);
		
				$dialog.find('form').on('submit', function(e) {
					e.preventDefault();
		
					//Check if button is already disabled (request in progress)
					var btn = $(this).find('.btn-flat[type="submit"]');
					if(btn.hasClass('disabled'))
						return false;
					
					//Disable button and add loader
					btn.addClass('disabled').attr('data-text',btn.text()).html('');
					AOW.UI.addLoader(btn);
					
					//Send request to remove
					AOW.request({
						url: 'd/'+me.slug+'/remove-store',
						method: 'POST',
						data: {
							password_check: $('#password_check').val()
						},
						success: function(response) {
							window.location.href = "d/"+me.slug+"/connect";
						},
						error: function(response) {
							response = response.responseJSON;
							
							//Toast error
							if(isset(response.data.error)) {
								AOW.UI.toast(response.data.error,'error');
							} else {
								AOW.UI.toast(__('account_ns_toast.errorOccured'),'error');
							}
							
							//Re-enable btn
							btn.removeClass('disabled').html(btn.attr('data-text')).removeAttr('data-text');
						}
					});
		
					return false;
				});
			}
		});
		
		return false;
	},
	
	initWindowHistory: function() {
		var me = this;
		
		// window event for listening to clicks on browsers Next or Back buttons
		window.onpopstate = me.onNextOrBackClicked.bind(me);
		
		// manually triggering a function when we push a new state into the history
		var pushState = window.history.pushState;
		window.history.pushState = function() {
			var state = arguments[0];
			// trigger this function when a new state is pushed to window history
			me.applyState.call(me, state);
			
			return pushState.apply(window.history, arguments);
		};
	},
	
	onNextOrBackClicked: function(e) {
		this.applyState(e.state);
	},
	
	pushStateToHistory: function(state) {
		
		var url = this.getCurrentUrl(state);
		
		window.history.pushState(state, '', url);
	}
});

// var me = this;
// // window event for listening to clicks on browsers Next or Back buttons
// window.onpopstate = me.onNextOrBackClicked.bind(me);
// 
// // manually triggering a function when we push a new state into the history
// var pushState = window.history.pushState;
// window.history.pushState = function() {
// 	if( arguments[0].fakeId !== me.fakeId ) {
// 		// trigger this function when history is pushed
// 		me.applyState.call(me, arguments[0]);
// 
// 		return pushState.apply(window.history, arguments);
// 	}
// };

AOW.Dashboard.page = function(options) {
	return AOW.langRequest(options);
};

AOW.Dashboard.startLoader = function(noloader) {
	if(typeof noloader !== 'undefined' && noloader === true) {
		$('#main-dashboard').find('.loader-overlay *').hide();
	} else {
		$('#main-dashboard').find('.loader-overlay *').show();
	}

	$('#main-dashboard').find('.loader-overlay').show();

	setTimeout(function() {
		$('#main-dashboard').find('.main-page').addClass('loading');
	});
};

AOW.Dashboard.stopLoader = function() {
	$('#main-dashboard').find('.main-page').removeClass('loading');

	setTimeout(function() {
		$('#main-dashboard').find('.loader-overlay').hide();
	}, 150);
};

require('./home/Home.js');
require('./connect/Connect.js');
require('./products/Products.js');
require('./products/SyncProduct.js');
require('./products/sync-aow-item/SyncAOWItem.js');
require('./products/sync-aow-item/SyncAOWProduct.js');
require('./products/sync-aow-item/SyncAOWCollection.js');
require('./reports/Reports.js');
require('./settings/Settings.js');