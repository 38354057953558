import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import useCheckDpi from "../_hooks/useCheckDpi";

interface Props {
  layerId: string
}

export default function LayerDPI(props: Props) {
  const [t] = useTranslation('design-lab');
  
  const layer = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId));
  const checkDpi = useCheckDpi();

  const { dpi, minDpi, dpiIndicator } = useMemo(() => {
    return layer ? checkDpi(layer) : {
        dpi: 0,
        minDpi: 0,
        dpiIndicator: 'bad'
    };
}, [layer, checkDpi]);

  return <div style={{
    marginTop: '10px',
  }}>
    <div style={{
      fontSize: '12px',
      textTransform: 'uppercase',
      color: '#999',
    }}>{t('Quality (DPI)')}</div>
    <strong
      style={{
        color: dpiIndicator === 'good' ? '#4caf50' : (dpiIndicator === 'ok' ? '#ff9800' : '#f44336')
      }}
    >{dpi}</strong>
  </div>
}