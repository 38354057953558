import { styled } from "@mui/material"
import ValidIndicator from "../_components/ValidIndicator"
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import useCheckWarningPoint from "../_hooks/useCheckWarningPoint";
import useCheckDpi from "../_hooks/useCheckDpi";
import { EditWarnings } from "../../UIData/_actions/DesignLabStoreActions";
import useLabData from "../_hooks/useLabData";
import Point from "../../Common/Utils/Math/Point";

const ValidIndicatorsContainer = styled('div')({
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  padding: '20px',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',

  '@media (max-width: 1510px), (max-height: 1070px)': {
    padding: '15px',
  },

  '@media (max-width: 1510px) and (max-height: 1070px)': {
    flexDirection: 'row',
  },

  '@media (max-width: 1053px), (max-height: 780px)': {
    flexDirection: 'column',
  },
})

export default function ValidIndicatorSection() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('design-lab');

  const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));
  const layers = useAppSelector(state => state.get('UIData').get('designLab').get('layers'));
  const resolutionOk = useAppSelector(state => state.get('UIData').get('designLab').get('warnings').get('resolution').reduce((reduction, value) => value && reduction, true));
  const templatesCoveredOk = useAppSelector(state => state.get('UIData').get('designLab').get('warnings').get('templates_covered').reduce((reduction, value) => value && reduction, true));

  const labData = useLabData();
  const checkWarningPoint = useCheckWarningPoint();
  const checkDpi = useCheckDpi();

  useEffect(() => {
    //Ignore checking scene if there are subproducts
    if (productData?.get('labData')?.has_subproducts || !labData?.variants[activeVariant]) return;

    const scenes = Object.keys(labData.variants[activeVariant].scenes);
    let tmpWarnings: Record<string, Record<string, boolean>> = {}
    scenes.forEach(scene => {
      tmpWarnings[scene] = {
        'templates_covered': false,
        'resolution': true,
      };

      //Check templates_covered
      let templatesCoveredOk = true;

      if (labData) {
        labData.variants[activeVariant]?.scenes[scene]?.warnings.forEach((warning: Point) => {
          templatesCoveredOk = templatesCoveredOk && checkWarningPoint(warning, scene);
        });
      }

      //Check resolution
      let resolutionOk = true;
      layers.forEach(layer => {
        if (layer.get('scene') !== scene) {
          return;
        }

        const { dpiIndicator } = checkDpi(layer);

        resolutionOk = resolutionOk && dpiIndicator !== 'bad';
      })

      tmpWarnings[scene].templates_covered = templatesCoveredOk;
      tmpWarnings[scene].resolution = resolutionOk;
    })

    dispatch(EditWarnings(tmpWarnings));
  }, [productData, labData, layers, activeVariant, checkWarningPoint, checkDpi]);

  return <ValidIndicatorsContainer>
    <ValidIndicator
      valid={resolutionOk}
      validText={t('Resolution: Good')}
      invalidText={t('Resolution: Bad')}
      iconSize="medium"
    />
    <ValidIndicator
      valid={templatesCoveredOk}
      validText={t('Templates: Covered')}
      invalidText={t('Templates: Not Covered')}
      iconSize="medium"
    />
  </ValidIndicatorsContainer>
}