window.SupportDetector = {
	canvas: !! window.CanvasRenderingContext2D,
	webgl: ( function () {
		try {
			var canvas = document.createElement( 'canvas' ); return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );
		} catch ( e ) {
			return false;
		}
	} )(),
	workers: !! window.Worker,
	fileapi: window.File && window.FileReader && window.FileList && window.Blob,
	getWebGLErrorMessage: function (inlab) {
		var element = document.createElement( 'div' );
		element.className = 'webgl-error-message';
		element.style.textAlign = 'center';
		element.style.background = '#fff';
		element.style.color = '#000';
		element.style.padding = '1.5em';
		element.style.paddingTop = '40%';
		element.style.paddingBottom = inlab ? '14em' : '';
		element.style.position = 'absolute';
		element.style.top = '0';
		element.style.left = '0';
		element.style.right = '0';
		element.style.bottom = '0';
		element.style.overflow = 'hidden';
		element.innerHTML = window.WebGLRenderingContext ? [
			'Your graphics card does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation">WebGL</a>.<br />',
			'Find out how to get it <a href="http://get.webgl.org/">here</a>.'
		].join( '\n' ) : [
			'Your browser does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation">WebGL</a>.<br/>',
			'Find out how to get it <a href="http://get.webgl.org/">here</a>.'
		].join( '\n' );
		return element;
	},
	addGetWebGLMessage: function ( parameters ) {
		var parent, id, element;
		parameters = parameters || {};
		parent = parameters.parent !== undefined ? parameters.parent : document.body;
		id = parameters.id !== undefined ? parameters.id : 'oldie';
		element = Detector.getWebGLErrorMessage();
		element.id = id;
		parent.appendChild( element );
	}
};