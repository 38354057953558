import { Layer as LayerNode, Transformer as TransformerNode } from 'react-konva';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import { Ref, forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { Box, Transformer } from 'konva/lib/shapes/Transformer';
import { Node } from 'konva/lib/Node';

export type TransformerHandle = {
  selectNodes: (nodes:Node[]) => void,
}

interface Props {
  getLayerRefs: () => Map<string, Node>
}

export default forwardRef(function TransformerLayer(props:Props, ref:Ref<TransformerHandle>) {
  const layers = useAppSelector(state => state.get('UIData').get('designLab').get('layers'));
  const selectedLayerId = useAppSelector(state => state.get('UIData').get('designLab').get('selectedLayer'));
  const zoomScale = useAppSelector(state => state.get('UIData').get('designLab').get('zoomScale'));

  const transformerRef = useRef<Transformer>(null);

  useImperativeHandle(ref, () => {
    return {
      selectNodes: (nodes) => {
        transformerRef.current?.nodes(nodes)
      },
    }
  }, []);

  useEffect(() => {
    if (!transformerRef.current) return;
    const listLayerRefs = props.getLayerRefs();
    const selectedLayerRef = selectedLayerId ? listLayerRefs.get(selectedLayerId) : undefined;

    if (selectedLayerId && selectedLayerRef) {
      transformerRef.current.nodes([selectedLayerRef]);
    } else {
      transformerRef.current.nodes([]);
    }
  }, [selectedLayerId, props.getLayerRefs]);

  const boundBoxFunc = useCallback((oldBox:Box, newBox:Box) => {
    if (newBox.width / zoomScale < 20 || newBox.height / zoomScale < 20) {
      return oldBox;
    }

    return newBox;
  }, [zoomScale])

  return <LayerNode>
    <TransformerNode
      ref={transformerRef}
      enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
      rotationSnaps={[0, 45, 90, 135, 180, 225, 270, 315]}
      borderStroke={"#000"}
      borderStrokeWidth={2}
      borderDash={[4]}
      anchorSize={16}
      anchorCornerRadius={2}
      anchorStroke={"#000"}
      anchorStrokeWidth={2}
      keepRatio={true}
      flipEnabled={false}
      useSingleNodeRotation
      centeredScaling
      opacity={selectedLayerId && layers?.get(selectedLayerId) && layers.get(selectedLayerId)?.get('type') === 'text' && layers.get(selectedLayerId)?.get('width') === 0 && layers.get(selectedLayerId)?.get('height') === 0 ? 0 : 1}
      boundBoxFunc={boundBoxFunc}
    /*onTransform={(e) => {
        dispatch(EditLayer(selectedLayerId,  {
            x: e.target.attrs.x - stageOffset.x,
            y: e.target.attrs.y - stageOffset.y,
            width: e.target.attrs.width,
            height: e.target.attrs.height,
            scaleX: e.target.attrs.scaleX,
            scaleY: e.target.attrs.scaleY,
            rotation: e.target.attrs.rotation,
        }))
    }}
    onTransformEnd={(e) => {
        dispatch(EditLayer(selectedLayerId,  {
            x: e.target.attrs.x - stageOffset.x,
            y: e.target.attrs.y - stageOffset.y,
            width: e.target.attrs.width,
            height: e.target.attrs.height,
            scaleX: e.target.attrs.scaleX,
            scaleY: e.target.attrs.scaleY,
            rotation: e.target.attrs.rotation,
        }))
    }}*/
    />
  </LayerNode>
})