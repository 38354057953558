import { type ChangeEvent, type MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, ListItemIcon, MenuItem, Skeleton, ToggleButton, ToggleButtonGroup, styled, useMediaQuery, useTheme } from '@mui/material'
import { ViewList as ViewListIcon, ViewModule as ViewModuleIcon, AddRounded as AddRoundedIcon } from '@mui/icons-material'
import { SaveUserPrefs } from '../../UserData/_actions/UserDataActions'
import { useTranslation } from 'react-i18next'
import { ProductCardWrapper } from './ProductDesign'
import ItemList from '../../Common/ItemList/ItemList'
import LimitSelect from '../../Common/ItemList/LimitSelect'
import AccountHeader from '../AccountHeader'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { type UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore'
import ProductSearchBar, { type ProductSearchParams } from './ProductSearchBar'
import { useSearchParams } from 'react-router-dom'
import { FetchCollections } from '../../UserData/_actions/CollectionActions'
import SelectV2 from '../../Common/Form/SelectV2'
import NewCollectionDialog from '../Collections/NewCollectionDialog'
import CollectionSettingsButton from '../Collections/CollectionSettingsButton'
import usePrevious from '../../Common/_hooks/usePrevious'
import { type CollectionStore } from '../../UserData/_stores/CollectionStore'
import ProductDesignMainActions from './ProductDesignMainActions'

const StyledToggleButton = styled(ToggleButton)({
  background: 'none',
  border: 0,
  borderRadius: 0
})

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  alignSelf: 'stretch',

  [`${StyledToggleButton}`]: {
    '&:not(:first-of-type)': {
      border: 0,
      margin: 0
    }
  }
})

const ProductSkeleton = styled(Skeleton)({
  backgroundColor: 'rgba(0,0,0,0.1)',
  width: '100%',
  height: 'auto',
  boxSizing: 'content-box',
  aspectRatio: '1120 / 1000',
  paddingBottom: '100px',
  borderRadius: '20px',

  '&.list-view': {
    paddingBottom: 0,
    aspectRatio: 'auto',
    height: '72px',
    margin: '10px 0',
  }
})

const ProductCardSkeleton = styled(ProductCardWrapper)({
})

const SEARCH_FIELDS = ['search', 'categories', 'collections']

export default function ProductDesignList() {
  const [t] = useTranslation('account-v2')
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const theme = useTheme()
  const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'))

  const userLayout = useAppSelector(state => state.get('userData').get('prefs').get('accountProductsLayout'))
  const userLimit = useAppSelector(state => state.get('userData').get('prefs').get('accountPageLimit'))
  const collections = useAppSelector(state => state.get('userData').get('collections'))

  // Init search bar with url search param
  const categories = searchParams.get('categories')
  const searchQuery = searchParams.get('search')
  const paramCollections = searchParams.get('collections')
  const [search, setSearch] = useState<ProductSearchParams>({
    categories: categories ? categories.split(',') : [],
    search: searchQuery ? searchQuery.split(',') : [],
    collections: paramCollections ? paramCollections.split(',') : []
  })

  const [selectedCollectionId, setSelectedCollectionId] = useState(Number(paramCollections) ?? 0)
  const [limit, setLimit] = useState(userLimit)
  const [layout, setLayout] = useState<'grid' | 'list'>(userLayout)

  const [newCollectionDialogOpened, setNewCollectionDialogOpened] = useState(false)

  const onNewCollectionDialogOpen = useCallback(() => {
    setNewCollectionDialogOpened(true)
  }, [])

  const onNewCollectionDialogClose = useCallback(() => {
    setNewCollectionDialogOpened(false)
  }, [])

  const onCollectionAdd = useCallback((collectionId: number) => {
    setSelectedCollectionId(collectionId)
    setSearch({
      ...search,
      collections: [String(collectionId)]
    })
  }, [search])

  useEffect(() => {
    dispatch(FetchCollections())
  }, [])

  const loadingGrid = useMemo(() => {
    const listSkeletons = []

    for (let i = 0; i < limit; i++) {
      listSkeletons.push(<ProductCardSkeleton key={i} item className={layout === 'list' ? 'list-view' : ''}><ProductSkeleton variant="rectangular" className={layout === 'list' ? 'list-view' : ''}/></ProductCardSkeleton>)
    }

    return listSkeletons
  }, [limit, layout])

  useEffect(() => {
    // Save preferences if any have changed
    if (layout === userLayout) {
      return
    }

    const fd = new FormData()
    fd.append('accountProductsLayout', layout)

    dispatch(SaveUserPrefs(fd, true))
  }, [layout])

  const renderItem = useCallback((item: UserProductDesignStore) => {
    return <ProductDesignMainActions
      item={item}
      view={layout}
    />
  }, [layout])

  const renderList = useCallback((items: JSX.Element | null, links: JSX.Element | null, loading: boolean) => {
    return <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {loading
          ? loadingGrid
          : (items ?? <Grid item xs={12}>
            <span>{t('No Results')}</span>
          </Grid>
            )
        }
      </Grid>

      {links}
    </>
  }, [loadingGrid, t])

  const selectedCollection = useMemo(() => {
    return collections.get(String(selectedCollectionId))
  }, [selectedCollectionId, collections])

  const prevSelectedCollection = usePrevious(selectedCollection)

  useEffect(() => {
    // Reset collection if we lost the collection object (after deletion)
    if (selectedCollectionId > 0 && !selectedCollection && prevSelectedCollection?.get('id') === selectedCollectionId) {
      setSelectedCollectionId(0)
      setSearch(value => ({
        ...value,
        collections: []
      }))
    }
  }, [selectedCollectionId, selectedCollection])

  const renderCollectionItem = useCallback((collection: CollectionStore) => {
    return <MenuItem
      key={collection.get('id')}
      value={collection.get('id')}
    >{collection.get('name')}</MenuItem>
  }, [])

  const onChangeCollection = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === 'new') return

    setSelectedCollectionId(Number(e.target.value))
    setSearch(value => ({
      ...value,
      collections: Number(e.target.value) === 0 ? [] : [e.target.value]
    }))
  }, [])

  const onLayoutChange = useCallback((e: MouseEvent<HTMLElement>, value?: 'grid' | 'list') => {
    if (!value) return
    setLayout(value)
  }, [])

  return <div>
    <AccountHeader
      title={selectedCollection ? selectedCollection.get('name') : t('Products')}
      titleActions={selectedCollection ? <CollectionSettingsButton
        collection={selectedCollection}
      /> : null}
      actions={<div style={{ minWidth: '100px' }}>
        <SelectV2
          value={selectedCollection ? selectedCollection.get('id') : 0}
          onChange={onChangeCollection}
        >
          <MenuItem value={0}>{t('All products')}</MenuItem>
          {collections.valueSeq().map(renderCollectionItem)}
          <MenuItem
            value="new"
            sx={{
              color: 'primary.main'
            }}
            onClick={onNewCollectionDialogOpen}
          >
            <ListItemIcon><AddRoundedIcon color="primary"/></ListItemIcon>
            {t('New collection')}
          </MenuItem>
        </SelectV2>
      </div>}
    />
    <ProductSearchBar
      placeholder={t('Search your products...')}
      value={search}
      onChange={setSearch}
    >
      { !breakpointSmDown ? <>
        <LimitSelect
          value={limit}
          onChange={setLimit}
        />
        <StyledToggleButtonGroup
          value={layout}
          exclusive
          onChange={onLayoutChange}
        >
          <StyledToggleButton value="grid">
            <ViewModuleIcon />
          </StyledToggleButton>
          <StyledToggleButton value="list">
            <ViewListIcon />
          </StyledToggleButton>
        </StyledToggleButtonGroup>
      </> : null }
    </ProductSearchBar>
    <ItemList
      dataType={'productDesigns'}
      item={renderItem}
      searchQuery={search}
      searchFields={SEARCH_FIELDS}
      limit={limit}
      render={renderList}
    />

    <NewCollectionDialog
      opened={newCollectionDialogOpened}
      onClose={onNewCollectionDialogClose}
      onAdd={onCollectionAdd}
    />
  </div>
}
