import { Grid, Pagination as MuiPagination, styled, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useCallback, useMemo, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PaginationStore, { PaginationItem } from '../../UserData/_stores/PaginationStore';
import { EditPaginationLoader, FetchPaginationItems } from '../../UserData/_actions/UserDataActions';
import { UserImageStore } from '../../UserData/_stores/UserImageStore';
import { UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore';
import { UserAddressStore } from '../../UserData/_stores/UserAddressStore';
import { v4 as uuidv4 } from 'uuid';
import { UserCreditCardStore } from '../../UserData/_stores/UserCreditCardStore';
import { useAppDispatch } from '../_hooks/useAppDispatch';
import UserCartStore from '../../UserData/_stores/UserCartStore';
import { PhotoLabCreationStore } from '../../UserData/_stores/PhotoLabCreationStore';
import { useAppSelector } from '../_hooks/useAppSelector';

const StyledPagination = styled(MuiPagination)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	padding: theme.spacing(2, 0),

	[theme.breakpoints.down('md')]: {
		padding: theme.spacing(1, 0),
	}
}));

export interface ItemListSearchParams {
	[key: string]: string[]
}

export type Props = {
	listId?: string
	controlled?: boolean
	fetchPaginationItems?: Function
	render: (items: JSX.Element | null, links: JSX.Element | null, loading: boolean, page: number) => JSX.Element
	loading?: boolean
	maxItemsCount?: number
	searchQuery?: string | ItemListSearchParams
	searchFields?: string[]
	page?: number
	limit?: number
	ids?: Array<string>
	searchPlaceHolder?: string
	excludeId?: string
} & ({
	dataType: 'images'
	item: (item: UserImageStore, index: number) => JSX.Element
} | {
	dataType: 'productDesigns'
	item: (item: UserProductDesignStore, index: number) => JSX.Element
} | {
	dataType: 'addresses'
	item: (item: UserAddressStore, index: number) => JSX.Element
} | {
	dataType: 'creditCards'
	item: (item: UserCreditCardStore, index: number) => JSX.Element
} | {
	dataType: 'orders'
	item: (item: UserCartStore, index: number) => JSX.Element
} | {
	dataType: 'photoLabCreations'
	item: (item: PhotoLabCreationStore, index: number) => JSX.Element
})

export default function ItemList(props: Props) {
	const listId = useMemo(() => props.listId ? props.listId : uuidv4(), [props.listId]);

	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useAppDispatch();
	const [searchParams, setSearchParams] = useSearchParams()
	const theme = useTheme()
	const breakpointSmDown = useMediaQuery(theme.breakpoints.down('sm'))

	const itemList = useAppSelector(state => (state.get('userData').get(props.dataType) as PaginationStore).get('itemLists').get(listId));
	const data = useAppSelector(state => (state.get('userData').get(props.dataType) as PaginationStore).get('data'));

	const [searchQuery, setSearchQuery] = useState(props.searchQuery || '')
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(props.limit || 20)
	const [ids, setIds] = useState(props.ids || [])

	useEffect(() => {
		setSearchQuery(props.searchQuery || '');
	}, [props.searchQuery]);

	useEffect(() => {
		if (!props.limit) return;

		setLimit(props.limit);
	}, [props.limit]);

	useEffect(() => {
		setIds(props.ids || []);
	}, [props.ids]);

	const fetchItems = useCallback(() => {
		let tmpLimit = props.excludeId && page === 1 ? limit - 1 : limit;
		let offset = props.excludeId ? (
			page > 1 ? (limit - 1) + limit * (page - 2) : 0
		) : limit * (page - 1);
		let formData = new FormData();
		formData.append("offset", String(offset));

		if (typeof searchQuery === 'object') {
			Object.keys(searchQuery).forEach(key => {
				formData.append(key, searchQuery[key].join(','))
			})
		} else {
			formData.append("search", searchQuery);
		}

		formData.append("limit", String(tmpLimit));

		ids.forEach((id) => formData.append("ids[]", id));

		if (props.excludeId) {
			formData.append('excludeId', props.excludeId);
		}

		dispatch(EditPaginationLoader(props.dataType, listId, true));
		dispatch(FetchPaginationItems(listId, formData, props.dataType).set({
			onSuccess: () => {
				dispatch(EditPaginationLoader(props.dataType, listId, false));
			}
		}));
	}, [props.dataType, props.excludeId, page, limit, searchQuery, ids, listId]);

	useEffect(() => {
		if (props.controlled) return;

		let queryString = new URLSearchParams(location.search)

		if (props.searchFields) {
			const newSearchQuery: Record<string, string[]> = {}
			props.searchFields.forEach(searchField => {
				newSearchQuery[searchField] = queryString.get(searchField)?.split(',') ?? [];
			})
			setSearchQuery(newSearchQuery)
		} else {
			setSearchQuery(queryString.get('search') ?? '')
		}
		setLimit(Number(queryString.get('limit')) || 20)
		setPage(Number(queryString.get('page')) || 1)
		const ids = queryString.get('ids');
		setIds(ids ? ids.split(',') : [])
	}, [location, props.controlled]);

	useEffect(() => {
		//Reset to page 1 when excludeId changes
		if (props.excludeId && page !== 1) {
			setPage(1);
		}
	}, [props.excludeId]);

	/*useEffect(() => {
		if(props.controlled) {
			updateStateControlled(props.page || page)
		}
	}, [props.limit, props.ids, props.page])*/

	useEffect(() => {
		let hasChanged = false

		if (!props.controlled && props.searchQuery !== undefined) {
			if (typeof props.searchQuery === 'object') {
				const keys = Object.keys(props.searchQuery)
				for (let key of keys) {
					const value = props.searchQuery[key].join(',');

					if (value !== searchParams.get(key)) {
						searchParams.set(key, value)
						hasChanged = true
					}
				}
			} else {
				const value = props.searchQuery.replace('%', '')

				if (value !== searchParams.get('search')) {
					searchParams.set('search', props.searchQuery.replace('%', ''))
					hasChanged = true
				}
			}

			//Reset page and update searchParams if they changed
			if (hasChanged) {
				searchParams.set('page', '1')

				setSearchParams(searchParams, {
					replace: true,
				})
			}
		}
	}, [props.searchQuery]);

	useEffect(() => {
		if (!props.controlled && props.limit !== undefined) {
			const value = String(props.limit)
			if (value !== searchParams.get('limit')) {
				searchParams.set('page', '1')
				searchParams.set('limit', value)
				setSearchParams(searchParams, {
					replace: true,
				})
			}
		}
	}, [props.limit]);

	useEffect(() => {
		fetchItems()
	}, [page, searchQuery, limit, ids, props.excludeId])

	useEffect(() => {
		if (!itemList?.get('valid')) fetchItems()
	}, [itemList])

	const renderLinks = () => {
		let totalPages = itemList?.get('total') ? Math.ceil(itemList.get('total') / limit) : 1;

		if (totalPages <= 1 && page === 1) {
			return null;
		}

		return <StyledPagination
			count={totalPages}
			showFirstButton={breakpointSmDown ? false : totalPages > 5}
			showLastButton={breakpointSmDown ? false : totalPages > 5}
			siblingCount={breakpointSmDown ? 0 : 1}
			page={page}
			onChange={(e, value) => {
				if (props.controlled) {
					setPage(value);
				} else {
					searchParams.set('page', String(Math.ceil(((value - 1) * limit + 1) / +limit)))
					searchParams.set('limit', String(limit))
					navigate(location.pathname + '?' + searchParams.toString())
				}
			}}
		/>
	}

	const renderItems = () => {
		if (!itemList?.get('items').count()) return null;

		let renderedItems = itemList.get('items')
			.valueSeq()
			.take(props.maxItemsCount ? props.maxItemsCount : limit)
			.map((id, key) => {
				const itemData = data.get(id);
				if (!itemData) return null;

				return <Fragment key={id}>{props.item(itemData, key)}</Fragment>;
			});

		return <>{renderedItems}</>
	}

	return props.render(renderItems(), renderLinks(), itemList?.get('loading') || (!itemList?.get('valid') && !itemList?.get('items').count()), page)
}
