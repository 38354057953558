import { LegacyRef, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import KonvaImage from "../KonvaNode/KonvaImage";
import { EditGuides, EditLayer, SelectLayer } from "../../UIData/_actions/DesignLabStoreActions";
import useStageOffset from "../_hooks/useStageOffset";
import KonvaPatternImage from "../KonvaNode/KonvaPatternImage";
import { LAB_TEMPLATE_HEIGHT, LAB_TEMPLATE_WIDTH } from "../../config";
import { Line, Text as TextNode, Layer as LayerNode } from "react-konva";
import { Node } from "konva/lib/Node";
import { Text } from "konva/lib/shapes/Text";
import { Image } from "konva/lib/shapes/Image";
import { TransformerHandle } from "./TransformerLayer";
import { GuideSnap, GuideStore } from "../../UIData/_stores/DesignLab/GuideStore";

const GUIDELINE_OFFSET = 10;
const MAX_LAB_SIDE = Math.max(LAB_TEMPLATE_WIDTH, LAB_TEMPLATE_HEIGHT);

interface Props {
  layerId: string
  getLayerRefs: () => Map<string, Node>
  getTransformerRef: () => TransformerHandle | null
}

export default function ContentNode(props: Props) {
  const dispatch = useAppDispatch()

  const stageOffset = useStageOffset();
  const layer = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId));
  const isPanning = useAppSelector(state => state.get('UIData').get('designLab').get('isPanning'));
  const zoomScale = useAppSelector(state => state.get('UIData').get('designLab').get('zoomScale'));
  const selectedLayerId = useAppSelector(state => state.get('UIData').get('designLab').get('selectedLayer'));

  //Initialize text width/height 
  useEffect(() => {
    const listLayerRefs = props.getLayerRefs();
    if (!layer) return;

    if (layer.get('type') === 'text' && (layer.get('width') === 0 || layer.get('height') === 0)) {
      dispatch(EditLayer(layer.get('id'), {
        width: (listLayerRefs.get(layer.get('id')) as Text)?.getTextWidth(),
        height: listLayerRefs.get(layer.get('id'))?.height(),
      }))
    }
  }, [layer]);

  const onSelect = useCallback(() => {
    dispatch(SelectLayer(props.layerId))
  }, [props.layerId])

  const updateLayerFromKonva = useCallback((layerId: string, e: any) => {
    dispatch(EditLayer(layerId, {
      x: e.target.attrs.x - stageOffset.x,
      y: e.target.attrs.y - stageOffset.y,
      width: e.target.attrs.width,
      height: e.target.attrs.height,
      scaleX: e.target.attrs.scaleX,
      scaleY: e.target.attrs.scaleY,
      rotation: e.target.attrs.rotation,
    }))
  }, [stageOffset]);

  const onDragStart = useCallback(() => {
  }, [])

  const onDragEnd = useCallback((e: any) => {
    dispatch(EditGuides([]))
    updateLayerFromKonva(props.layerId, e);
  }, [props.layerId, updateLayerFromKonva])

  const onTransform = useCallback((e: any) => {
    updateLayerFromKonva(props.layerId, e);
  }, [props.layerId, updateLayerFromKonva])

  const onTransformText = useCallback((e: any) => {
    dispatch(EditLayer(props.layerId, {
      x: e.target.attrs.x - stageOffset.x,
      y: e.target.attrs.y - stageOffset.y,
      width: e.target.getTextWidth(),
      height: e.target.height(),
      scaleX: e.target.attrs.scaleX,
      scaleY: e.target.attrs.scaleY,
      rotation: e.target.attrs.rotation,
    }))
  }, [props.layerId, stageOffset])

  const refFunc = useCallback((node: Text | Image | null) => {
    const listLayerRefs = props.getLayerRefs();
    const transformerRef = props.getTransformerRef();

    if (node) {
      listLayerRefs.set(props.layerId, node);

      if (props.layerId === selectedLayerId) {
        transformerRef?.selectNodes([node])
      }
    } else {
      listLayerRefs.delete(props.layerId);

      if (props.layerId === selectedLayerId) {
        transformerRef?.selectNodes([])
      }
    }
  }, [props.layerId, props.getLayerRefs, props.getTransformerRef])

  // were can we snap our objects?
  const getLineGuideStops = useCallback((skipShape: Node) => {
    let vertical: number[] = [];
    let horizontal: number[] = [];

    //Snap to Edges and Centers of each object
    const listLayerRefs = props.getLayerRefs();
    listLayerRefs.forEach((guideItem) => {
      if (guideItem === skipShape) {
        return;
      }
      var box = guideItem.getClientRect();

      //Snap to all edges of shape
      vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
      horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
    });

    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  }, [props.getLayerRefs]);

  //Get edges and center of objet to snap to
  const getObjectSnappingEdges = useCallback((node: Node) => {
    var box = node.getClientRect();
    var absPos = node.absolutePosition();

    return {
      vertical: [
        {
          guide: box.x,
          offset: absPos.x - box.x,
          snap: 'start' as GuideSnap,
        },
        {
          guide: box.x + box.width / 2,
          offset: absPos.x - box.x - box.width / 2,
          snap: 'center' as GuideSnap,
        },
        {
          guide: box.x + box.width,
          offset: absPos.x - box.x - box.width,
          snap: 'end' as GuideSnap,
        },
      ],
      horizontal: [
        {
          guide: box.y,
          offset: absPos.y - box.y,
          snap: 'start' as GuideSnap,
        },
        {
          guide: box.y + box.height / 2,
          offset: absPos.y - box.y - box.height / 2,
          snap: 'center' as GuideSnap,
        },
        {
          guide: box.y + box.height,
          offset: absPos.y - box.y - box.height,
          snap: 'end' as GuideSnap,
        },
      ],
    };
  }, []);

  const getGuides = useCallback((
    lineGuideStops: { vertical: number[], horizontal: number[] },
    itemBounds: { vertical: { guide: number, offset: number, snap: GuideSnap }[], horizontal: { guide: number, offset: number, snap: GuideSnap }[] }
  ) => {
    var resultV: {
      lineGuide: number,
      diff: number,
      snap: GuideSnap,
      offset: number,
    }[] = [];
    var resultH: {
      lineGuide: number,
      diff: number,
      snap: GuideSnap,
      offset: number,
    }[] = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        var diff = Math.abs(lineGuide - itemBound.guide);

        //If distance between guideline and object snap point is within offset, add it
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        var diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    var guides: GuideStore[] = [];

    //Find closest snap point
    var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
    if (minV) {
      guides.push(new GuideStore({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap,
      }));
    }
    if (minH) {
      guides.push(new GuideStore({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap,
      }));
    }
    return guides;
  }, []);

  const onDragMove = useCallback((e: any) => {
    // find possible snapping lines
    var lineGuideStops = getLineGuideStops(e.target);
    // find snapping points of current object
    var itemBounds = getObjectSnappingEdges(e.target);

    // now find where can we snap current object
    var guides = getGuides(lineGuideStops, itemBounds);

    // do nothing if no snapping or we're in pattern mode
    if (!guides.length || layer?.get('patternMode') === 'repeat') {
      updateLayerFromKonva(props.layerId, e);
      return;
    }

    dispatch(EditGuides(guides))

    var absPos = e.target.absolutePosition();
    // now force object position
    guides.forEach((lg) => {
      switch (lg.snap) {
        case 'start': {
          switch (lg.orientation) {
            case 'V':
              absPos.x = lg.lineGuide + lg.offset;
              break;

            case 'H':
              absPos.y = lg.lineGuide + lg.offset;
              break;
          }
          break;
        }
        case 'center': {
          switch (lg.orientation) {
            case 'V':
              absPos.x = lg.lineGuide + lg.offset;
              break;

            case 'H':
              absPos.y = lg.lineGuide + lg.offset;
              break;
          }
          break;
        }
        case 'end': {
          switch (lg.orientation) {
            case 'V':
              absPos.x = lg.lineGuide + lg.offset;
              break;
            case 'H':
              absPos.y = lg.lineGuide + lg.offset;
              break;
          }
          break;
        }
      }
    });
    e.target.absolutePosition(absPos);
    updateLayerFromKonva(props.layerId, e);
  }, [props.layerId, layer?.get('patternMode'), getLineGuideStops, getObjectSnappingEdges, getGuides, updateLayerFromKonva])

  const dashLength = useMemo(() => 4 * (1 / zoomScale), [zoomScale]);

  const linePoints = useMemo(() => {
    if (!layer) return [];
    return [
      0, 0,
      layer.get('width') * layer.get('scaleX'), 0,
      layer.get('width') * layer.get('scaleX'), layer.get('height') * layer.get('scaleY'),
      0, layer.get('height') * layer.get('scaleY'),
      0, 0,
    ]
  }, [layer])

  const line1Dash = useMemo(() => [dashLength, dashLength, 0], [dashLength]);
  const line2Dash = useMemo(() => [0, dashLength, dashLength], [dashLength]);

  if (!layer) return null;

  return <>
    {['img', 'pattern'].includes(layer.get('type')) ? <>
      {layer.get('patternMode') !== 'none' ? <>
        <KonvaPatternImage
          key={layer.get('id') + '-pattern'}
          x={stageOffset.x + layer.get('x')}
          y={stageOffset.y + layer.get('y')}
          width={MAX_LAB_SIDE * 2}
          height={MAX_LAB_SIDE * 2}
          imageWidth={layer.get('width')}
          imageHeight={layer.get('height')}
          fillPatternX={MAX_LAB_SIDE}
          fillPatternY={MAX_LAB_SIDE}
          fillPatternOffsetX={layer.get('width') / 2}
          fillPatternOffsetY={layer.get('height') / 2}
          src={layer.get('src')}
          fillPatternScaleX={layer.get('scaleX')}
          fillPatternScaleY={layer.get('scaleY')}
          offsetX={MAX_LAB_SIDE}
          offsetY={MAX_LAB_SIDE}
          rotation={layer.get('rotation')}
          patternMode={layer.get('patternMode')}
          listening={false}
        />


        {layer.get('id') !== selectedLayerId ? <>
          <Line
            key={layer.get('id') + '-line1'}
            points={linePoints}
            stroke="#ffffff"
            strokeWidth={2 * (1 / zoomScale)}
            dash={line1Dash}
            x={layer.get('x') + stageOffset.x}
            y={layer.get('y') + stageOffset.y}
            offsetX={(layer.get('width') * layer.get('scaleX')) / 2}
            offsetY={(layer.get('height') * layer.get('scaleY')) / 2}
            rotation={layer.get('rotation')}
            listening={false}
          />
          <Line
            key={layer.get('id') + '-line2'}
            points={linePoints}
            stroke="#000000"
            strokeWidth={2 * (1 / zoomScale)}
            dash={line2Dash}
            x={layer.get('x') + stageOffset.x}
            y={layer.get('y') + stageOffset.y}
            offsetX={(layer.get('width') * layer.get('scaleX')) / 2}
            offsetY={(layer.get('height') * layer.get('scaleY')) / 2}
            rotation={layer.get('rotation')}
            listening={false}
          />
        </> : null}
      </> : null}

      <KonvaImage
        key={layer.get('id')}
        ref={refFunc}
        name={'object'}
        draggable={!isPanning}
        onMouseDown={onSelect}
        onTouchStart={onSelect}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onTransform={onTransform}
        src={layer.get('src')}
        rotation={layer.get('rotation')}
        width={layer.get('width')}
        height={layer.get('height')}
        offsetX={layer.get('width') / 2}
        offsetY={layer.get('height') / 2}
        x={layer.get('x') + stageOffset.x}
        y={layer.get('y') + stageOffset.y}
        scaleX={layer.get('scaleX')}
        scaleY={layer.get('scaleY')}
      />
    </> : null}

    {layer.get('type') === 'text' ? <>
      <TextNode
        key={layer.get('id')}
        ref={refFunc}
        name={'object'}
        draggable={!isPanning}
        onMouseDown={onSelect}
        onTouchStart={onSelect}
        onDragStart={onDragStart}
        onDragMove={onDragMove}
        onDragEnd={onDragEnd}
        onTransform={onTransformText}
        text={layer.get('text')}
        fontSize={50}
        fontFamily={layer.get('font')}
        fill={layer.get('color')}
        fillEnabled={true}
        opacity={layer.get('width') === 0 && layer.get('height') === 0 ? 0 : 1}
        //stroke="#00ff00"
        //strokeWidth={5}
        //strokeEnabled={true}
        rotation={layer.get('rotation')}
        width={layer.get('width') !== 0 ? layer.get('width') : undefined}
        height={layer.get('height') !== 0 ? layer.get('height') : undefined}
        offsetX={layer.get('width') / 2}
        offsetY={layer.get('height') / 2}
        x={layer.get('x') + stageOffset.x}
        y={layer.get('y') + stageOffset.y}
        scaleX={layer.get('scaleX')}
        scaleY={layer.get('scaleY')}
      />
    </> : null}
  </>
}