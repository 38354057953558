import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Slider, Tooltip, Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InputSelect } from '../../_components/DesignLab';
import { useTranslation } from 'react-i18next';
import useLabData from '../../_hooks/useLabData';
import { AddImgLayer, EditActiveVariant, EditLayer } from '../../../UIData/_actions/DesignLabStoreActions';
import { UserImageStore } from '../../../UserData/_stores/UserImageStore';
import { LayerPatternMode } from '../../../UIData/_stores/DesignLab/DesignLabLayerStore';
import ProductOptionStore from '../../../AppData/_stores/ProductOptionStore';
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch';
import ImageLibraryBrowser from '../../ImageLibraryBrowser/_components/ImageLibraryBrowser';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import { LabWrapper, LeftContainer } from '../../_components/LabInterface';

const ArrowWhite = styled('img')({
    marginLeft: '15px',

    '@media (max-width: 1200px), (max-height: 900px)': {
        width: '30px',
    },
})

const FabricsList = styled('div')(({theme}) => ({
    width: '300px',
    padding: '0 15px',

    '&.hidden': {
        transform: 'translateX(-100px)',
        opacity: 0,
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        transform: 'translateX(-100px)',
        opacity: 0,

        '&.active': {
            opacity: 1,
            transform: 'none',
        },
    }
}));

const FabricItem = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: '15px',
    paddingRight: '15px',
    cursor: 'pointer',
    transition: 'background-color 250ms',
    borderRadius: '20px',
    '&.selected, &:hover': {
        backgroundColor: '#E8E8E8',
    }
}));

const WhiteGrid = styled(Grid)(({theme}) => ({
    padding: '40px',

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        padding: '15px',
    },
}));

const FabricImageDrop = styled('div')(({theme}) => ({
    background: '#EBEBEB',
    border: 'dashed 2px #707070',
    borderRadius: '20px',
    height: '100%',
    minHeight: 350,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',

    ['@media (max-width: '+theme.breakpoints.values.sm+'px)']: {
        minHeight: 0,
        height: 150
    }
}));

const BlueGrid = styled(Grid)(({theme}) => ({
    padding: '60px',
    background: '#F2F6F8',
}));

const BlueGridTitle = styled('div')(({theme}) => ({
    textAlign: 'center', 
    marginBottom: '30px',
    fontSize: '24px',
}));

const SelectedFabricSpecs = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    'img': {
        width: '25px',
        marginRight: '10px',
    }
}));

const RepeatOptions = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    gap: 10,
    margin: '15px 0 30px',
    flexWrap: 'wrap',
}));

const RepeatOptionItem = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50px',
    background: '#EBEBEB',
    height: '50px',
    width: '50px',
    padding: '8px',
    cursor: 'pointer',
    'img': {
        maxWidth: '100%',
    }
}));

const CustomInputLabel = styled(InputLabel)(({theme}) => ({
    marginTop: '30px',
    marginBottom: '10px',
    color: '#000',
}));


const LayoutFormControl = styled(FormControl)`
    height: 45px;
    border-radius: 30px;
    background: #EBEBEB;
    color: #000;
    padding: 0 15px;
    width: 100%;
`

const FabricSeePreviewButton = styled(Button)(({theme}) => ({
    position: 'absolute',
    zIndex: 30,
    padding: '6px 20px',
    display: 'none',
    background: 'linear-gradient(0deg, #DA3192 0%, #F600BE 100%)',
    color: '#fff',
    borderRadius: '30px',
    fontWeight: 400,
    bottom: '15px',
    top: 'auto',
    margin: 'auto',
    left: 0,
    right: 0,
    width: 'fit-content',

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const StageContainer = styled('div')(({theme}) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 300ms, opacity 300ms',
    position: 'relative',
    opacity: 1,
    paddingLeft: 30,

    '&.hidden': {
        transform: 'translateY(100px)',
        opacity: 0,
    },

    '@media (max-width: 1510px), (max-height: 1070px)': {
        paddingLeft: '15px',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        paddingRight: '15px',
        transform: 'translateY(100px)',
        opacity: 0,

        '&.active': {
            opacity: 1,
            transform: 'none',
        }
    },
}));

type FabricRepeatMode = 'none'|'repeat'|'repeat-mirror'|'half-drop'|'half-brick'|'repeat-mirror-x'|'repeat-mirror-y';

const FABRIC_REPEAT_MODES:FabricRepeatMode[] = [
    'none',
    'repeat',
    'repeat-mirror',
    'repeat-mirror-x',
    'repeat-mirror-y',
    'half-drop',
    'half-brick',
];

type Props = {
    hide?: boolean
    active?: boolean
    onNext: () => void
}


export default function FabricLab(props: Props) {
    const dispatch = useAppDispatch();
    const [t] = useTranslation('design-lab');
    const activeScene = useAppSelector(state => state.get('UIData').get('designLab').get('activeScene'));
    const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));
    const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
    const firstLayer = useAppSelector(state => state.get('UIData').get('designLab').get('layers').first());
    const labData = useLabData();
    const productData = useAppSelector(state => state.get('appData').get('products').get('50'));

    const [fabricId, setFabricId] = useState<number|null>(null);
    const [libraryOpened,setLibraryOpened] = useState(false)

    const listFabrics = useMemo(() => {
        return productData?.get('availableOptionTypes').get('2')?.get('options');
    }, [productData]);

    const listFabricSizes = useMemo(() => {
        if(!listFabrics) return [];

        const fabric = listFabrics.get(String(fabricId));

        if(!fabric) return [];

        let tmpList:ProductOptionStore[] = [];

        productData?.get('availableOptionTypes').get('13')?.get('options').forEach(option => {
            //Check for fabric dependency
            if(!option.get('listDependencies').includes(fabric.get('id'))) {
                return;
            }

            tmpList.push(option);
        });
        
        return tmpList
    }, [productData, fabricId, listFabrics]);

    //Default to first fabric in list
    useEffect(() => {
        if(fabricId || !listFabrics) return;

        setFabricId(listFabrics.first()?.get('id') ?? null);
    }, [fabricId, listFabrics]);

    //Reset fabric size to the first in list if current doesnt exist
    useEffect(() => {
        const fabric = listFabrics?.get(String(fabricId));
        if(listFabricSizes.length < 1 || !fabric || !fabricId) return;
        
        //Grab size slug from current variant
        const sizeSlug = activeVariant.split('|')[1];

        let sizeFound = false;
        listFabricSizes.forEach(size => {
            if(size.get('slug') === sizeSlug) {
                sizeFound = true;
            }
        });

        if(sizeFound) {
            dispatch(EditActiveVariant(fabric.get('slug')+'|'+sizeSlug));
        } else {
            //Default to 1m
            dispatch(EditActiveVariant(fabric.get('slug')+'|1m'));
        }
    }, [listFabricSizes]);

    const repeatMode = useMemo(() => {
        if(!firstLayer) return 'none';
        const patternMode = firstLayer.get('patternMode');

        if(['repeat','repeat-mirror','repeat-mirror-x','repeat-mirror-y','half-drop','half-brick'].includes(patternMode)) {
            return patternMode;
        }

        return 'none';
    }, [firstLayer]);

    const onSelectImage = useCallback((item:UserImageStore) => {
        let originalWidth = item.get('width');
        let originalHeight = item.get('height');
        let width = originalWidth*30/150;
        let height = originalHeight*30/150;

        if(firstLayer) {
            dispatch(EditLayer(firstLayer.get('id'), {
                fileid: item.get('fileid'),
                src: '/api/user/images/image/'+item.get('fileid'),
                originalWidth: originalWidth,
                originalHeight: originalHeight,
                width: width,
                height: height,
                x: 0,
                y: 0,
                scaleX: 1,
                scaleY: 1,
                rotation: 0,
                alignment: 'center',
                patternMode: 'none',
            }))
        } else{
            dispatch(AddImgLayer({
                fileid: item.get('fileid'),
                src: '/api/user/images/image/'+item.get('fileid'),
                originalWidth: originalWidth,
                originalHeight: originalHeight,
                subproduct: null,
                scene: 'main',
                width: width,
                height: height,
                x: 0,
                y: 0,
                scaleX: 1,
                scaleY: 1,
                rotation: 0,
                alignment: 'center',
                patternMode: 'none',
            }))
        }
    }, [labData, activeScene, activeSubproduct, activeVariant, firstLayer]);

    const repeatModeNames = useMemo(() => {
        return {
            'none': t('No repeat'),
            'repeat': t('Block'),
            'repeat-mirror': t('Mirror'),
            'repeat-mirror-x': t('Mirror Horizontal'),
            'repeat-mirror-y': t('Mirror Vertical'),
            'half-drop': t('Half-Drop'),
            'half-brick': t('Half-Brick'),
        }
    }, [t]);

    const editRepeat = useCallback((repeat:LayerPatternMode) => {
        if(!firstLayer) return;

        dispatch(EditLayer(firstLayer.get('id'), {
            patternMode: repeat,
            alignment: repeat === 'none' ? 'center' : 'top-left',
        }))
    }, [firstLayer, activeVariant]);

    const onRepeatClick:Record<LayerPatternMode, () => void> = useMemo(() => {
        let tmpList:Record<string, () => void> = {};
        FABRIC_REPEAT_MODES.forEach(mode => {
            tmpList[mode] = () => editRepeat(mode);
        });
        return tmpList;
    }, [editRepeat]);

    const renderRepeatMode = useCallback((mode:FabricRepeatMode) => {
        const active = mode === repeatMode ? 'active':'';
        return <Tooltip
            key={mode}
            title={repeatModeNames[mode]}
            placement="bottom"
            disableInteractive
        >
            <RepeatOptionItem 
                onClick={onRepeatClick[mode]}
                className={mode === repeatMode ? 'active':''}
            >
                <img src={require('@resources/img/create/design-lab-v4/icons/repeat/'+mode+(active ? '-'+active:'')+'.svg?url')}/>
            </RepeatOptionItem>
        </Tooltip>
    }, [repeatMode, onRepeatClick, repeatModeNames]);

    const [patternWidthIn, patternHeightIn, patternDpi] = useMemo(() => {
        if(!labData) return [0, 0, 0];
        if(!firstLayer) return [0, 0, labData.dpi];

        return [
            Math.round((firstLayer.get('originalWidth')*firstLayer.get('scaleX'))/labData.dpi*100)/100,
            Math.round((firstLayer.get('originalHeight')*firstLayer.get('scaleY'))/labData.dpi*100)/100,
            Math.round(labData.dpi*(1 / firstLayer.get('scaleX'))*10)/10,
        ]
    }, [firstLayer, labData?.dpi]);

    const onLibraryOpen = useCallback(() => {
        setLibraryOpened(true)
    }, [])

    const onLibraryClose = useCallback(() => {
        setLibraryOpened(false)
    }, [])

    return <LeftContainer className={props.active ? 'active':''}>
        { /* <FabricsList className={props.hide ? 'hidden':(props.active ? 'active':'')}>
                <ListTitle style={{padding: '0',}}>Fabrics</ListTitle>
                <div>
                    { listFabrics ? listFabrics.valueSeq().map(fabric => {
                        return <FabricItem
                            key={fabric.get('id')}
                        >
                            <img 
                                src={'/static/img/all-products/products/'+fabric.get('slug')+'-fabric.jpg'}
                                style={{
                                    maxWidth: '85px', 
                                    maxHeight: '85px', 
                                    borderRadius: '15px',
                                    marginRight: '10px',
                                }} 
                            />
                            <Typography variant="body1" style={{
                                flex: '1',
                            }}>{ fabric.get('name') }</Typography>

                            <ValidIndicator 
                                valid={true} 
                                iconSize="small"
                                className="selected" 
                                style={{
                                    width: '25px',
                                }} 
                            />
                        </FabricItem>
                    }) : null }
                </div>
        </FabricsList> */ }
        <StageContainer 
            className={props.hide ? 'hidden':(props.active ? 'active':'')}
        >
            <FabricSeePreviewButton
                onClick={props.onNext}
            >{ t('Next') }<ArrowWhite src={require('@resources/img/create/design-lab-v4/icons/white-arrow.svg?url')}/></FabricSeePreviewButton>
            <LabWrapper
                style={{

                }}
            >
                <Paper 
                    elevation={3}
                    style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexFlow: 'column',
                        borderRadius: '20px 20px 0 0',
                        overflow: 'hidden',
                    }}
                >
                    <WhiteGrid container spacing={2}>
                        <Grid item xs={12} sm={5} xl={6}>
                                <FabricImageDrop style={{
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}>
                                    { firstLayer ? <img 
                                        src={firstLayer.get('src')}
                                        style={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    /> : null }
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={onLibraryOpen}
                                        startIcon={<img src={require('@resources/img/create/design-lab-v4/icons/add-image.svg?url')} alt={t('Add Image icon')} />}
                                    >{t('Upload Image')}</Button>
                                </FabricImageDrop>
                        </Grid>
                        <Grid item xs={12} sm={7} xl={6}>
                            { firstLayer ? <>
                                <CustomInputLabel id="layout" style={{marginTop: '0'}}>{ t('Repeat') }</CustomInputLabel>
                                <RepeatOptions>
                                    { FABRIC_REPEAT_MODES.map(renderRepeatMode) }
                                </RepeatOptions>

                                <CustomInputLabel id="fabric">{ t('Fabric') }</CustomInputLabel>
                                { listFabrics ? <LayoutFormControl variant="standard">
                                    <Select
                                        labelId="fabric"
                                        style={{color: '#000'}}
                                        variant="standard"
                                        input={<InputSelect/>}
                                        value={fabricId}
                                        onChange={(e) => {
                                            setFabricId(Number(e.target.value));
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }
                                        }}
                                        sx={{
                                            '.MuiSelect-icon': {
                                                color: '#000',
                                            }
                                        }}
                                    >
                                        { listFabrics.valueSeq().map(fabric => {
                                            return <MenuItem key={fabric.get('id')} value={fabric.get('id')}>{fabric.get('name')}</MenuItem>
                                        }) }
                                    </Select>
                                </LayoutFormControl> : null }

                                <CustomInputLabel id="fabric-size">{ t('Fabric Size') }</CustomInputLabel>
                                <LayoutFormControl variant="standard">
                                    <Select
                                        labelId="fabric-size"
                                        style={{color: '#000'}}
                                        variant="standard"
                                        input={<InputSelect/>}
                                        value={activeVariant}
                                        onChange={(e) => {
                                            dispatch(EditActiveVariant(e.target.value));
                                        }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }
                                        }}
                                        sx={{
                                            '.MuiSelect-icon': {
                                                color: '#000',
                                            }
                                        }}
                                    >
                                        { listFabricSizes.map(size => {
                                            const fabric = listFabrics?.get(String(fabricId));
                                            if(!fabric) return null;

                                            return <MenuItem key={size.get('id')} value={fabric.get('slug')+'|'+size.get('slug')}>{size.get('name')}</MenuItem>
                                        }) }
                                    </Select>
                                </LayoutFormControl>

                                <CustomInputLabel id="pattern-size">{ t('Pattern Size') } ({ patternWidthIn }" x { patternHeightIn }", { patternDpi } DPI)</CustomInputLabel>
                                <Slider
                                    value={firstLayer.get('scaleX')}
                                    min={0.1}
                                    max={1.5}
                                    step={0.01}
                                    valueLabelDisplay="auto" 
                                    onChange={(e, value) => {
                                        const newScale = Number(value);
                                        dispatch(EditLayer(firstLayer.get('id'), {
                                            scaleX: newScale,
                                            scaleY: newScale,
                                        }))
                                    }}
                                />
                            </> : null }
                        </Grid>
                    </WhiteGrid>
                    { /*<BlueGrid container spacing={2}>
                        <Grid item xs={12}>
                        <BlueGridTitle>You have selected : <strong>Silk Habotai</strong></BlueGridTitle>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectedFabricSpecs>
                                <img src="static/img/pages/fabric/design-2020/lightweight.svg" alt="Weight"/>
                                Weight: 275g/m2
                            </SelectedFabricSpecs>
                            <SelectedFabricSpecs>
                                <img src="static/img/pages/fabric/design-2020/sizes.svg" alt="Printable Width"/>
                                Printable Width: 55”/ 140cm
                            </SelectedFabricSpecs>
                            <SelectedFabricSpecs>
                                <img src="static/img/pages/fabric/design-2020/knit.svg" alt="Content"/>
                                Content: 100% cotton
                            </SelectedFabricSpecs>
                            <SelectedFabricSpecs>
                                <img src="static/img/pages/fabric/design-2020/color.svg" alt="Color"/>
                                Color: Off-white
                            </SelectedFabricSpecs>
                            <SelectedFabricSpecs>
                                <img src="static/img/pages/fabric/design-2020/print.svg" alt="Print Through"/>
                                Print Through: Low- roughly 10%
                            </SelectedFabricSpecs>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h4" style={{marginBottom: '0'}}>Features</Typography>
                            <Typography variant="body1" style={{marginBottom: '30px'}}>
                                <ul style={{marginTop: '0'}}>
                                    <li>Classic weave quilting cotton made from quality long staple cotton fibers.</li>
                                    <li>Easy to work with.</li>
                                    <li>Smooth, untextured print surface.</li>
                                </ul>
                            </Typography>
                            <Typography variant="h4" style={{marginBottom: '0'}}>Uses</Typography>
                            <Typography variant="body1" style={{marginBottom: '30px'}}>
                                <ul style={{marginTop: '0'}}>
                                    <li>Quilting & Crafts</li>
                                    <li>Lightweight clothing</li>
                                    <li>Accessories</li>
                                </ul>
                            </Typography>
                        </Grid>
                    </BlueGrid> */ }
                </Paper>
            </LabWrapper>
        </StageContainer>

        <ImageLibraryBrowser
            opened={libraryOpened}
            onSelect={onSelectImage}
            onClose={onLibraryClose}
        />
    </LeftContainer>
}