import { useCallback, useContext, useEffect } from "react"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { DoubleSide } from "three"

export default function ToteBag(props: ProductModelProps) {
    const option_style = props.options.get('style') ?? 'urban'

    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return option_style ? {
            'urban': new URL('@resources/3d-models/glb/tote-bag-urban.glb', import.meta.url),
            'basic': new URL('@resources/3d-models/glb/tote-bag-basic.glb', import.meta.url),
        }[option_style] : undefined
    }, [option_style])
    
    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Handle changes
    const option_strapColor = props.options.get('strap-color')
    useEffect(() => {
        switch (option_style) {
            case 'urban':
                let mat_strap_in = FindMaterial("Strap In", elements)
                let mat_strap_out = FindMaterial("Strap Out", elements)
                if(!(mat_strap_in && mat_strap_out)) return
                switch (option_strapColor) {
                    case 'black-vegan-leather':
                        mat_strap_in.color.setHex(0x626b6b)
                        mat_strap_out.color.setHex(0x212121)
                        break
                    case 'navy-vegan-leather':
                        mat_strap_in.color.setHex(0x325a79)
                        mat_strap_out.color.setHex(0x15232f)
                        break
                    case 'brown-vegan-leather':
                        mat_strap_in.color.setHex(0xa67553)
                        mat_strap_out.color.setHex(0x4f2b1b)
                        break
                }
                break
            case 'basic':
                let mat_border = FindMaterial("Canvas Border", elements)
                let mat_strap = FindMaterial("Strap", elements)
                if(!(mat_border && mat_strap)) return
                switch (option_strapColor) {
                    case 'black-cotton':
                        mat_border.color.setHex(0x2b2f2f)
                        mat_strap.color.setHex(0x2b2f2f)
                        mat_strap.roughness = 0.8
                        break;
                    case 'natural-cotton':
                        mat_border.color.setHex(0xffffff) // Border is white (unprinted) with natural strap
                        mat_strap.color.setHex(0xd9d4c8)
                        mat_strap.roughness = 0.8
                        break;
                    case 'black-poly':
                        mat_border.color.setHex(0x323232)
                        mat_strap.color.setHex(0x323232)
                        mat_strap.roughness = 0.6
                        break;
                    case 'grey-poly':
                        mat_border.color.setHex(0x8b8b8b)
                        mat_strap.color.setHex(0x8b8b8b)
                        mat_strap.roughness = 0.6
                        break;
                }
                break
        }
    }, [option_strapColor, option_style, elements])

    // Apply lab texture
    useEffect(() => {
        if (!elements) return

        let out_mat = FindMaterial('Canvas', elements)
        if(out_mat){
            out_mat.map = productTexture
            out_mat.map.flipY = false
            out_mat.map.needsUpdate = true
            out_mat.side = DoubleSide
        }

        let in_mat = FindMaterial('Canvas In', elements)
        if(in_mat){
            in_mat.transparent = true
            in_mat.opacity = 0.9
        }
    }, [elements, productTexture])
    
    return <ModelElements elements={elements}/>
}