import Immutable from "immutable"

export type GuideSnap = 'start'|'center'|'end';

interface IGuideStore {
	lineGuide: number
  offset: number
  orientation: 'V' | 'H'
  snap: GuideSnap,
}

const defaultGuideStore = Immutable.Record<IGuideStore>({
	lineGuide: 0,
  offset: 0,
  orientation: 'H',
  snap: 'start',
});

export class GuideStore extends defaultGuideStore implements IGuideStore {}