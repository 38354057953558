import Skeleton from '@mui/material/Skeleton'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material'
import { useCallback, useMemo, useRef, useState } from 'react'
import Dropzone, { type DropzoneRef } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import ItemList from '../../Common/ItemList/ItemList'
import Image from './Image'
import { UploadUserImage } from '../../UserData/_actions/UserImageActions'
import SearchBar from '../../Common/ItemList/SearchBar'
import LimitSelect from '../../Common/ItemList/LimitSelect'
import AccountHeader from '../AccountHeader'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { useSearchParams } from 'react-router-dom'
import { type UserImageStore } from '../../UserData/_stores/UserImageStore'
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

export default function ImageList() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('account-v2')
  const [searchParams] = useSearchParams()

  const [isDragActive, setIsDragActive] = useState(false)
  const dropzoneRef = useRef<DropzoneRef>(null)

  const loading = useAppSelector(state => state.get('UIData').get('loaders').get('images'))
  const userLimit = useAppSelector(state => state.get('userData').get('prefs').get('accountPageLimit'))
  const isUploading = useAppSelector(state => state.get('userData').get('images').get('isUploading'));

  const [search, setSearch] = useState(searchParams.get('search') ?? '')
  const [limit, setLimit] = useState(userLimit)

  const onDragEnter = useCallback(() => setIsDragActive(true), [])
  const onDragLeave = useCallback(() => setIsDragActive(false), [])

  const onUpload = useCallback((files: File[]) => {
    setIsDragActive(false)
    files.forEach(file => {
      const fd = new FormData()
      fd.append('file', file)
      dispatch(UploadUserImage(fd))
    })
  }, [])

  const openDropzone = useCallback(() => {
    if (!dropzoneRef.current) return
    dropzoneRef.current.open()
  }, [])

  const loadingGrid = useMemo(() => {
    const listSkeletons = []

    for (let i = 0; i < limit; i++) {
      listSkeletons.push(<Grid key={i} item xs={6} sm={4} lg={3} xl={2}><Skeleton variant="rounded" height={205} /></Grid>)
    }

    return listSkeletons
  }, [limit])

  const renderItem = useCallback((item: UserImageStore) => {
    return <Image item={item} />
  }, [])

  const renderList = useCallback((items: JSX.Element | null, links: JSX.Element | null, loading: boolean) => {
    return <>
      <Grid container spacing={2}>
        {loading
          ? loadingGrid
          : (items || isUploading ? <>
            {isUploading && <Grid item xs={6} sm={4} lg={3} xl={2}><Skeleton variant="rounded" width={"100%"} height={205} /></Grid>}
            {items}
          </> : <Grid item xs={12}>
            <span>{t('No Results')}</span>
          </Grid>)
        }
      </Grid>

      {links}
    </>
  }, [loadingGrid, isUploading, t])

  return <>
    <AccountHeader
      title={t('Image Library')}
      actions={<Button
        variant="contained"
        color="accent"
        onClick={openDropzone}
        startIcon={<CloudUploadIcon />}
      >{t('Upload')}</Button>}
    />
    <div className={'images-container' + (loading ? ' images-container--loading' : '')}>
      <Dropzone 
        ref={dropzoneRef}
        noClick
        multiple
        accept={{
          'image/jpeg': ['.jpg','.jpeg'],
          'image/png': ['.png'],
          'image/gif': ['.gif'],
        }} 
        onDrop={onUpload}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps({ disabled: false })} />

            <SearchBar
              placeholder={t('Search your images...')}
              value={search}
              onChange={setSearch}
            >
              <LimitSelect
                value={limit}
                onChange={setLimit}
              />
            </SearchBar>
            <ItemList
              dataType={'images'}
              item={renderItem}
              searchQuery={search}
              limit={limit}
              render={renderList}
            />
            {isDragActive ? <Stack spacing={1} sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <Typography variant="h2" component="h3">{t('Drop your files here')}</Typography>
              <SaveAltRoundedIcon
                color="secondary"
                sx={{
                  fontSize: '4rem',
                }}
              />
            </Stack> : null}
          </div>
        )}
      </Dropzone>
    </div>
  </>
}
