import { FormControl, InputBase, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { useCallback } from "react";
import ProductVariantStore from "../../AppData/_stores/ProductVariantStore";
import { EditActiveVariant } from "../../UIData/_actions/DesignLabStoreActions";

export const InputSelect = styled(InputBase)({
  height: '100%',
  color: '#fff',

  '.MuiInputBase-input:focus': {
    background: 'transparent',
  },

  '.MuiSelect-select': {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '0 34px 0 0',
  },

  '.MuiSelect-icon': {
    color: '#fff',
  }
})

const VariantSelectorFormControl = styled(FormControl)({
  lineHeight: '64px',
  height: '64px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  padding: '0px 10px 0px 15px',
  backgroundColor: 'transparent',
  transition: 'background-color 150ms',

  '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },

  '&.active': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
})

export default function VariantSelector() {
  const dispatch = useAppDispatch()

  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));

  const onChangeVariant = useCallback((e: SelectChangeEvent<string>) => {
    dispatch(EditActiveVariant(String(e.target.value)));
  }, [])

  const renderVariant = useCallback((variant: ProductVariantStore) => {
    return <MenuItem key={variant.get('id')} value={variant.get('slug')}>{variant.get('name')}</MenuItem>
  }, [])

  if (!productData || productData.get('variants').count() === 0) return null;

  return <>
    <div style={{
      width: '1px',
      height: '36px',
      background: '#fff',
      marginLeft: '10px',
      marginRight: '10px',
    }}></div>
    <VariantSelectorFormControl variant="standard">
      <Select
        variant="standard"
        input={<InputSelect />}
        value={activeVariant}
        onChange={onChangeVariant}>
        {productData.get('variants').valueSeq().map(renderVariant)}
      </Select>
    </VariantSelectorFormControl>
  </>
}