import { styled } from "@mui/material"
import { useAppSelector } from "../../Common/_hooks/useAppSelector";

const LayerImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
})

interface Props {
  layerId: string
}

export default function LayerThumbnail(props: Props) {
  const layerSrc = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId)?.get('src') ?? '');

  return <div style={{
    width: '40px',
    height: '40px',
    marginRight: '10px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    flexShrink: 0,
  }}>
    <LayerImage src={layerSrc} />
  </div>
}