import { useCallback } from "react";
import { ButtonTab } from "../SceneSelector/SceneTab";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { EditActiveSubproduct, SelectLayer } from "../../UIData/_actions/DesignLabStoreActions";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import ValidIndicator from "../_components/ValidIndicator";

interface Props {
  slug: string
  name: string
}

export default function SubproductTab(props: Props) {
  const dispatch = useAppDispatch()

  const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));
  const resolutionOk = useAppSelector(state => state.get('UIData').get('designLab').get('warnings').get('resolution').get(props.slug));
  const templatesCoveredOk = useAppSelector(state => state.get('UIData').get('designLab').get('warnings').get('templates_covered').get(props.slug));

  const onClick = useCallback(() => {
    dispatch(EditActiveSubproduct(props.slug))
    dispatch(SelectLayer(null));
  }, [props.slug])

  return <ButtonTab
    key={props.slug}
    onClick={onClick}
    className={activeSubproduct === props.slug ? 'active' : ''}
  >
    <ValidIndicator valid={Boolean(templatesCoveredOk && resolutionOk)} iconSize="small" />
    {props.name}
  </ButtonTab>
}