import { useContext, useEffect } from "react"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function Beanie(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/beanie.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    
    // Handle color change
    const option_color = props.options.get('lining-color')
    useEffect(() => {
        let in_mat = FindMaterial('beanie-in', elements)
        let in_color = {
            "black": [0.1, 0.1, 0.1],
            'chocolate': [102/255, 83/255, 66/255],
            'electric-blue': [8/255, 130/255, 160/255],
            'pink': [0.68, 0.21, 0.52],
            'cherry': [60/255, 5/255, 10/255],
            'gray':  [0.3, 0.3, 0.3],
        }[option_color || "black"] as [number, number, number]
        in_mat!.color.setRGB(...in_color)
    }, [elements, option_color])

    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let out_mat = FindMaterial('beanie-out', elements)
        if(out_mat){
            out_mat.map = productTexture
            out_mat.map.flipY = false
            out_mat.map.needsUpdate = true
        }
    }, [elements, productTexture])
    
    return <ModelElements elements={elements}/>
}