import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { EditLoader } from "../../UIData/_actions/UIDataActions";
import { FetchOneItem } from "../../UserData/_actions/UserDataActions";
import { ClearLab, EditActiveDesign, EditActiveOption, EditActiveProduct, EditActiveVariant, EditAutoDesignSubproduct, EditDesignName, EditLayer, EditMirrorMode, ResetActiveOptions, SelectLayer } from "../../UIData/_actions/DesignLabStoreActions";
import { GetProductLabData } from "../../UIData/_actions/DesignLabActions";
import { isBrowser } from 'browser-or-node';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LAB_FONTS } from "../../config";

export default function useLabInit() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate();
  const [tR] = useTranslation('routes');
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams<{product?: string}>();

  const selectedDesignId = useMemo(() => {
    return searchParams.has('design') ? searchParams.get('design') : undefined;
  }, [searchParams])

  const listProducts = useAppSelector(state => state.get('appData').get('products'));
  const activeDesignId = useAppSelector(state => state.get('UIData').get('designLab').get('activeDesignId'));
  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));
  const productDesign = useAppSelector(state => selectedDesignId ? state.get('userData').get('productDesigns').get('data').get(selectedDesignId) : undefined);

  //Load design from url
  useEffect(() => {
    if (!selectedDesignId || selectedDesignId === activeDesignId) return;

    dispatch(EditLoader("design-lab_design", true));
    dispatch(FetchOneItem(selectedDesignId, 'productDesigns', ['designData']));
  }, [selectedDesignId]);

  useEffect(() => {
    if (!productDesign || productDesign.get('id') === activeDesignId) return;

    const tmpProduct = listProducts.get(String(productDesign.get('id_product')));
    if (!tmpProduct) return;

    if (productDesign.get('id_product') != productData?.get('id')) {
      navigate('/' + tR('create') + '/' + tmpProduct.get('url_slug') + '?design=' + productDesign.get('id'), {
        replace: true
      });
      return;
    }


    dispatch(EditActiveDesign(productDesign.get('id')));

    const variantId = productDesign.get('id_product_variant');
    const variant = variantId ? tmpProduct.get('variants').get(String(variantId)) : undefined;
    if (variant) {
      dispatch(EditActiveVariant(variant.get('slug')));
    }
    dispatch(EditDesignName(productDesign.get('name')));

    //Load layers
    let selectedLayer: string | null = null;
    Object.keys(productDesign.get('designData').layers).forEach(layerId => {
      //Select first layer
      if (!selectedLayer) {
        selectedLayer = layerId;
      }
      dispatch(EditLayer(layerId, productDesign.get('designData').layers[layerId]));
    });
    if (selectedLayer) {
      dispatch(SelectLayer(selectedLayer));
    }

    //Load mirrorModes
    if (productDesign.get('designData').mirrorModes) {
      Object.keys(productDesign.get('designData').mirrorModes).forEach(key => {
        dispatch(EditMirrorMode(key, productDesign.get('designData').mirrorModes[key]));
      });
    }

    //Load autoDesign
    if (productDesign.get('designData').autoDesignSubproducts) {
      Object.keys(productDesign.get('designData').autoDesignSubproducts).forEach(key => {
        dispatch(EditAutoDesignSubproduct(key, productDesign.get('designData').autoDesignSubproducts[key]));
      });
    }

    //Load options
    if (productDesign.get('designData').options) {
      Object.keys(productDesign.get('designData').options).forEach(key => {
        dispatch(EditActiveOption(key, productDesign.get('designData').options[key]));
      });
    }

    dispatch(EditLoader("design-lab_design", false));

  }, [productData, productDesign]);

  //Change product based on url
  useEffect(() => {
    //Do nothing if it's the same as currently selected product
    if (params.product === productData?.get('url_slug')) return;

    if (!params.product) {
      dispatch(EditActiveProduct(null))
      return
    }

    const newProduct = listProducts.find(product => {
      return product.get('url_slug') === params.product;
    });

    if (!newProduct) return;

    dispatch(EditActiveProduct(newProduct.get('id')));
    dispatch(GetProductLabData(newProduct.get('slug')));

    //Always clear the lab when going in/out of fabric
    //TODO: Might implement this later for the whole lab, but with additional code to keep some data like layers
    if (productData?.get('slug') === 'fabric' || newProduct.get('slug') === 'fabric') {
      dispatch(ClearLab());
    }

    if (newProduct.get('slug') === 'fabric') {
      //FabricLab will handle its own default variant
      return;
    }

    //Don't reset anything if it's the same product or if we're loading a design
    if (newProduct.get('id') === productData?.get('id') || activeDesignId) {
      return;
    }

    //Reset variant
    let variant = 'default';

    if (newProduct.get('variants').count() > 0) {
      variant = newProduct.get('variants').first()?.get('slug') ?? variant;
    }

    dispatch(EditActiveVariant(variant));

    //Reset options
    dispatch(ResetActiveOptions());
  }, [params, listProducts]);

  useEffect(() => {
    if (!isBrowser) return;

    const WebFontLoader = require('webfontloader');

    WebFontLoader.load({
      google: {
        families: LAB_FONTS
      },
      active: () => {
        //On load
      },
      classes: false
    });
  }, []);
}