import { useAppSelector } from "../../../Common/_hooks/useAppSelector";
import useLabData from "../../_hooks/useLabData";
import FabricViewer from "./FabricViewer";

export default function LabFabricViewer() {
  const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
  const labData = useLabData();

  return labData ? <FabricViewer 
    labData={labData} 
    variant={activeVariant} 
    inLab={true} 
  /> : null
}