import { Button, Tooltip, styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ConfirmUpscaledImage, UpscaleImage, UpscaledImagePayload } from '../../../UIData/_actions/DesignLabActions';
import { EditLayer } from '../../../UIData/_actions/DesignLabStoreActions';
import DesignLabLayerStore from '../../../UIData/_stores/DesignLab/DesignLabLayerStore';
import UpscaleDialog, { UpscaleState } from './UpscaleDialog';
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch';
import PropertyPanel from '../PropertyPanel';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import useCheckDpi from '../../_hooks/useCheckDpi';

const WarningTab = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: '11px',
    left: '41px',
    background: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    transition: 'opacity 250ms',
    overflow: 'hidden',
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
}));

const WarningIcon = styled('div')({
    background: '#e33250',
    width: '32px',
    height: '32px',
    borderRadius: '32px',
    color: '#fff',
    fontSize: '24px',
    fontWeight: 600,
    textAlign: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 250ms',
    animationName: 'warning_pulse',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    '@keyframes warning_pulse': {
        from: {
            boxShadow: '0 0 0 0 rgba(227, 50, 80, .75)',
        },
        to: {
            boxShadow: '0 0 0 15px transparent',
        }
    },
});

type Props = {
    layerId: string
    opened?: boolean
    onOpen?: () => void
    onClose: () => void
}

export default function UpscaleTool(props: Props) {
    const [t] = useTranslation('design-lab');
    const dispatch = useAppDispatch();

    const layer = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId));
    const layerFileId = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId)?.get('fileid') ?? '');
    const checkDpi = useCheckDpi();

    const [upscaleState, setUpscaleState] = useState<UpscaleState>('none');
    const [upscaledImage, setUpscaledImage] = useState<UpscaledImagePayload | undefined>(undefined);
    const [nbAttempts, setNbAttempts] = useState(1);

    const { dpi, minDpi, dpiIndicator } = useMemo(() => {
        return layer ? checkDpi(layer) : {
            dpi: 0,
            minDpi: 0,
            dpiIndicator: 'bad'
        };
    }, [layer, checkDpi]);

    const onClickYes = useCallback(() => {
        props.onClose();
        setUpscaleState('generating');
        dispatch(UpscaleImage(
            layerFileId,
            1,
            (data) => {
                setUpscaleState('done');
                setUpscaledImage(data);
            },
            () => {
                setUpscaleState('none');
            }
        ));
        setNbAttempts(1);
    }, [props.onClose, layerFileId])

    const onConfirm = useCallback(() => {
        setUpscaleState('none');
        dispatch(ConfirmUpscaledImage(layerFileId, (image) => {
            dispatch(EditLayer(props.layerId, {
                fileid: image.fileid,
                src: '/api/user/images/image/' + image.fileid,
                originalWidth: image.width,
                originalHeight: image.height,
            }));
        }))
    }, [props.layerId, layerFileId])

    const onTryAgain = useCallback(() => {
        setUpscaleState('generating');
        dispatch(UpscaleImage(
            layerFileId,
            2,
            (data) => {
                setUpscaleState('done');
                setUpscaledImage(data);
            },
            () => {
                setUpscaleState('none');
            }
        ));

        setNbAttempts(2);
    }, [layerFileId])

    const onDialogClose = useCallback(() => {
        setUpscaleState('none')
    }, [])

    if (!layer) return null;

    return <>
        {dpiIndicator === 'bad' ? <Tooltip
            title={t('Upscale image')}
        >
            <WarningTab
                onClick={props.onOpen}
            >
                <WarningIcon>!</WarningIcon>
            </WarningTab>
        </Tooltip> : null}

        <PropertyPanel elevation={4} className={props.opened ? 'opened' : ''}>
            <h4 style={{
                margin: '0 0 10px 0',
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                marginBottom: '5px',
                whiteSpace: 'nowrap',
            }}>
                <div
                    style={{
                        background: '#f44336',
                        width: '18px',
                        height: '18px',
                        borderRadius: '18px',
                        color: '#fff',
                        lineHeight: '18px',
                        fontSize: '14px',
                        fontWeight: 600,
                        textAlign: 'center',
                        flexShrink: 0,
                        cursor: 'pointer',
                    }}
                >!</div>
                {t('Image too small')}
            </h4>
            <p style={{
                margin: '0 0 5px',
                fontSize: '14px',
            }}>{t('Do you want to upscale it using our AI tool?')}</p>
            <div style={{
                display: 'flex'
            }}>
                <Button
                    size="small"
                    style={{
                        color: '#fff',
                        padding: '10px 20px',
                        minWidth: '0',
                        borderRadius: '40px'
                    }}
                    onClick={props.onClose}
                >{t('No')}</Button>
                <Button
                    style={{
                        color: '#fff',
                        padding: '10px 20px',
                        minWidth: '0',
                        borderRadius: '20px'
                    }}
                    onClick={onClickYes}
                >{t('Yes')}</Button>
            </div>
        </PropertyPanel>

        <UpscaleDialog
            upscaleState={upscaleState}
            upscaledImage={upscaledImage}
            onConfirm={onConfirm}
            onTryAgain={nbAttempts < 2 ? onTryAgain : undefined}
            onClose={onDialogClose}
        />
    </>
}