import { Box, Button, Container, Stack, Link as MuiLink, NoSsr } from "@mui/material";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function CookieConsent() {
  const [t] = useTranslation('header')
  const [tR] = useTranslation('routes')
  const [cookies, setCookie] = useCookies(['cookieconsent_status'])

  const onAccept = useCallback(() => {
    setCookie('cookieconsent_status', 'dismiss', {
      path: '/',
      expires: DateTime.now().plus({years: 1}).toJSDate(),
    });
  }, [])

  if (cookies.cookieconsent_status === 'dismiss') {
    return null;
  }

  return <NoSsr>
    <Box
      component="div"
      sx={{
        position: 'fixed',
        zIndex: 2000,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'secondary.main',
        py: 2,
        color: '#fff',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          px: {
            xs: 2,
            md: 3,
            lg: 4
          }
        }}
      >
        <Stack direction={{xs:'column', sm: 'row'}} spacing={2} justifyContent="space-between" alignItems="center">
          <Stack>
            {t('This website uses cookies to ensure you get the best experience.')}
            <Stack direction="row" spacing={2}>
              <MuiLink component={Link} reloadDocument to={String(tR('/info/privacy-policy'))}>{t('Privacy policy')}</MuiLink>
              <MuiLink component={Link} reloadDocument to={String(tR('/info/terms-of-service'))}>{t('Terms of Service')}</MuiLink>
            </Stack>
          </Stack>
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={onAccept}
            sx={{
              flexShrink: 0,
            }}
          >{t('Got it!')}</Button>
        </Stack>
      </Container>
    </Box>
  </NoSsr>
}