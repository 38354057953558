import { Button, Tooltip, styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AddPatternLayer, AddTextLayer, SetLayerOrder } from '../../UIData/_actions/DesignLabStoreActions';
import WebsiteStore from '../../WebsiteStore';
import { LAB_TEMPLATE_HEIGHT, LAB_TEMPLATE_WIDTH } from '../../config';
import AddImageTool from '../Tool/_components/AddImageTool';
import useCalcFitTemplate from '../_hooks/useCalcFitTemplate';
import useCalcFitToSide from '../_hooks/useCalcFitToSide';
import useLabData from '../_hooks/useLabData';
import PatternStore from '../_stores/PatternStore';
import Layer from './Layer';
import PatternBrowser from '../_components/PatternBrowser';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import IconButton from '../../Common/Buttons/IconButton';
import LayerList from './LayerList';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

export const ListTitle = styled('h3')(({ theme }) => ({
  marginTop: 0,
  padding: '0 15px 0',
  fontSize: '16px',
  textTransform: 'uppercase',
  lineHeight: '40px',
  color: '#333',
  marginBottom: 0,

  ['@media (max-width: 1510px) and (min-height: ' + theme.height_breakpoints.values.lab_mobile + 'px), (max-height: 1070px) and (max-width: 953px)']: {
    lineHeight: '50px',
  }
}));

const LayerListContainer = styled('div')(({ theme }) => ({
  width: '275px',
  flexShrink: 0,
  transition: 'transform 300ms, opacity 300ms',
  position: 'relative',
  zIndex: 30,
  opacity: 1,
  paddingRight: '10px',

  '&.hidden': {
    transform: 'translateX(-100px)',
    opacity: 0,
  },

  ['@media (max-width: 1510px) and (min-height: ' + theme.height_breakpoints.values.lab_mobile + 'px), (max-height: 1070px) and (max-width: 953px)']: {
    height: '236px',
    marginBottom: '10px',
    width: 'auto',
    position: 'relative',
  },

  ['@media (max-width: ' + theme.breakpoints.values.lab_mobile + 'px), (max-height: ' + theme.height_breakpoints.values.lab_mobile + 'px)']: {
    transform: 'translateX(-100px)',
    opacity: 0,

    '&.active': {
      opacity: 1,
      transform: 'none',
    },
  }
}));

const ListWrap = styled('div')(({ theme }) => ({
  padding: '10px 10px 30px 15px',
  height: 'calc(100vh - 172px)',
  overflow: 'auto',

  ['@media (max-width: 1510px) and (min-height: ' + theme.height_breakpoints.values.lab_mobile + 'px), (max-height: 1070px) and (max-width: 953px)']: {
    display: 'flex',
    gap: '10px',
    alignItems: 'flex-start',
    position: 'absolute',
    overflow: 'auto',
    width: '100%',
    height: 'calc(100% - 50px)',
    top: '50px',
    padding: '10px 15px 0',
    left: '0',
  },

  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
    },
  }
}));

const AddContainer = styled('div')(({ theme }) => ({
  marginBottom: '10px',
  display: 'flex',
  gap: '10px',
  padding: '0 15px',

  ['@media (max-width: 1510px) and (min-height: ' + theme.height_breakpoints.values.lab_mobile + 'px), (max-height: 1070px) and (max-width: 953px)']: {
    position: 'absolute',
    top: '5px',
    right: '0',
  },
}));

type Props = {
  hidden?: boolean
  active?: boolean
}

export default function LayerSection(props: Props) {
  const dispatch = useAppDispatch();
  const [t] = useTranslation('design-lab');

  const activeScene = useAppSelector(state => state.get('UIData').get('designLab').get('activeScene'));
  const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));
  const autoDesign = useAppSelector(state => activeSubproduct !== null ? state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct) : undefined);
  const labData = useLabData();

  const calcFitTemplate = useCalcFitTemplate();
  const calcFitToSide = useCalcFitToSide();

  const [patternBrowserOpened, setPatternBrowserOpened] = useState(false);

  const onSelectPattern = useCallback((item: PatternStore) => {
    if(!activeScene) return;

    let originalWidth = item.get('repeat_width');
    let originalHeight = item.get('repeat_height');
    let width = originalWidth;
    let height = originalHeight;
    let scale = Math.min(LAB_TEMPLATE_WIDTH / width / item.get('horizontalRapportsPostcard'), LAB_TEMPLATE_HEIGHT / height / item.get('horizontalRapportsPostcard'));

    dispatch(AddPatternLayer({
      fileid: item.get('id'),
      src: item.get('repeat_src'),
      originalWidth: originalWidth,
      originalHeight: originalHeight,
      scene: activeScene,
      subproduct: activeSubproduct,
      width: width,
      height: height,
      x: LAB_TEMPLATE_WIDTH / 2,
      y: LAB_TEMPLATE_HEIGHT / 2,
      scaleX: scale,
      scaleY: scale,
      rotation: 0,
    }))
  }, [labData, activeScene, activeSubproduct, calcFitTemplate, calcFitToSide])

  const onPatternBrowserOpen = useCallback(() => {
    setPatternBrowserOpened(true)
  }, [])

  const onPatternBrowserClose = useCallback(() => {
    setPatternBrowserOpened(false);
  }, [])

  const onAddText = useCallback(() => {
    if(!activeScene) return;

    dispatch(AddTextLayer({
      text: t('Your text'),
      color: "#000000",
      font: "Norican",
      scene: activeScene,
      subproduct: activeSubproduct,
      x: LAB_TEMPLATE_WIDTH / 2,
      y: LAB_TEMPLATE_HEIGHT / 2,
      width: 0,
      height: 0,
      scaleX: 1,
      scaleY: 1,
      rotation: 0,
    }))
  }, [activeScene, activeSubproduct, t])

  return <LayerListContainer className={props.hidden ? 'hidden' : (props.active ? 'active' : '')}>
    <ListTitle>{t('Layers')}</ListTitle>

    <AddContainer>
      <AddImageTool />
      
      <Tooltip title={t('Add Pattern')}>
        <IconButton
          variant="contained"
          color="primary"
          size="large"
          disabled={!!autoDesign}
          onClick={onPatternBrowserOpen}
        ><img src={require('@resources/img/create/design-lab-v4/icons/add-pattern.svg?url')} /></IconButton>
      </Tooltip>

      <Tooltip title={t('Add Text')}>
        <IconButton
          variant="contained"
          color="primary"
          size="large"
          disabled={!!autoDesign}
          onClick={onAddText}
        ><img src={require('@resources/img/create/design-lab-v4/icons/add-text.svg?url')} /></IconButton>
      </Tooltip>
    </AddContainer>
    <ListWrap>
      <LayerList/>
    </ListWrap>

    <PatternBrowser
      opened={patternBrowserOpened}
      onSelect={onSelectPattern}
      onClose={onPatternBrowserClose}
    />
  </LayerListContainer>
}