import { Layer } from 'konva/lib/Layer';
import { useCallback, useMemo, useRef } from 'react';
import { Layer as LayerNode, Shape, Text } from 'react-konva';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import useLabData from '../_hooks/useLabData';
import { LAB_TEMPLATE_HEIGHT, LAB_TEMPLATE_WIDTH } from '../../config';
import KonvaImage from '../KonvaNode/KonvaImage';
import useStageDimensions from '../_hooks/useStageDimensions';
import useStageOffset from '../_hooks/useStageOffset';
import { Context } from 'konva/lib/Context';
import { useTranslation } from 'react-i18next';
import { LabOverlayMarker } from '../../AppData/LabData';

export default function OverlayLayer() {
  const [t] = useTranslation('design-lab');
  const activeScene = useAppSelector(state => state.get('UIData').get('designLab').get('activeScene'));
  const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
  const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));
  const zoomScale = useAppSelector(state => state.get('UIData').get('designLab').get('zoomScale'));
  const zoomOffsetX = useAppSelector(state => state.get('UIData').get('designLab').get('zoomOffsetX'));
  const zoomOffsetY = useAppSelector(state => state.get('UIData').get('designLab').get('zoomOffsetY'));
  const labData = useLabData();
  const { stageWidth, stageHeight } = useStageDimensions();
  const stageOffset = useStageOffset();

  const overlayRef = useRef<Layer>(null);

  const overlaySlug = useMemo(() => {
    if (!labData || !activeScene) return null;

    let slug = String(labData.id);

    if (activeSubproduct) {
      slug += '-' + activeSubproduct;
    }

    if (activeVariant !== 'default') {
      slug += '-' + activeVariant;
    }

    if (activeScene !== 'main') {
      slug += '-' + activeScene;
    }

    return slug;
  }, [labData, activeScene, activeVariant, activeSubproduct]);

  const sceneFunc = useCallback((ctx: Context) => {
    let rectBox = {
      x: zoomOffsetX,
      y: zoomOffsetY,
      width: stageWidth / zoomScale,
      height: stageHeight / zoomScale,
    };

    ctx.fillStyle = 'rgba(255,255,255,1)';
    ctx.strokeStyle = 'rgba(255,0,0,0)';
    ctx.fillRect(rectBox.x, rectBox.y, rectBox.width, rectBox.height);

    const pixelFix = 1 / zoomScale;
    ctx.clearRect(stageOffset.x + pixelFix, stageOffset.y + pixelFix, LAB_TEMPLATE_WIDTH - pixelFix * 2, LAB_TEMPLATE_HEIGHT - pixelFix * 2)

    if (overlayRef.current) {
      overlayRef.current.canvas._canvas.style.opacity = '0.6';
    }
  }, [stageWidth, stageHeight, stageOffset, zoomScale, zoomOffsetX, zoomOffsetY])

  const overlayMarkers = useMemo(() => {
    return labData?.variants[activeVariant]?.scenes[activeScene ?? '']?.overlay_markers ?? [];
  }, [labData, activeVariant, activeScene]);

  const listMarkerTexts = useMemo(() => {
    return {
      side: t('SIDE'),
      front: t('FRONT'),
      back: t('BACK'),
      inside: t('INSIDE'),
      outside: t('OUTSIDE'),
    } as Record<string, string>
  }, [t])

  const renderMarker = useCallback((marker: LabOverlayMarker) => {
    return <Text
      key={marker.slug+'_'+marker.x+'_'+marker.y}
      text={listMarkerTexts[marker.slug] ?? marker.slug}
      fontSize={24}
      fontFamily="Poppins, sans-serif"
      fill="#333333"
      fillEnabled={true}
      x={marker.x + stageOffset.x}
      y={marker.y + stageOffset.y}
      width={LAB_TEMPLATE_WIDTH}
      height={LAB_TEMPLATE_HEIGHT}
      offsetX={LAB_TEMPLATE_WIDTH/2}
      offsetY={LAB_TEMPLATE_HEIGHT/2}
      rotation={marker.rotate}
      align="center"
      verticalAlign="middle"
      listening={false}
    />
  }, [stageOffset, listMarkerTexts])

  return <LayerNode
    ref={overlayRef}
    listening={false}
  >
    <Shape
      width={stageWidth / zoomScale}
      height={stageHeight / zoomScale}
      listening={false}
      sceneFunc={sceneFunc}
    />
    {overlaySlug ? <KonvaImage
      src={require('@resources/img/create/overlays/svg/' + overlaySlug + '.svg?url')}
      width={LAB_TEMPLATE_WIDTH}
      height={LAB_TEMPLATE_HEIGHT}
      x={stageOffset.x}
      y={stageOffset.y}
      listening={false}
      disableCache
    /> : null}

    {overlayMarkers.map(renderMarker)}
  </LayerNode>
}