import { Canvas } from '@react-three/fiber';
import Immutable from 'immutable';
import { memo } from 'react';
import { PCFShadowMap } from 'three';
import ModelViewerComponents from './ModelViewerComponents';
import ModelViewerProduct from './ModelViewerProduct';
import { LabData } from '../AppData/LabData';

type Props = {
    viewerId: string
    productId: number
    labData: LabData
    options:Immutable.Map<string,string>
    variant: string
}

const ModelViewerCanvas = memo((props:Props) => {
    return <Canvas 
        shadows={{ type: PCFShadowMap }} 
        //Necessary or else it breaks the lab currently
        style={{pointerEvents: 'inherit'}}
    >
        <ModelViewerComponents 
            labData={props.labData}
            variant={props.variant}
        />
        <ModelViewerProduct 
            viewerId={props.viewerId}
            productId={props.productId}
            options={props.options}
            variant={props.variant}
        />
    </Canvas>
})

export default ModelViewerCanvas;