import { Button, Grid, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductsList from './ProductsList';
import SelectCategory from './SelectCategory';
import Filters from './Filters';

const MainContainer = styled(Grid)({
	backgroundColor: '#f0f0f0',
	'.MuiGrid-item': {
		'.productcard-wrapper': {
			'&:hover': {
				'img': {
					opacity: .8,
				},
				'.product-name, .product-description': {
					color: '#fff',
				},
				'.product-label': {
					color: '#000',
				},
				'.print-type-tag': {
					color: '#fff',
				},
				'.product-pricewrapper': {
					'.product-startingat': {
						fontSize: '14px',
					},
					'.product-price': {
						fontSize: '20px',
					}
				}
			}
		},
		'&:nth-of-type(4n + 2) .productcard-wrapper': {
			'&:hover, &:hover .imagecontainer': {
				backgroundColor: '#00aced',
			}
		},
		'&:nth-of-type(4n + 3) .productcard-wrapper': {
			'&:hover, &:hover .imagecontainer': {
				backgroundColor: '#ea008a',
			}
		},
		'&:nth-of-type(4n + 4) .productcard-wrapper': {
			'&:hover, &:hover .imagecontainer': {
				backgroundColor: '#e7d236',
			}
		},
		'&:nth-of-type(4n + 5) .productcard-wrapper': {
			'&:hover, &:hover .imagecontainer': {
				backgroundColor: '#ea008a',
			}
		},
	},
	'.filters-section': {
		overflow: 'visible',
	},
	'@media (min-width: 1200px)': {
		'.products-section, .filters-section': {
			transition: 'all 250ms',
		},
		'&.filters-hidden': {
			'.products-section': {
				maxWidth: '86.333334%',
				flexBasis: '86.333334%',
				borderRight: 0,
				transition: 'max-width 250ms',

				'@media (max-width: 1600px)': {
					maxWidth: '83.333334%',
					flexBasis: '83.333334%',
				},

				'.products-item': {
					transition: 'max-width 250ms',
					maxWidth: '24.666667%',
					flexBasis: '24.666667%',

					'@media (min-width: 1630px)': {
						maxWidth: '20%',
						flexBasis: '20%',
					},

					'@media (max-width: 1400px)': {
						maxWidth: '33.333333%',
						flexBasis: '33.333333%',
					},
				}
			},

			'.filters-section': {
				maxWidth: 0,
				flexBasis: 0,
				transition: 'max-width 250ms',
				overflow: 'visible',
				padding: 0,
			},

			'.filters-opacity': {
				width: 0,
				overflow: 'hidden',
				padding: 0,
			}
		}
	}
});
const FilterToggleButton = styled(Button)(({ theme }) => ({
	position: 'absolute',
	top: theme.spacing(4),
	right: '-1px',
	whiteSpace: 'nowrap',
	borderTopRightRadius: 0,
	borderBottomRightRadius: 0,

	[theme.breakpoints.down('lg')]: {
		display: 'none'
	}
}));

const ProductsDiv = styled(Grid)({
	position: 'relative',
	borderRight: '0.7px solid #afafaf',

	'@media (min-width:1600px)': {
		maxWidth: '68.666667%',
		flexBasis: '68.666667%',
	},
	'@media(max-width:1199px)': {
		order: 3,
		borderRight: 'none',
	},
	'@media(max-width:600px)': {
		order: 3,
		borderLeft: 'none',
	},
});
const TabContainer = styled(Grid)({
	backgroundColor: '#fff',

	'@media (min-width:1600px)': {
		maxWidth: '13.666667%',
		flexBasis: '13.666667%',
	},

	'@media(min-width:601px)': {
		position: 'sticky',
		top: 0,
		height: '100vh',
	},

	'@media(max-width:1199px)': {
		order: 2,
	},
	'@media(max-width:600px)': {
		order: 1,
	},
});
const FilterDiv = styled(Grid)({
	position: 'relative',
	'@media (min-width:1600px)': {
		maxWidth: '17.666667%',
		flexBasis: '17.666667%',
	},
	'@media(min-width:1200px)': {
		position: 'sticky',
		top: 0,
		height: '100vh',
		overflow: 'auto',
	},
	'@media(max-width:1199px)': {
		order: 1,
		borderBottom: '0.7px solid #afafaf',
		maxWidth: 'none',
		width: '100%',
		flexBasis: '100%',
		cursor: 'pointer',
	},
	'@media(max-width:600px)': {
		order: 2,
		borderTop: '0.7px solid #afafaf',
	},
	'&::-webkit-scrollbar': {
		width: '4px',
	},
	'&::-webkit-scrollbar-track': {
		background: '#f1f1f1',
	},
	'&::-webkit-scrollbar-thumb': {
		background: '#888',
		borderRadius: '5px',
	},
	'&::-webkit-scrollbar-thumb:hover': {
		background: '#555',
	},
});

export default function ProductsPageContent() {
	const [t] = useTranslation('products');
	const [filtersOpened, setFiltersOpened] = useState(false);

	const onFiltersToggle = useCallback(() => setFiltersOpened(value => !value), [])

	return <MainContainer className={filtersOpened ? "" : "filters-hidden"} container>
		<TabContainer item xs={12} sm={4} md={3} lg={2}>
			<SelectCategory />
		</TabContainer>
		<ProductsDiv className="products-section" item xs={12} sm={8} md={9} lg={7} sx={{ px: 2, py: 4 }}>
			<ProductsList />
			<FilterToggleButton
				variant="contained"
				color="primary"
				size="large"
				onClick={onFiltersToggle}
			>{filtersOpened ? t('Hide Filters') : t('Show Filters')}</FilterToggleButton>
		</ProductsDiv>
		<FilterDiv className="filters-section" item xs={12} sm={12} md={3}>
			<Filters />
		</FilterDiv>
	</MainContainer>
}