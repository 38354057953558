import { Button, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useExitLab from '../_hooks/useExitLab';
import DialogButton from '../../Common/Buttons/DialogButton';
import Dialog from '../../Common/Dialog/Dialog';
import DialogTitle from '../../Common/Dialog/DialogTitle';
import DialogContent from '../../Common/Dialog/DialogContent';
import DialogActions from '../../Common/Dialog/DialogActions';

const StyledButton = styled(Button)({
    alignSelf: 'stretch',
    borderRadius: 0,
    color: '#fff',
    gap: '10px',
    padding: '15px',
})

const ExitText = styled('span')({
    '@media (max-width: 1053px)': {
        display: 'none',
    },
})

export default function ExitLab() {
    const [t] = useTranslation('design-lab');
    const [confirmDialogOpened, setConfirmDialogOpened] = useState(false);
    const exitLab = useExitLab();

    const beforeUnloadCallback = useCallback((e: BeforeUnloadEvent) => {
        if (!confirmDialogOpened) {
            e.preventDefault();
        }
    }, [confirmDialogOpened]);

    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnloadCallback);

        return () => {
            window.removeEventListener('beforeunload', beforeUnloadCallback);
        }
    }, [beforeUnloadCallback]);

    const onClick = useCallback(() => {
        setConfirmDialogOpened(true);
    }, [])

    const onConfirmDialogClose = useCallback(() => {
        setConfirmDialogOpened(false)
    }, [])

    return <>
        <StyledButton
            onClick={onClick}
        >
            <ExitText>{t('Exit')}</ExitText>
            <ExitToAppIcon />
        </StyledButton>
        <Dialog
            open={confirmDialogOpened}
        >
            <DialogTitle>{t('Are you sure you want to exit the Design Lab? ')}</DialogTitle>
            <DialogContent>
                <p>{t('You will lose your progress if you haven\'t saved your design.')}</p>
            </DialogContent>
            <DialogActions>
                <DialogButton
                    color="secondary"
                    onClick={onConfirmDialogClose}
                >{t('Cancel')}</DialogButton>
                <DialogButton
                    color="primary"
                    onClick={exitLab}
                >{t('Confirm')}</DialogButton>
            </DialogActions>
        </Dialog>
    </>
}