import { Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import EmptyCart from './EmptyCart'
import { Outlet } from 'react-router-dom'
import { useAppDispatch } from '../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../Common/_hooks/useAppSelector'
import { FetchPaginationItems } from '../UserData/_actions/UserDataActions'
import { selectCartCount } from '../UserData/_selectors/OrderSelectors'
import PageLoader from '../Common/Loader/PageLoader'

export default function Cart() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const cartCount = useAppSelector(selectCartCount)
  const valid = useAppSelector(state => state.get('userData').get('orders').get('itemLists').get('cart')?.get('valid') ?? false)
  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  useEffect(() => {
    if (valid) return

    const fd = new FormData()
    fd.append('statuses', 'cart')
    fd.append('limit', '9999')
    fd.append('fields', 'items')

    dispatch(FetchPaginationItems('cart', fd, 'orders'))
  }, [valid])

  return <>
    <Helmet>
      <title>{t('Cart')}</title>
    </Helmet>
    { !cartCount && (!valid || loader)
      ? <PageLoader/>
      : <Container
          maxWidth="xl"
          sx={{
            alignSelf: !cartCount ? 'center' : 'flex-start',
            p: {
              xs: 2,
              md: 3,
              lg: 4
            }
          }}
        >{!cartCount ? <EmptyCart /> : <Outlet />}
      </Container>
    }
  </>
}
