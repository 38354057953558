
interface Props {
  slug: string
  width?: number,
  height?: number,
}

export default function ProductImage(props:Props) {
  return <picture>
    <source
      type="image/webp"
      srcSet={require('@resources/img/create/thumbnails/' + props.slug + '.webp') + ' 1x, ' + require('@resources/img/create/thumbnails/' + props.slug + '@2x.webp') + ' 2x'}
    />
    <source
      type="image/jpeg"
      srcSet={require('@resources/img/create/thumbnails/' + props.slug + '.jpg') + ' 1x, ' + require('@resources/img/create/thumbnails/' + props.slug + '@2x.jpg') + ' 2x'}
    />
    <img
      src={require('@resources/img/create/thumbnails/' + props.slug + '.svg?url')}
      width={props.width}
      height={props.height}
      style={{
        width: '100%',
        height: 'auto',
        display: 'block',
      }}
    />
  </picture>
}