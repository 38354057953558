import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import SectionDetails from '../../_Sections/SectionDetails';
import SectionGallery from '../../_Sections/SectionGallery';
import SectionDropship from '../../_Sections/SectionDropship';
import SectionPairings from '../../_Sections/SectionPairings';
import SectionBranding from '../../_Sections/SectionBranding';
import SectionSizes from '../../_Sections/SectionSizes';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import { Container, Grid, Stack, Typography, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import SectionPricing from '../../_Sections/SectionPricing';
import tKey from '../../../Common/Utils/tKey';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';
import ProductSEO from '../../_Sections/ProductSEO';
import { BlackCheckedIcon } from '../../../Common/_components/CheckBoxes';

const BackingOptions = styled('div')(({ theme }) => ({
  background: 'black',
  padding: '120px 0',
  color: 'white',
  'h3.textwhite, p': {
    color: 'white',
    textAlign: 'center',
  },
  'h3.textwhite': {
    marginBottom: '40px',
  }
}))

const PRODUCT_ID = 39;
const VIEW_PRODUCT_ID = 150;

export default function CanvasPrintPage() {
  const [t] = useTranslation('page/products/canvas-prints');
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(PRODUCT_ID)));
  const locale = useAppSelector(state => state.get('userData').get('prefs').get('lang'))

  if (!productData) return null

  return <div className="centered-page product-page-v4">
    <ProductSEO
      viewProductId={VIEW_PRODUCT_ID}
      title={t('Custom all-over print Canvas Prints | Products')}
      description={t('Design and create your own custom printed Canvas Prints featuring your artwork. ✔ Print-on-demand drop shipping ✔ No minimums ✔ Made in Canada')}
    />
    <SectionDetails
      viewProductId={VIEW_PRODUCT_ID}
      color="#DCB7C8"
      bgStyle={{
        backgroundPosition: 'bottom right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '48%',
      }}
      tag={t('The all-over print')}
      title={t('Canvas Print')}
      text={t('Transform your space with custom printed stretched canvas wall art. Utilizing state-of-the-art printing technology and UL certified GREENGUARD GOLD ink, canvas prints are water-resistant, fade-resistant, and anti-yellowing for lasting beauty. Create keepsakes or print masterpieces with stretched canvas prints.')}
      createUrl={productData.get('url_slug')}
      img={require('@resources/img/pages/products/canvas-prints/banner-1-' + locale + '.png')}
      alt={t('Picture of Canvas Print')}
      details={[
        {
          img: require('@resources/img/pages/products/canvas-prints/icon-1.svg?url'),
          alt: t('Cotton icon'),
          text: t('Printed on durable satin finish poly-cotton blend canvas, weighting 344gsm '),
        },
        {
          img: require('@resources/img/pages/products/canvas-prints/icon-2.svg?url'),
          alt: t('Print icon'),
          text: t('High-definition printing ensures sharp clarity and vibrant colors'),
        },
        {
          img: require('@resources/img/pages/products/canvas-prints/icon-3.svg?url'),
          alt: t('Canvas icon'),
          text: t('Mounted on warp-free FSC Certified stretcher bars from sustainable forests'),
        },
        {
          img: require('@resources/img/pages/products/canvas-prints/icon-4.svg?url'),
          alt: t('Hardware icon'),
          text: t('Ready to hang - pre-installed sawtooth hanging hardware'),
        },
        {
          img: require('@resources/img/pages/products/canvas-prints/icon-5.svg?url'),
          alt: t('Canada icon'),
          text: t('Digitally printed in Montreal, Canada and shipped worldwide'),
        },
      ]}
    />
    <SectionGallery images={[
      require('@resources/img/pages/products/canvas-prints/gallery_0.jpg'),
      require('@resources/img/pages/products/canvas-prints/gallery_1.jpg'),
      require('@resources/img/pages/products/canvas-prints/gallery_2.jpg'),
      require('@resources/img/pages/products/canvas-prints/gallery_3.jpg'),
      require('@resources/img/pages/products/canvas-prints/gallery_4.jpg'),
    ]} />

    <SectionDropship
      title={<Trans
        t={t}
        i18nKey={tKey('<yellow>Drop ship</yellow> your Canvas Prints <br/> <pink>on-demand</pink>')}
        components={{
          yellow: <span className="ty" />,
          pink: <span className="tp" />,
        }}
      />}
      text={t('Your brand and following will love Canvas Print! At prices designed for reselling, Canvas Print made on demand meet the needs of sellers who prefer not to stock inventory. Sell them in your eCommerce store and decide your profit margin')}
    />

    <SectionPricing
      productId={PRODUCT_ID}
      variants={['12x12','16x16','12x16','12x18','11x14','16x20']}
      options={['no-frame','black-frame']}
      listOptions
    />

    <SectionSizes
      productId={PRODUCT_ID}
      title={t('Canvas Print Sizing')}
      text={t('Stretched canvas sizing is in general very accurate. Please allow some variations within 1-5mm from the advertised size. ')}
    >
      <img
        src={require('@resources/img/pages/products/canvas-prints/measurement.svg?url')}
        style={{
          marginRight: 0,
        }}
      />
    </SectionSizes>

    <BackingOptions>
      <Container maxWidth="md">
        <Stack spacing={2} alignItems="center" textAlign="center">
          <Stack spacing={-4}>
            <Typography
              variant="h1-accent"
              component="h3"
            >{t('Choose from')}</Typography>
            <Typography
              variant="h1"
              component="h3"
              className="textwhite"
            >{t('two frame color options')}</Typography>
            <Typography
              variant="body1"
              component="p"
              className="textwhite"
            >{t('High quality hardwood floater frame molding made from poplar wood')}</Typography>
          </Stack>
        </Stack>
        <Grid container spacing={4} justifyContent="center">
          <Grid item sm={4}>
            <img
              src={require('@resources/img/pages/products/canvas-prints/option1.png')}
              style={{
                width: '100%',
                marginTop: '60px',
                marginBottom: '15px',
              }}
            />
            <Typography
              variant="h4"
              component="h3"
              className="textwhite"
            >{t('Matte Black')}</Typography>
          </Grid>
          <Grid item sm={4}>
            <img
              src={require('@resources/img/pages/products/canvas-prints/option2.png')}
              style={{
                width: '100%',
                marginTop: '60px',
                marginBottom: '15px',
              }}
            />
            <Typography
              variant="h4"
              component="h3"
              className="textwhite"
            >{t('White')}</Typography>
          </Grid>
        </Grid>
      </Container>
    </BackingOptions>

    <SectionBranding
      title={t('Brand your Canvas Print')}
      text={t('Branding enhances your products. Add custom labels, artist cards or stickers to your packages to create a cohesive message and build your brand.')}
    />

    <SectionPairings
      text={t('Pair your Canvas Print with these great products to create your own unique styles.')}
      productSlugs={['blanket', 'velveteen-pillow-cases', 'tapestries']}
    />
  </div>
}
