import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Stack, styled, Typography } from '@mui/material';
import { useAppSelector } from '../Common/_hooks/useAppSelector';
import { useMemo } from 'react';

export default function ProductListHeader() {
  const [t] = useTranslation('products')
  const params = useParams<{ category1?: string, category2?: string }>()
  const viewCategories = useAppSelector(state => state.get('appData').get('viewCategories'));

  const selectedCategory = useMemo(() => {
    if (!params.category1) return undefined;

    if (params.category2) {
      return viewCategories.find(category => category.get('url_slug') === params.category2);
    } else {
      return viewCategories.find(category => category.get('url_slug') === params.category1);
    }
  }, [viewCategories, params])

  const parentCategoryTitle = useMemo(() => {
    return params.category1 && params.category2 ? viewCategories.find(category => category.get('url_slug') === params.category1)?.get('name') ?? '' : '';
  }, [viewCategories, params])

  return <Box
    component="div"
    sx={{
      textAlign: {
        xs: 'center',
        sm: 'left',
      },
      mb: 2,
    }}
  >
    {parentCategoryTitle ? <Typography variant="h2-accent">
      {parentCategoryTitle}
    </Typography> : null}
    <Stack spacing={2}>
      <Typography variant="h1">{selectedCategory ? selectedCategory.get('name') : t('All Products')}</Typography>
      <Typography variant="body1" sx={{ maxWidth: '650px' }}>{selectedCategory ? selectedCategory.get('description') : null}</Typography>
    </Stack>
  </Box>
}