//require('core-js');

require('script-loader!jquery');
require('script-loader!select2'); //Only used in Dashboard for dashboard-snooze-rating atm. Remove once we refactor

require('script-loader!../../node_modules/materialize-css/dist/js/materialize.js');
require('script-loader!../../vendor/artofwhere/aow-lib/resources/frontend-lib/jquery.html5uploader.min.js'); //Only used by PhotoLab

require('../../vendor/artofwhere/aow-lib/resources/js/AOW.js');

require('./ProductDesignView.js');
require('script-loader!./routes.js');
require('script-loader!./account/SocialApp.js');


require('script-loader!./create/Product.js');

var req = require.context("script-loader!./create/ProductCategories", true, /^(.*\.(js$))[^.]*$/im);
req.keys().forEach(function(key){
    req(key);
});

//Parent products first, prefixed by _
var req = require.context("script-loader!./create/Products", true, /^(.*\/_[a-zA-Z0-9]+\.(js$))[^.]*$/im);
req.keys().forEach(function(key){
    req(key);
});

var req = require.context("script-loader!./create/Products", true, /^(.*\/[a-zA-Z0-9]+\.(js$))[^.]*$/im);
req.keys().forEach(function(key){
    req(key);
});

require('./info/Dropship.js');
require('./info/Help.js');
require('./info/ProductionAndShipping.js');
require('./info/Fabrics.js');
require('./ShippingTables.js');
require('./dashboard/AccountSettings.js');