import * as Immutable from 'immutable';

export type LayerPatternMode = 
    'none'
    |'repeat'
    |'repeat-x'
    |'repeat-y'
    |'repeat-mirror'
    |'repeat-mirror-x'
    |'repeat-mirror-y'
    |'repeat-x-mirror-x'
    |'repeat-y-mirror-y'
    |'half-drop'
    |'half-brick';

export type LayerAlignment = 'center'|'top-left';

export type LayerType = 'img'|'text'|'pattern';

interface IDesignLabLayerStore {
    id: string,
    type: LayerType,
    text: string,
    font: string,
    color: string,
    fileid: string,
    src: string,
    originalWidth: number,
    originalHeight: number,
    scene: string,
    subproduct: string|null,
    width: number,
    height: number,
    x: number,
    y: number,
    scaleX: number,
    scaleY: number,
    rotation: number,
    patternMode: LayerPatternMode,
    alignment: LayerAlignment | null,
}

const defaultDesignLabLayerStore = Immutable.Record<IDesignLabLayerStore>({
    id: '',
    type: 'img',
    text: '',
    font: '',
    color: '',
    fileid: '',
    src: '',
    originalWidth: 0,
    originalHeight: 0,
    scene: '',
    subproduct: null,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    rotation: 0,
    patternMode: 'none',
    alignment: null,
});

export default class DesignLabLayerStore extends defaultDesignLabLayerStore implements IDesignLabLayerStore {}