import { IconButton, InputAdornment, InputBase, MenuItem, styled } from '@mui/material';
import { useCallback } from 'react';
import * as React from 'react';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const StyledBar = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.06)',
  borderRadius: '30px',
  display: 'flex',
  height: '48px',
  marginBottom: theme.spacing(3),
  padding: theme.spacing(0, 3, 0, 2),
  alignItems: 'center',
  gap: '10px',
}));

type Props = React.PropsWithChildren<{
  placeholder?: string
  value?: string
  onChange?: (value: string) => void
}>

export default function SearchBar(props: Props) {
  const [t] = useTranslation('common');

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange && props.onChange(String(e.target.value));
  }, [props.onChange])

  const onClear = useCallback(() => {
    props.onChange && props.onChange('');
  }, [props.onChange]);

  return <StyledBar>
    <SearchIcon style={{ fontSize: '28px' }} />
    <InputBase
      placeholder={props.placeholder ? props.placeholder : t('Search...')}
      value={props.value}
      onChange={onChange}
      style={{
        padding: '10px 0',
        flexGrow: 1,
        paddingRight: props.children ? '20px' : '0',
      }}
      endAdornment={props.value ? <InputAdornment position="end">
        <IconButton
          onClick={onClear}
          edge="end"
        ><ClearIcon /></IconButton>
      </InputAdornment> : null}
    />

    {props.children}
  </StyledBar>
}