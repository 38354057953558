import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import PatternBrowser from "../_components/PatternBrowser";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { EditLayer } from "../../UIData/_actions/DesignLabStoreActions";
import PatternStore from "../_stores/PatternStore";
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

interface Props {
  layerId: string
}

export default function LayerPattern(props:Props) {
  const [t] = useTranslation('design-lab');
  const dispatch = useAppDispatch()
  const [patternBrowserOpened, setPatternBrowserOpened] = useState(false);

  const onOpen = useCallback(() => {
    setPatternBrowserOpened(true);
  }, [])

  const onClose = useCallback(() => {
    setPatternBrowserOpened(false);
  }, [])

  const onSelect = useCallback((item:PatternStore) => {
    dispatch(EditLayer(props.layerId, {
      fileid: item.get('id'),
      src: item.get('repeat_src'),
      originalWidth: item.get('repeat_width'),
      originalHeight: item.get('repeat_height'),
      width: item.get('repeat_width'),
      height: item.get('repeat_height'),
    }))
  }, [props.layerId])

  return <>
    <Button
      onClick={onOpen}
      startIcon={<PhotoLibraryIcon/>}
      size="small"
      variant="text"
      color="primary"
      style={{
        marginTop: '15px',
      }}
    >{t('Pattern')}</Button>

    <PatternBrowser
      opened={patternBrowserOpened}
      onSelect={onSelect}
      onClose={onClose}
    />
  </>
}