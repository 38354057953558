const { DateTime } = require('luxon');

require('../Dashboard');

AOW.Dashboard.Reports = function(slug) {
	AOW.Dashboard.call(this);
	
	this.slug = slug;
	
	this.$reportsView 	     = this.$el.find('#reports-view');
	this.$datesInput  	  	 = this.$reportsView.find('#report-dates');
	this.$reportDownloadPdf  = this.$reportsView.find('.download-pdf-btn');
	
	this.init();
};

AOW.Dashboard.Reports.prototype = Object.assign({}, AOW.Dashboard.Reports.prototype, {
	init: function() {
		var me = this;

		/*this.$datesInput.daterangepicker({
			minDate: DateTime.now().minus({years: 7}).toJSDate(),
		  	callback: function (startDate, endDate, period) {
		    	$(this).val(startDate.format('L') + ' – ' + endDate.format('L'));
		    	me.onDatesInputChange();
		  }
		});*/
		this.$reportDownloadPdf.on('click', function() {
			me.downloadPdf();
		});
	},

	onDatesInputChange: function(e) {
		var me = this;
		var reportDates = me.$datesInput.val().split(' – ');

		//Send request to get sales
		AOW.request({
			url: 'd/'+me.slug+'/get-sales',
			method: 'GET',
			data: {
				startDate: reportDates[0],
				endDate: reportDates[1]
			},
			success: function(response) {
				var GST = response.data.GST;
				var QST = response.data.QST;
				var HST = response.data.HST;

				$('.start-date .date').html(response.data.startDate);
				$('.end-date .date').html(response.data.endDate);
				$('#subtotal .price').html('$ '+response.data.subtotal);
				$('#discount .price').html('($ '+response.data.discount+')');
				$('#shipping .price').html('$ '+response.data.shipping);
				$('#taxes .price').html('$ '+response.data.taxes);
				$('#total .price').html('$ '+response.data.total);

				if(GST > 0){
					$('#gst').removeClass('hide');
					$('#gst .price').html('$ '+GST);
				} else {
					$('#gst').addClass('hide');
				}

				if(QST > 0){
					$('#qst').removeClass('hide');
					$('#qst .price').html('$ '+QST);
				} else {
					$('#qst').addClass('hide');
				}

				if(HST > 0){
					$('#hst').removeClass('hide');
					$('#hst .price').html('$ '+HST);
				} else {
					$('#hst').addClass('hide');
				}
			},
			error: function(response) {
				console.log('ERROR');
			}
		});
	},

	downloadPdf: function(e){
		
		var me = this;
		var reportDates = me.$datesInput.val().split(' – ');
		var startDate = reportDates[0];	var endDate = reportDates[1];

		window.open('d/'+me.slug+'/purchase-report-pdf?startDate='+startDate+'&endDate='+endDate);
	}
});