import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Drawer from '@mui/material/Drawer';
import { useCallback, useState } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import ViewCategoryStore from '../AppData/_stores/ViewCategoryStore';
import Hidden from '../Common/_components/Hidden';
import { Divider, Stack, styled, Typography } from '@mui/material';
import { useAppSelector } from '../Common/_hooks/useAppSelector';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const StyledLink = styled(Link)({
	color: '#000'
})

const StyledAccordion = styled(Accordion)({
	backgroundColor: 'unset',
	boxShadow: 'unset',
	borderBottom: '0.7px solid #afafaf',
	borderRadius: 0,

	':last-child': {
		border: 0,
	},

	'.MuiAccordionSummary-content': {
		margin: 0,
	},
	'.MuiAccordionSummary-root.Mui-expanded': {
		minHeight: '48px',
	},

	'&.MuiAccordion-root:before': {
		display: 'none',
	},

	'&.MuiAccordion-root.Mui-expanded': {
		margin: 0,
	},
	'&.MuiAccordion-root.Mui-expanded:before': {
		display: 'none',
	},

	'.MuiAccordionSummary-root': {
		padding: 0,
	},
});

const EmptyAccordionElement = styled('div')({
	minHeight: '48px',
	lineHeight: '48px',
});

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
	display: 'block',
	padding: theme.spacing(0, 0, 2),

	'a:not(:last-child)': {
		'li': {
			marginBottom: '1rem',
		}
	}
}));

const StyledDrawer = styled(Drawer)({
	'.MuiDrawer-paperAnchorBottom': {
		borderTopLeftRadius: '10px',
		borderTopRightRadius: '10px',
		margin: '0 auto',
		width: '95%',
		maxHeight: '85%',
	},

	'.drawer-content': {
		padding: '22px 20px',
	}
});

const ParentCategoryItem = styled('span')(({ theme }) => ({
	margin: 0,
	fontWeight: 500,
	color: theme.palette.secondary.main,

	'&.selected': {
		color: theme.palette.accent.main,
		fontWeight: 600,
	}
}));

const SubcategoryItem = styled('li')(({ theme }) => ({
	listStyle: 'none',
	fontWeight: 500,

	'&.selected': {
		color: theme.palette.accent.main,
	}
}));


export default function SelectCategory() {
	const [displayDrawer, setDisplayDrawer] = useState(false);
	const [t] = useTranslation('products')
	const [tR] = useTranslation('routes');
	const params = useParams()
	const viewCategories = useAppSelector(state => state.get('appData').get('viewCategories'));

	const topLevelCategories = useMemo(() => {
		return viewCategories.filter(category => category.get('id_parent') === null).sort((a, b) => a.get('theorder') - b.get('theorder'))
	}, [viewCategories])

	const renderTopLevelCategory = useCallback((topLevelCategory: ViewCategoryStore) => {
		const childCategories = viewCategories.filter(category => category.get('id_parent') === topLevelCategory.get('id')).sort((a, b) => a.get('theorder') - b.get('theorder'));
		const selected = params.category2 ? childCategories.find(category => category.get('url_slug') == params.category2) !== undefined : params.category1 == topLevelCategory.get('url_slug')

		return <StyledAccordion key={topLevelCategory.get('id')} expanded={selected}>
			<StyledLink to={tR('/products') + '/' + topLevelCategory.get('url_slug')}>
				<AccordionSummary
					expandIcon={childCategories.count() > 0 ? <ExpandMoreIcon /> : null}
				>
					<ParentCategoryItem
						className={selected ? "selected" : ""}
						onClick={childCategories.count() === 0 ? () => setDisplayDrawer(false) : undefined}
					>
						{topLevelCategory.get('name')}
					</ParentCategoryItem>
				</AccordionSummary>
			</StyledLink>

			{childCategories.count() > 0 ? <StyledAccordionDetails>
				{childCategories.valueSeq().map(secondLayer => {
					return <StyledLink
						key={secondLayer.get('id')}
						to={tR('/products') + '/' + topLevelCategory.get('url_slug') + '/' + secondLayer.get('url_slug')}
					>
						<SubcategoryItem
							className={params.category2 === secondLayer.get('url_slug') ? "selected" : ""}
							onClick={() => setDisplayDrawer(false)}>{secondLayer.get('name')}</SubcategoryItem>
					</StyledLink>
				})}
			</StyledAccordionDetails> : null}
		</StyledAccordion>
	}, [viewCategories, params])

	return <>
		<Hidden only={['xs']}>
			<Stack spacing={3} sx={{ px: 2, pt: 4 }}>
				<Typography variant="h3">{t('Select Product')}</Typography>
				<Stack>
					<StyledLink to={tR('/products')}>
						<EmptyAccordionElement>
							<ParentCategoryItem className={params.category1 === undefined ? 'selected' : ''} sx={{ pb: 2 }}>
								{t('All Products')}
							</ParentCategoryItem>
						</EmptyAccordionElement>
					</StyledLink>
					<Divider color="#afafaf" />
					{topLevelCategories.valueSeq().map(renderTopLevelCategory)}
				</Stack>
			</Stack>
		</Hidden>

		<Hidden only={['sm', 'md', 'lg', 'xl']}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" onClick={() => setDisplayDrawer(true)} sx={{ p: 2 }}>
				<Typography variant="h3">{t('Select Product')}</Typography>
				<ArrowForwardIcon />
			</Stack>
			<StyledDrawer anchor="bottom" open={displayDrawer} onClose={() => setDisplayDrawer(false)}>
				<Stack spacing={3} sx={{ p: 2 }}>
					<Typography variant="h3">{t('Select Product')}</Typography>

					<Stack>
						<StyledLink to={tR('/products')} onClick={() => setDisplayDrawer(false)}>
							<EmptyAccordionElement>
								<ParentCategoryItem className={params.category1 === undefined ? 'selected' : ''}>
									{t('All Products')}
								</ParentCategoryItem>
							</EmptyAccordionElement>
						</StyledLink>
						{topLevelCategories.valueSeq().map(renderTopLevelCategory)}
					</Stack>
				</Stack>
			</StyledDrawer>
		</Hidden>
	</>
}