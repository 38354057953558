import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import useLabData from '../_hooks/useLabData';
import LabOptionTool, { LabOptionValue } from './LabOptionTool';
import { EditActiveOption } from '../../UIData/_actions/DesignLabStoreActions';
import Immutable from 'immutable';
import { Color } from 'three';
import { LabOptionData } from '../../AppData/LabData';

type Props = {
  option: string,
  optionData: LabOptionData
}

export default function OptionTool(props: Props) {
  const dispatch = useAppDispatch();
  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const availableOptionTypes = useAppSelector(state =>  state.get('appData').get('products').get(String(activeProductId))?.get('availableOptionTypes'))
  const activeOptions = useAppSelector(state => state.get('UIData').get('designLab').get('activeOptions'));
  const labData = useLabData()
  const [searchParams, setSearchParams] = useSearchParams();

  const optionType = useMemo(() => {
    return availableOptionTypes?.find(optionType => optionType.get('slug') === props.option);
  }, [props.option, availableOptionTypes])

  const value = useMemo(() => {
    return activeOptions.get(props.option);
  }, [props.option, activeOptions])

  const listValues = useMemo(() => {
    let tmpListValues = Immutable.OrderedMap<string, LabOptionValue>()

    if (!optionType || !labData) return tmpListValues;

    optionType.get('options').sort((a, b) => a.get('theorder') - b.get('theorder')).valueSeq().forEach(option => {
      // Dependency check
      if (option.get('listDependencies').count() > 0) {
        let isValid = false
        option.get('listDependencies').forEach(dependency => {
          //Find option
          let found = false;
          availableOptionTypes?.forEach(tmpOptionType => {
            tmpOptionType.get('options').forEach(tmpOption => {
              if(tmpOption.get('id') !== dependency) {
                return;
              }

              found = true;

              //Match! Auto-mark valid if it's not a lab option
              if(!Object.keys(labData.options).includes(tmpOptionType.get('slug'))) {
                isValid = true;
                return false;
              }

              //Check that it matches the currently selected lab option
              if(activeOptions.get(tmpOptionType.get('slug')) === tmpOption.get('slug')) {
                isValid = true;
                return false;
              }
            })

            if(found) return false;
          });
        })

        if (!isValid) return;
      }

      let color:string|undefined = undefined;
      if(props.optionData.layout === 'color') {
        color = props.optionData.colors[option.get('slug')] ?? '#000000';
      }

      tmpListValues = tmpListValues.set(option.get('slug'), new LabOptionValue({
        name: option.get('name'),
        slug: option.get('slug'),
        color: color,
      }))
    })

    return tmpListValues
  }, [optionType, availableOptionTypes, activeOptions, props.optionData, labData]);

  useEffect(() => {
    let valueIsValid = false;
    listValues.forEach(tmpValue => {
      if (tmpValue.get('slug') === value) {
        valueIsValid = true;
        return;
      }
    })

    //This is used to select a default value, so stop here if we have one
    if ((value && valueIsValid) || !optionType) return;

    let option = listValues.first();

    //Try to get from searchparams
    const paramOption = searchParams.get('option_' + optionType.get('slug'))

    if (!!paramOption) {
      listValues.forEach(tmpValue => {
        if(tmpValue.get('slug') === paramOption) {
          option = tmpValue;
        }
      })
    }

    if (!option) return

    dispatch(EditActiveOption(optionType.get('slug'), option.get('slug')));
  }, [optionType, value, searchParams, listValues]);

  if (!optionType) return null

  return <LabOptionTool
    type="option"
    layout={props.optionData.layout ?? 'color'}
    name={optionType.get('name')}
    option={props.option}
    listValues={listValues}
  />
}