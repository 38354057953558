import { Button, Chip, Collapse, Divider, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Switch, TextField, Typography, styled } from '@mui/material'
import { CloseRounded as CloseRoundedIcon } from '@mui/icons-material'
import { CartStack } from '../../Cart/CartContent/CartStack'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import type Immutable from 'immutable'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import SummaryLineLoader from './SummaryLineLoader'
import { useTranslation } from 'react-i18next'
import DineroFactory, { type Dinero } from 'dinero.js'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { ApplyPromoCode } from '../../UserData/_actions/UserCartActions'
import SelectRewardDialog from './SelectRewardDialog'
import UserRewardCoupon from '../../Account/GoalsAndRewards/UserRewardCoupon'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import useIsSale from '../../Common/_hooks/useIsSale'

const PriceLine = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#999',

  '&.total': {
    fontSize: '18px',
    fontWeight: '500',
    color: '#000'
  },

  '&.discount': {
    color: theme.palette.accent.main,
    fontWeight: '500'
  }
}))

const PriceLabel = styled('span')({

})

const PriceEntry = styled(SummaryLineLoader)({

})

export interface Props {
  carts: Immutable.OrderedMap<string, UserCartStore>
  disabled?: boolean
}

export default function CheckoutSummaryContent(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))
  const userRewards = useAppSelector(state => state.get('userData').get('rewards'))
  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))

  const [rewardDialogOpened, setRewardDialogOpened] = useState(false)
  const [promocodeValue, setPromocodeValue] = useState('')

  const [promocode, reward] = useMemo(() => {
    let tmpPromocode = ''
    let tmpRewardId: number | null = null

    const arrayCarts = props.carts.toIndexedSeq()
    for (const cart of arrayCarts) {
      const cartPromocode = cart.get('promocode')
      if (cartPromocode) {
        tmpPromocode = cartPromocode

        const cartReward = cart.get('id_reward')
        if (cartReward !== null) {
          tmpRewardId = cartReward
        }
      }
    }

    return [
      tmpPromocode,
      tmpRewardId !== null ? userRewards.get(String(tmpRewardId)) : undefined
    ]
  }, [props.carts, userRewards])

  useEffect(() => {
    if (promocode) {
      setDiscountVisible(true)

      if (reward) {
        setDiscountType('reward')
      } else {
        setDiscountType('code')
      }
    }

    setPromocodeValue('')
  }, [promocode, reward])

  const [discountVisible, setDiscountVisible] = useState(!!promocode)
  const [discountType, setDiscountType] = useState<'code' | 'reward'>(reward ? 'reward' : 'code')

  const grandTotal = useMemo(() => {
    let grandTotal: Dinero | undefined
    for (const [, cart] of props.carts) {
      const price = {
        amount: cart.get('total').get('amount'),
        currency: cart.get('total').get('currency')
      }
      grandTotal = grandTotal ? grandTotal.add(DineroFactory(price)) : DineroFactory(price)
    }
    return grandTotal
  }, [props.carts])

  const firstCart = useMemo(() => {
    return props.carts.first()
  }, [props.carts])

  const cartIds = useMemo(() => {
    return props.carts.valueSeq().map(cart => cart.get('id')).toArray()
  }, [props.carts])

  const onRewardDialogOpen = useCallback(() => {
    setRewardDialogOpened(true)
  }, [])

  const onRewardDialogClose = useCallback(() => {
    setRewardDialogOpened(false)
  }, [])

  const onSubmitPromocode = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    dispatch(ApplyPromoCode(cartIds, promocodeValue))
  }, [cartIds, promocodeValue])

  const onDeletePromocode = useCallback(() => {
    dispatch(ApplyPromoCode(cartIds, ''))
  }, [cartIds])

  const onChangeDiscountVisible = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountVisible(e.target.checked)
  }, [])

  const onChangeDiscountType = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountType(e.target.value as 'reward' | 'code')
  }, [])

  const onChangePromocodeValue = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPromocodeValue(e.target.value)
  }, [])

  const isSale = useIsSale();

  if (!firstCart) return null

  return <CartStack spacing={2}>
    <Typography variant="h3">{t('Summary')}</Typography>

    {props.carts.valueSeq().map(cart => {
      return <React.Fragment key={cart.get('id')}>
        <Stack spacing={1}>
          {props.carts.count() > 1 ? <Typography variant="h4">{cart.get('cart_name')}</Typography> : null}
          <PriceLine>
            <PriceLabel>{t('Subtotal')}</PriceLabel>
            <PriceEntry>{cart.get('subtotal').toDinero().add(cart.get('discount_wholesale').toDinero()).toFormat()}</PriceEntry>
          </PriceLine>

          {!cart.get('discount_wholesale').toDinero().isZero() ? <PriceLine className="discount">
            <PriceLabel>{t('Wholesale discount')}</PriceLabel>
            <PriceEntry>{cart.get('discount_wholesale').toDinero().multiply(-1).toFormat()}</PriceEntry>
          </PriceLine> : null}

          <PriceLine>
            <PriceLabel>{t('Shipping')}</PriceLabel>
            <PriceEntry>{cart.get('shipping').toDinero().toFormat()}</PriceEntry>
          </PriceLine>

          {!cart.get('discount').toDinero().isZero() ? <>
            { promocode || !isSale ? <PriceLine className="discount">
                <PriceLabel>{t('Discount')}</PriceLabel>
                <PriceEntry>{cart.get('discount').toDinero().multiply(-1).toFormat()}</PriceEntry>
              </PriceLine> : <div>
                <Stack
                  spacing={1}
                  sx={{
                    background: 'black',
                    borderRadius: '10px',
                    p: 1.5,
                    mx: -1,
                    my: 1,
                  }}
                >
                  <Typography variant="h2" sx={{fontSize: '30px', color: '#fff'}}>BOXING DAY 2024</Typography>
                  <PriceLine className="discount">
                    <PriceLabel>{t('Discount')}</PriceLabel>
                    <PriceEntry>{cart.get('discount').toDinero().multiply(-1).toFormat()}</PriceEntry>
                  </PriceLine>
                </Stack>
              </div> }
            </> : null }

          <PriceLine>
            <PriceLabel>{t('Taxes')}</PriceLabel>
            <PriceEntry>{cart.get('taxes').toDinero().toFormat()}</PriceEntry>
          </PriceLine>
        </Stack>
        <Divider />
      </React.Fragment>
    })}

    <PriceLine className="total">
      <PriceLabel>{t('Total')}</PriceLabel>
      <PriceEntry width={110} height={17}>{grandTotal?.toFormat()}</PriceEntry>
    </PriceLine>

    {['cart', 'ordered'].includes(firstCart.get('status')) && firstCart.get('order_from') !== 'invoice' ? <>
      <Divider />

      <div>
        <FormControlLabel
          control={<Switch
            color="accent"
            checked={discountVisible}
            onChange={onChangeDiscountVisible}
          />}
          label={t('Have a discount?')}
          labelPlacement="start"
          disabled={!!cartLoader || props.disabled}
          componentsProps={{
            typography: {
              variant: 'h3'
            }
          }}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 0
          }}
        />
        <Collapse in={discountVisible}>
          <RadioGroup
            value={discountType}
            onChange={onChangeDiscountType}
          >
            <FormControlLabel
              value="code"
              label={<div>
                <Typography variant="body1" component="p">{t('Apply code')}</Typography>
              </div>}
              control={<Radio />}
              disabled={!!cartLoader || props.disabled}
            />

            <Collapse in={discountType === 'code'}>
              <Stack
                spacing={1}
                alignItems="flex-start"
              >
                { !promocode ? <form onSubmit={onSubmitPromocode}>
                  <TextField
                    value={promocodeValue}
                    onChange={onChangePromocodeValue}
                    disabled={!!cartLoader || props.disabled}
                    size="small"
                    InputProps={{
                      endAdornment: <div style={{
                        background: '#fff',
                        margin: '-7px -16px -7px 0',
                        borderRadius: '30px',
                        alignSelf: 'stretch'
                      }}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="medium"
                          type="submit"
                          disabled={!!cartLoader || props.disabled}
                        >{t('Apply')}</Button>
                      </div>
                    }}
                  />
                </form> : null }

                { promocode ? <Chip
                  color="primary"
                  label={promocode}
                  disabled={!!cartLoader || props.disabled}
                  onDelete={onDeletePromocode}
                /> : null }
              </Stack>
            </Collapse>

            {userRewards.count() > 0 && loggedIn ? <>
              <FormControlLabel
                value="reward"
                label={<Stack spacing={1}>
                  <Typography variant="body1" component="p">{t('Apply reward')}</Typography>
                </Stack>}
                control={<Radio />}
                disabled={!!cartLoader || props.disabled}
                style={{
                  alignItems: 'flex-start'
                }}
              />

              <Collapse in={discountType === 'reward'}>
                { reward ? <div style={{
                  position: 'relative',
                  marginTop: '12px',
                  marginRight: '12px'
                }}>
                  <UserRewardCoupon
                    userReward={reward}
                    fullWidth
                    small
                  />
                  <div style={{
                    border: '1px solid black',
                    background: '#fff',
                    position: 'absolute',
                    top: '-12px',
                    right: '-12px',
                    borderRadius: '50%'
                  }}>
                    <IconButton
                      color="secondary"
                      size="small"
                      onClick={onDeletePromocode}
                    >
                      <CloseRoundedIcon fontSize="inherit"/>
                    </IconButton>
                  </div>
                </div> : <Button
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  onClick={onRewardDialogOpen}
                  disabled={!!cartLoader || props.disabled}
                >{t('Choose reward')}</Button> }
              </Collapse>
            </> : null}
          </RadioGroup>
        </Collapse>
      </div>
    </> : null}

    { loggedIn ? <SelectRewardDialog
      cartIds={cartIds}
      opened={rewardDialogOpened}
      onClose={onRewardDialogClose}
    /> : null }
  </CartStack>
}
