import { KonvaEventObject } from 'konva/lib/Node';
import { Stage } from 'konva/lib/Stage';
import { ElementRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Layer as LayerNode, Line, Rect, Shape, Stage as StageNode, Text, Transformer as TransformerNode } from 'react-konva';
import useStageDimensions from '../_hooks/useStageDimensions';
import useZoom from '../_hooks/useZoom';
import { LAB_TEMPLATE_WIDTH, LAB_TEMPLATE_HEIGHT, LAB_ZOOM_MULTIPLIER } from '../../config';
import { CanvasToLab, InverseZoom } from '../../Common/Utils/ZoomUtils';
import OverlayLayer from './OverlayLayer';
import TransformerLayer from './TransformerLayer';
import ContentLayer from './ContentLayer';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import GuideLayer from './GuideLayer';

type Props = {}

export default function LabStage(props: Props) {
    const { stageWidth, stageHeight } = useStageDimensions();
    const zoom = useZoom();
    
    const zoomScale = useAppSelector(state => state.get('UIData').get('designLab').get('zoomScale'));
    const zoomOffsetX = useAppSelector(state => state.get('UIData').get('designLab').get('zoomOffsetX'));
    const zoomOffsetY = useAppSelector(state => state.get('UIData').get('designLab').get('zoomOffsetY'));
    const isPanning = useAppSelector(state => state.get('UIData').get('designLab').get('isPanning'));

    const stageRef = useRef<Stage>(null);
    const listLayerRefs = useRef(new Map);
    const transformerRef = useRef<ElementRef<typeof TransformerLayer>>(null);

    const [hasStartedPanning, setHasStartedPanning] = useState(false);

    useEffect(() => {
        if (!isPanning) {
            setHasStartedPanning(false);
        }
    }, [isPanning])

    const getLayerRefs = useCallback(() => {
        return listLayerRefs.current;
    }, [])

    const getTransformerRef = useCallback(() => {
        return transformerRef.current;
    }, [])

    const onWheel = useCallback((e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();
        if (!stageRef.current) return;

        const pointer = stageRef.current.getPointerPosition();
        if (!pointer) return;

        let wheel = - Math.round(e.evt.deltaY) / LAB_ZOOM_MULTIPLIER;

        zoom(wheel, { x: pointer.x, y: pointer.y });
    }, [zoom])

    const onDragStart = useCallback(() => {
        if (!isPanning) return;
        setHasStartedPanning(true);
    }, [isPanning])

    const onDragEnd = useCallback(() => {
        if (!isPanning) return;
        setHasStartedPanning(false);
    }, [isPanning])

    const onDragMove = useCallback((e: KonvaEventObject<DragEvent>) => {
        if (!isPanning || !stageRef.current) return;
        setHasStartedPanning(true);

        // Prevent the konva pan it's bad
        stageRef.current.x(0);
        stageRef.current.y(0);

        const offset = { x: zoomOffsetX, y: zoomOffsetY }

        zoom(0, InverseZoom({
            x: offset.x - e.evt.movementX / zoomScale,
            y: offset.y - e.evt.movementY / zoomScale,
        }, offset, zoomScale));
    }, [isPanning, zoomOffsetX, zoomOffsetY, zoomScale, zoom])

    return <StageNode
        ref={stageRef}
        draggable={isPanning}
        width={stageWidth}
        height={stageHeight}
        scaleX={zoomScale}
        scaleY={zoomScale}
        offsetX={zoomOffsetX}
        offsetY={zoomOffsetY}
        onWheel={onWheel}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragMove={onDragMove}
        style={{
            background: "#ededed",
            borderRadius: "10px",
            display: "inline-block",
            overflow: "hidden",
            cursor: isPanning ? (hasStartedPanning ? 'grabbing' : 'grab') : 'default',
        }}
    >
        <ContentLayer 
            getLayerRefs={getLayerRefs}
            getTransformerRef={getTransformerRef}
        />
        <OverlayLayer />
        <GuideLayer/>
        <TransformerLayer
            ref={transformerRef}
            getLayerRefs={getLayerRefs}
        />
    </StageNode>
}