import { Box, Button, Container, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CheckoutSummary from '../../Checkout/Summary/CheckoutSummary'
import { CartHeaderH1 } from '../CartContent/CartHeader'
import { useSearchParams, Link } from 'react-router-dom'
import CartConfirmationContent from './CartConfirmationContent'
import { Helmet } from 'react-helmet-async'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { ProductItem } from '../../UserData/_stores/UserCartStore'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import CartGridContainer from '../CartContent/CartGridContainer'
import CartListGrid from '../CartContent/CartListGrid'
import CartListStack from '../CartContent/CartListStack'
import { FetchPaginationItems } from '../../UserData/_actions/UserDataActions'
import { selectCartConfirmations } from '../../UserData/_selectors/OrderSelectors'
import { OpenAuthModal } from '../../Auth/_actions/AuthActions'
import PageLoader from '../../Common/Loader/PageLoader'

export default function CartConfirmation() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')
  const [tR] = useTranslation('routes')
  const [searchParams, setSearchParams] = useSearchParams()
  const theme = useTheme()
  const breakpointMdDown = useMediaQuery(theme.breakpoints.down('md'))

  const carts = useAppSelector(selectCartConfirmations)
  const loader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))
  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))

  // Load carts based on payment_id
  useEffect(() => {
    const paymentId = searchParams.get('payment_id')
    if (!paymentId) return

    const fd = new FormData()
    fd.append('payment_id', paymentId)
    fd.append('limit', '9999')
    fd.append('fields', 'items')

    dispatch(FetchPaginationItems('cartConfirmation', fd, 'orders'))
  }, [searchParams])

  // Trigger Ad tracking
  useEffect(() => {
    if (Boolean(searchParams.get('logged')) || carts.count() === 0) {
      return
    }

    carts.forEach(cart => {
      const subtotal = cart.get('subtotal').toDinero()
      const discount = cart.get('discount').toDinero()
      const shipping = cart.get('shipping').toDinero().toUnit()
      const taxes = cart.get('taxes').toDinero().toUnit()
      const value = subtotal.subtract(discount).toUnit()
      const currency = subtotal.getCurrency()
      let totalQuantity = 0
      const contents: Array<{ id: string, quantity: number }> = []
      const contentIds: string[] = []
      const items: Array<{
        item_id: string
        item_name: string
        price: number
        quantity: number
      }> = []
      const pItems: Array<{
        product_id: string
        product_name: string
        product_price: number
        product_quantity: number
      }> = []

      cart.get('items').forEach((item) => {
        totalQuantity += item.get('quantity')
        let id = item.get('type')
        let name: string = item.get('name')

        if (item instanceof ProductItem) {
          id += '-' + item.get('product_id')
          name = item.get('product_name')
        }

        contents.push({
          id,
          quantity: item.get('quantity')
        })
        contentIds.push(id)

        items.push({
          item_id: id,
          item_name: name,
          price: item.get('price').toDinero().toUnit(),
          quantity: item.get('quantity')
        })

        pItems.push({
          product_id: id,
          product_name: name,
          product_price: item.get('price').toDinero().toUnit(),
          product_quantity: item.get('quantity')
        })
      })

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      window.fbq && window.fbq('track', 'Purchase', {
        content_type: 'product',
        contentIds,
        value,
        totalQuantity,
        currency,
        contents
      })

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      window.gtag && window.gtag('event', 'purchase', {
        transaction_id: cart.get('ref_id'),
        currency,
        value,
        shipping,
        tax: taxes,
        items
      })

      // @ts-expect-error No types definitions for pinterest ad tracking
      window.pintrk?.('track', 'checkout', {
        value,
        currency,
        order_quantity: totalQuantity,
        order_id: cart.get('ref_id'),
        line_items: pItems
      })
    })

    // Add 'logged' to url so we don't log this twice in case of a refresh
    searchParams.set('logged', '1')
    setSearchParams(searchParams, {
      replace: true
    })
  }, [carts, searchParams])

  const renderCart = useCallback((cart: UserCartStore) => {
    return <CartConfirmationContent
      key={cart.get('id')}
      cart={cart}
    />
  }, [])

  const onOpenSignup = useCallback(() => {
    dispatch(OpenAuthModal(true))
  }, [])

  return <>
    <Helmet>
      <title>{t('Order confirmed')}</title>
    </Helmet>
    {carts.count() > 0 && !loader ? <Container
      maxWidth="xl"
      sx={{
        p: {
          xs: 2,
          md: 3,
          lg: 4
        }
      }}
    >
      <Stack spacing={2}>
        <CartHeaderH1>
          <Box
            component="span"
            sx={{
              display: {
                xs: 'none',
                sm: 'inline'
              }
            }}
          >{t('Cart') + ' > ' + t('Shipping') + ' > ' + t('Payment') + ' > '}</Box>
          <span className="title">{t('Confirmation')}</span>
        </CartHeaderH1>
        <div>
          <CartGridContainer>
            <CartListGrid>
              <CartListStack>
                <Paper
                  elevation={0}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    backgroundColor: '#000',
                    color: '#fff',
                    overflow: 'hidden'
                  }}
                >
                  <div style={{
                    backgroundImage: 'url(' + require('@resources/img/cart/confirmation-ornaments-1.svg?url') + ')',
                    backgroundPosition: 'bottom left',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '25%',
                    height: '95%',
                    backgroundSize: 'contain'
                  }}/>
                  <div style={{
                    backgroundImage: 'url(' + require('@resources/img/cart/confirmation-ornaments-2.svg?url') + ')',
                    backgroundPosition: 'bottom right',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: '30%',
                    height: '85%',
                    backgroundSize: 'contain'
                  }}/>
                  <Stack
                    spacing={2}
                    sx={{
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      px: {
                        xs: 6,
                        md: 10
                      },
                      pt: 7,
                      pb: 9
                    }}
                  >
                    <img
                      src={require('@resources/img/cart/confirmation-heart.svg?url')}
                      width={76}
                      height={57}
                    />
                    <Typography
                      variant="h3"
                      style={{
                        fontSize: '2rem',
                        textAlign: 'center',
                        textShadow: '0 0 5px rgba(0, 0, 0, 1)'
                      }}
                    >{t('Thank you for your order!')}</Typography>
                    <Typography
                      variant="body1"
                      style={{
                        textAlign: 'center',
                        textShadow: '0 0 5px rgba(0, 0, 0, 1)'
                      }}
                    >{ loggedIn
                      ? t('You can go to your account to follow the status of your order, or create and order more.')
                      : t('You can checkout faster in the future with an account, benefit from our Goals & Rewards program, brand your products and packaging for reselling, and integrate your own Etsy, Shopify or Squarespace store. It\'s completely free!')
                    }</Typography>
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'row'
                      }}
                      spacing={2}
                    >
                      <Button
                        color="accent"
                        variant="contained"
                        size="large"
                        href={tR('/create')}
                      >{t('Create a product')}</Button>
                      {loggedIn ? <Button
                        color="white"
                        variant="outlined"
                        size="large"
                        component={Link}
                        to={tR('/account/products')}
                      >{t('Go to Account')}</Button> : <Button
                        variant="outlined"
                        color="white"
                        size="large"
                        onClick={onOpenSignup}
                      >{t('Sign up')}</Button>}
                    </Stack>
                  </Stack>
                </Paper>

                { breakpointMdDown ? <CheckoutSummary carts={carts}/> : null }

                {carts.valueSeq().map(renderCart)}
              </CartListStack>
            </CartListGrid>

            { !breakpointMdDown ? <CheckoutSummary carts={carts}/> : null }
          </CartGridContainer>
        </div>
      </Stack>
    </Container> : <PageLoader />}
  </>
}
