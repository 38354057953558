import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import UserCartStore, { CartStatus } from "../../../UserData/_stores/UserCartStore";

export default function useOrderStatusProps(status:CartStatus) {
  const [t] = useTranslation('common')

  const statusText = useMemo(() => {
    //if(!order) return '';

    switch (status) {
      case 'cancelled': return t('Cancelled')
      case 'ordered': return t('Awaiting payment')
      case 'paid': return t('Paid')
      case 'in-production': return t('In production')
      case 'ready': return t('Ready for shipping')
      case 'shipped': return t('Shipped')
      case 'on-hold': return t('On hold')
      case 'cart': return t('Cart')
      case 'ignored': return t('Ignored')
    }
  }, [status, t]);

  const statusColor:'gray'|'warning'|'info'|'success'|'secondary' = useMemo(() => {
    //if(!order) return 'gray'

    switch (status) {
      case 'cancelled':
        return 'gray'
      case 'ordered':
      case 'on-hold':
        return 'warning'
      case 'paid':
      case 'in-production':
      case 'ready':
        return 'info'
      case 'shipped':
        return 'success'
      default:
        return 'secondary'
    }
  }, [status]);

  return {
    text: statusText,
    color: statusColor,
  }
}