import { useCallback } from 'react';
import { Button, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PinkHeight45FullWidthButton } from '../../Common/_components/Buttons';
import { SaveDesign } from '../../UIData/_actions/DesignLabActions';
import { UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore';
import WebsiteStore from '../../WebsiteStore';
import useCheckDpi from '../_hooks/useCheckDpi';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { DateTime } from 'luxon';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

const ArrowWhite = styled('img')({
    marginLeft: '15px',

    '@media (max-width: 1200px), (max-height: 900px)': {
        width: '30px',
    }
})

const PinkButton = styled(Button)({
    background: 'linear-gradient(0deg, #DA3192 0%, #F600BE 100%)',
    color: '#fff',
    textTransform: 'uppercase',
    fontFamily: 'Bebas Neue',
    fontSize: '26px',
    padding: '0px 30px 0 40px',
    height: '55px',
    borderRadius: '55px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'auto',

    '@media (max-width: 1200px), (max-height: 900px)': {
        padding: '0px 20px 0 30px',
    },

    '&[disabled]': {
		opacity: 0.4,
        color: '#fff',
	},

    '&:hover:not([disabled])': {
		background: 'linear-gradient(0deg, #DA3192 0%, #F600BE 0%)',
	},

	'&:focus:not([disabled])': {
		background: 'linear-gradient(-180deg, #DA3192 0%, #F600BE 100%)',
	}
})

type Props = {
    disabled?: boolean
    onBuild?: () => void
    onSave?: (productDesign: UserProductDesignStore, similarProducts?: { designData:Record<string, any>, previews: string[] }[]) => void
    onError?: () => void
}

export default function SaveButton(props:Props) {
    const [t] = useTranslation('design-lab');
    const dispatch = useAppDispatch();
    const activeDesignId = useAppSelector(state => state.get('UIData').get('designLab').get('activeDesignId'));
    const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
    const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
    const activeOptions = useAppSelector(state => state.get('UIData').get('designLab').get('activeOptions'));
    const layers = useAppSelector(state => state.get('UIData').get('designLab').get('layers'));
    const mirrorModes = useAppSelector(state => state.get('UIData').get('designLab').get('mirrorModes'));
    const autoDesignSubproducts = useAppSelector(state => state.get('UIData').get('designLab').get('autoDesignSubproducts'));
    const designName = useAppSelector(state => state.get('UIData').get('designLab').get('designName'));
    const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));
    const checkDpi = useCheckDpi();

    const onClick = useCallback(() => {
        if(props.disabled || !productData) return;

        props.onBuild && props.onBuild();

        //Default name
        let tmpDesignName = designName;
        if(tmpDesignName === "") {
            tmpDesignName = productData.get('name')+' ('+DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)+')'
        }

        let listUnavailableVariants:string[] = [];
        layers.forEach(layer => {
            const { unavailableVariants } = checkDpi(layer);

            unavailableVariants.forEach(variant => {
                if(!listUnavailableVariants.includes(variant)) {
                    listUnavailableVariants.push(variant);
                }
            });
        });

        const designData = {
            version: '4.0.0',
            productDesignId: activeDesignId,
            productId: productData.get('id'),
            productSlug: productData.get('slug'),
            variant: activeVariant,
            layers: layers.toJS(),
            mirrorModes: mirrorModes.toJS(),
            autoDesignSubproducts: autoDesignSubproducts.toJS(),
            options: activeOptions.toJS(),
            name: tmpDesignName,
            unavailable_variants: listUnavailableVariants,
        };

        const jsonData = JSON.stringify(designData);
        dispatch(SaveDesign(
            jsonData, 
            (productDesign, similarProducts) => {
                props.onSave && props.onSave(productDesign, similarProducts);
            }, 
            () => {
                props.onError && props.onError();
            }
        ));
    }, [props.disabled, productData, activeDesignId, activeVariant, activeOptions, mirrorModes, autoDesignSubproducts, layers, designName, checkDpi]);

    return <PinkButton
        disabled={props.disabled}
        onClick={onClick}
    >{ t('Save') }<ArrowWhite src={require('@resources/img/create/design-lab-v4/icons/white-arrow.svg?url')}/></PinkButton>
}