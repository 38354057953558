import { OrbitControls, OrbitControlsChangeEvent, PerspectiveCamera } from '@react-three/drei';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib';
import Easing from '../Common/Utils/Math/Easing';
import useAnimFrame from '../Common/_hooks/useAnimFrame';
import ModelViewerLights from './ModelViewerLights';
import { CAMERA_MAX_DISTANCE_DEFAULT, CAMERA_MAX_POLAR_DEFAULT, CAMERA_MIN_DISTANCE_DEFAULT, CAMERA_MIN_POLAR_DEFAULT, FIXED_VIEW_TRANSITION_TIME_MS } from './ModelViewerSettings';
import useFixedView from './_hooks/useFixedView';
import useFixedViewTime from './_hooks/useFixedViewTime';
import { LabData } from '../AppData/LabData';

const ENTRY_ANIM_TIME_MS = 1000

type Props = {
    labData: LabData
    variant: string
}

export default function ModelViewerComponents(props: Props) {
    const controls = useRef<OrbitControlsImpl>(null)

    const [fixedView, setFixedView] = useFixedView()
    const [fixedViewTime, setFixedViewTime] = useFixedViewTime()
    const [entryTime,setEntryTime] = useState(Date.now())
    const inFixedViewTransition = Date.now() < fixedViewTime + FIXED_VIEW_TRANSITION_TIME_MS && fixedView !== 'custom'
    const inEntryAnim = Date.now() < entryTime + ENTRY_ANIM_TIME_MS

    const modelViewerData = useMemo(() => {
        return props.labData.variants[props.variant].model_viewer ?? props.labData;
    }, [props.labData, props.variant])

    const minDistance = useMemo(() => modelViewerData?.minDistance ?? CAMERA_MIN_DISTANCE_DEFAULT, [modelViewerData])
    const maxDistance = useMemo(() => modelViewerData?.maxDistance ?? CAMERA_MAX_DISTANCE_DEFAULT, [modelViewerData])
    const minPolar = useMemo(() => modelViewerData?.minPolar ?? CAMERA_MIN_POLAR_DEFAULT, [modelViewerData])
    const maxPolar = useMemo(() => modelViewerData?.maxPolar ?? CAMERA_MAX_POLAR_DEFAULT, [modelViewerData])

    const initPosition = useMemo(() => {
        return new Vector3(
            modelViewerData?.initPosition?.x ?? 0,
            modelViewerData?.initPosition?.y ?? 0,
            modelViewerData?.initPosition?.z ?? (minDistance + maxDistance) / 2
        )
    }, [modelViewerData, minDistance, maxDistance])

    const [cameraDirection, setCameraDirection] = useState(new Vector3())
    
    //We start at max distance for entry animation
    const [cameraPosition, setCameraPosition] = useState(initPosition.clone().setZ(maxDistance))

    useEffect(() => {
        //Trigger entry animation when initPosition changes
        setCameraPosition(initPosition.clone().setZ(maxDistance))
        setEntryTime(Date.now())
    }, [initPosition])
    
    //Entry animation
    useAnimFrame(inEntryAnim, () => {
        let now = Date.now()
        let progress = Easing.EaseOutQuart((now - entryTime) / ENTRY_ANIM_TIME_MS)

        setCameraPosition(value => value.clone().setZ(maxDistance + progress*(initPosition.z - maxDistance)))
    }, [initPosition, entryTime])

    useAnimFrame(inFixedViewTransition, () => {
        if (fixedView === 'custom') return
        
        let now = Date.now()
        let progress = Easing.Bezier((now - fixedViewTime) / FIXED_VIEW_TRANSITION_TIME_MS)

        let angle = {
            'front': 0,
            'left': Math.PI/2,
            'right': 3*Math.PI/2,
            'back': Math.PI
        }[fixedView]

        if(!controls.current) return

        let polar = controls.current.getPolarAngle()
        let polarDiff = (Math.PI / 2.32) - polar
        controls.current.setPolarAngle(polar + polarDiff * progress)

        let azimuthal = controls.current.getAzimuthalAngle()
        let azimuthalDiff = (angle - azimuthal + 3*Math.PI) % (2*Math.PI) - Math.PI
        controls.current.setAzimuthalAngle(azimuthal + azimuthalDiff * progress)
    }, [fixedView])

    useMemo(() => {
        if (fixedView === 'custom') return
        
        if (controls.current) {
            controls.current.setAzimuthalAngle(controls.current.getAzimuthalAngle())
            controls.current.setPolarAngle(controls.current.getPolarAngle())
        }

        setFixedViewTime(Date.now())
    }, [fixedView])

    const onStart = useCallback(() => {
        setFixedView('custom')
    }, [])

    const isAnimating = useMemo(() => {
        return inFixedViewTransition || inEntryAnim
    }, [inFixedViewTransition, inEntryAnim])

    const onChange = useCallback((e?:OrbitControlsChangeEvent) => {
        if(!e) return

        setCameraDirection(e.target.object.getWorldDirection(new Vector3))

        //Disabled for now as I'm worried it may cause additional re-renders and it's not used for anything
        //setCameraPosition(e.target.object.getWorldPosition(new Vector3))
        console.log(e.target.object.getWorldPosition(new Vector3))
    }, [])

    return <>
        <ModelViewerLights cameraDirection={cameraDirection}/>
        <PerspectiveCamera makeDefault fov={35} position={cameraPosition}/>
        <OrbitControls
            ref={controls}
            enableDamping
            enablePan={false}
            enableZoom={!isAnimating}
            enableRotate={!isAnimating}
            minPolarAngle={minPolar}
            maxPolarAngle={maxPolar}
            minDistance={minDistance}
            maxDistance={maxDistance}
            dampingFactor={0.02}
            onChange={onChange}
            onStart={onStart}
            makeDefault
        />
    </>
}