import { CssBaseline, ScopedCssBaseline, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import LabLoader from '../../DesignLab/_components/LabLoader';
import { useTranslation } from 'react-i18next';

const LoaderContainer = styled('div')({
  flex: '1 0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  transition: 'opacity 300ms',

  '&.active': {
    display: 'flex',
  },
})

export default function PageLoader() {
  const [t] = useTranslation('common');

  return <LoaderContainer>
    <Typography
      variant="h2"
      sx={{mb: 2}}
    >{t('Loading')}</Typography>
    <LabLoader />
  </LoaderContainer>
}