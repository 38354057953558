import { Layer as LayerNode, Rect } from 'react-konva';
import { LAB_TEMPLATE_HEIGHT, LAB_TEMPLATE_WIDTH } from '../../config';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import useStageDimensions from '../_hooks/useStageDimensions';
import useStageOffset from '../_hooks/useStageOffset';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import { useCallback, useMemo } from 'react';
import KonvaImage from '../KonvaNode/KonvaImage';
import ContentNode from './ContentNode';
import DesignLabLayerStore from '../../UIData/_stores/DesignLab/DesignLabLayerStore';
import { TransformerHandle } from './TransformerLayer';
import { Node } from 'konva/lib/Node';
import useRenderingTrace from '../../Common/_hooks/useRenderingTrace';

interface Props {
  getLayerRefs: () => Map<string, Node>
  getTransformerRef: () => TransformerHandle | null
}

export default function ContentLayer(props: Props) {
  const dispatch = useAppDispatch()
  const { stageWidth, stageHeight } = useStageDimensions();
  const stageOffset = useStageOffset();
  const activeScene = useAppSelector(state => state.get('UIData').get('designLab').get('activeScene'));
  const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));
  const zoomScale = useAppSelector(state => state.get('UIData').get('designLab').get('zoomScale'));
  const visibleLayerIds = useAppSelector(state => state.get('UIData').get('designLab').get('layers')
    .filter(layer => layer.get('scene') === activeScene && (!activeSubproduct || layer.get('subproduct') === activeSubproduct))
    .valueSeq()
    .map(layer => layer.get('id'))
  , (a, b) => a.equals(b));

  const onDeselect = useCallback(() => {
    dispatch(SelectLayer(null));
  }, [])

  const renderLayer = useCallback((layerId: string) => {
    return <ContentNode
      key={layerId}
      layerId={layerId}
      getLayerRefs={props.getLayerRefs}
      getTransformerRef={props.getTransformerRef}
    />
  }, [props.getLayerRefs, props.getTransformerRef]);

  return <LayerNode>
    <Rect
      width={Math.max(LAB_TEMPLATE_WIDTH, stageWidth / Math.min(1, zoomScale))}
      height={Math.max(LAB_TEMPLATE_HEIGHT, stageHeight / Math.min(1, zoomScale))}
      x={Math.min(stageOffset.x, 0, -(stageWidth / zoomScale - stageWidth) / 2)}
      y={Math.min(stageOffset.y, 0, -(stageHeight / zoomScale - stageHeight) / 2)}
      fill="#fff"
      onMouseDown={onDeselect}
      onTouchStart={onDeselect}
    />
    {visibleLayerIds.map(renderLayer)}
  </LayerNode>
}