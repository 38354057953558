import { FormControl, InputBase, MenuItem, Select, styled } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EditMirrorMode } from '../../UIData/_actions/DesignLabStoreActions';
import WebsiteStore from '../../WebsiteStore';
import useSceneData from '../_hooks/useSceneData';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

const InputSelect = styled(InputBase)({
    height: '100%',

    '.MuiInputBase-input:focus': {
        background: 'transparent',
    },

    '.MuiSelect-select': {
        height: '100%',
        display: 'flex',
        gap: '15px',
        alignItems: 'center',
        padding: '0 24px 0 0',
        color: '#fff',
    },
})

const MirrorFormControl = styled(FormControl)({
    height: '40px',
    borderRadius: '30px',
    background: '#00aced',
    color: '#fff',
    padding: '0 15px',

    '.MuiSelect-icon': {
        color: '#fff',
    }
})

const MirrorItem = styled(MenuItem)({
    display: 'flex',
    gap: '15px',
    
    img: {
        filter: 'invert(1)',
    }
})

type Props = {};

export default function MirrorTool(props:Props) {
    const dispatch = useAppDispatch();
    const [t] = useTranslation('design-lab');
    const mirrorModes = useAppSelector(state => state.get('UIData').get('designLab').get('mirrorModes'));
    const activeScene = useAppSelector(state => state.get('UIData').get('designLab').get('activeScene'));
    const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));
    const autoDesign = useAppSelector(state => activeSubproduct ? state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct) : undefined);
    const sceneData = useSceneData();


    const hasMirrorSide = useMemo(() => {
        return sceneData?.tools.includes('mirror_side')
    }, [sceneData]);

    const hasMirrorFront = useMemo(() => {
        return sceneData?.tools.includes('mirror_front')
    }, [sceneData]);

    const prefix = useMemo(() => {
        return activeSubproduct ? activeSubproduct+'-' : '';
    }, [activeSubproduct])

    return !autoDesign ? <>
        { hasMirrorSide ? <MirrorFormControl variant="standard">
            <Select
                variant="standard"
                input={<InputSelect/>}
                value={mirrorModes.get(prefix + activeScene) || 0}
                onChange={(e) => {
                    dispatch(EditMirrorMode(prefix + activeScene, Number(e.target.value)));
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                }}
            >
                <MirrorItem value={0}><img src={require('@resources/img/create/design-lab-v4/icons/no-mirror.svg?url')} /> {t('No mirror')}</MirrorItem>
                <MirrorItem value={1}><img src={require('@resources/img/create/design-lab-v4/icons/mirror-left.svg?url')} /> {t('Mirror Left')}</MirrorItem>
                <MirrorItem value={3}><img src={require('@resources/img/create/design-lab-v4/icons/clone-left.svg?url')} /> {t('Clone Left')}</MirrorItem>
            </Select>
        </MirrorFormControl> : null }

        { hasMirrorFront && activeScene === 'main' ? <MirrorFormControl variant="standard">
            <Select
                variant="standard"
                input={<InputSelect/>}
                value={mirrorModes.get(prefix + activeScene) || 0}
                onChange={(e) => {
                    dispatch(EditMirrorMode(prefix + activeScene, Number(e.target.value)));
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                }}
            >
                <MirrorItem value={0}><img src={require('@resources/img/create/design-lab-v4/icons/no-mirror.svg?url')} /> {t('No mirror')}</MirrorItem>
                
                <MirrorItem value={5}><img src={require('@resources/img/create/design-lab-v4/icons/mirror-front.svg?url')}/> {t('Mirror Front')}</MirrorItem>
                <MirrorItem value={7}><img src={require('@resources/img/create/design-lab-v4/icons/clone-front.svg?url')}/> {t('Clone Front')}</MirrorItem>
            </Select>
        </MirrorFormControl> : null }
    </> : null;
}