import { Checkbox, FormControlLabel, Stack, styled, Typography } from '@mui/material';

export type Props = {
  image: string
  name: string
  selected?: boolean
}

export default function FilterCard(props: Props) {
  return <Stack 
    direction="row" 
    spacing={2} 
    justifyContent="space-between" 
    alignItems="center" 
    sx={{ 
      flexGrow: 1,
      cursor: 'pointer',
    }}
  >
    <Stack direction="row" spacing={2} alignItems="center">
      <img src={require('@resources/img/all-products/filters/' + props.image + '.svg?url')} alt={props.name} style={{ maxWidth: '35px' }} />
      <Typography variant="body2" sx={{ fontWeight: props.selected ? '600' : '400' }}>{props.name}</Typography>
    </Stack>
    <FormControlLabel
      control={
        <Checkbox
          color="secondary"
          checked={props.selected}
          onClick={(e) => { e.preventDefault() }}
        />
      }
      label=""
    />
  </Stack>
}