/* Applies color transformations to a canvas using a 512x512 LUT png and saves it in a new one in the canvas property. */
function LUThree(sourceCanvas, lutSrc){
	this.ready = false;
	this.lutSrc = lutSrc;
	this.sourceCanvas = sourceCanvas;
	this.renderer = null;
	this.loadLUT();
}

LUThree._renderer = null;

LUThree.prototype.loadLUT = function(lutSrc){
	var me = this;
	me.ready = false;
	lutSrc = lutSrc || me.lutSrc;
    var textureLoader = new THREE.TextureLoader();
    textureLoader.crossOrigin = 'anonymous';
    textureLoader.load(THREE_JSON_URL + lutSrc, function(e){
    	e.minFilter = THREE.NearestFilter;
    	e.magFilter = THREE.NearestFilter;
        me.onLUTload(e);
    });
};

LUThree.prototype.loadTexture = function(){
	this.texture = new THREE.Texture();
	this.texture.image = this.sourceCanvas;
};

LUThree.prototype.onLUTload = function(e){
	this.lut = e;
	this.loadTexture();
	this.setShader();
	this.ready = true;
};

LUThree.prototype.setShader = function(){
	// Save renderer size
		var width = this.sourceCanvas.width;
		var height = this.sourceCanvas.height;
	// Setup new renderer
		if(LUThree._renderer) {
			this.renderer = LUThree._renderer;
		} else {
			this.renderer = new THREE.WebGLRenderer({
				antialias: true
			});
			LUThree._renderer = this.renderer;
		}
		this.renderer.setSize(width, height);
		this.canvas = this.renderer.domElement;
	// Composer
		this.composer = new THREE.EffectComposer(this.renderer);
	// Texture pass
		this.texturePass = new THREE.TexturePass();
		this.texturePass.map = this.texture;
		this.composer.addPass(this.texturePass);
	// LUT pass
		this.lutPass = new THREE.ShaderPass(THREE.LUTShader);
		this.lutPass.uniforms.uLookup.value = this.lut;
		this.lutPass.renderToScreen = true;
		this.composer.addPass(this.lutPass);
};

LUThree.prototype.addWarning = function(element){
	if(this.warning){ return; }
	var $warning = $(
		'<div class="info-badge grey lighten-2" style="margin-bottom:1em">'+
			'<i class="material-icons left" style="">warning</i>'+
			'ton desin yé crissement laid srx tu devrai pas faire ca a moins det conscien que tu va tt fucker'+
		'</div>'
	);
	$warning.insertAfter($(element));
	this.warning = $warning;
};

LUThree.prototype.removeWarning = function(){
	if(this.warning){
		this.warning.remove();
		this.warning = null;
	}
};

export default LUThree;