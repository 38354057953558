import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import useLabData from './useLabData';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

export default function useSceneData() {
    const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
    const activeScene = useAppSelector(state => state.get('UIData').get('designLab').get('activeScene'));

    const labData = useLabData();

    return activeScene ? labData?.variants[activeVariant]?.scenes[activeScene] : undefined;
}