import { Button, styled } from "@mui/material";
import ValidIndicator from "../_components/ValidIndicator";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { EditActiveScene, SelectLayer } from "../../UIData/_actions/DesignLabStoreActions";
import { useCallback } from "react";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";

export const ButtonTab = styled(Button)({
  color: '#333',
  fontWeight: 500,
  fontSize: '16px',
  fontFamily: 'Poppins',
  borderRadius: '20px 20px 0 0',
  background: '#f7f7f7',
  boxShadow: '',
  padding: '10px 20px',
  marginRight: '15px',
  height: '45px',
  position: 'relative',
  lineHeight: '15px',
  '&.active': {
    background: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
    zIndex: 2,
  },
  '&:hover': {
    background: '#ddd',
  },
  '@media (max-width: 953px)': {
    fontSize: '12px',
    padding: '5px 10px',
    marginRight: '-5px',
  }
})

interface Props {
  slug: string
  name: string
}

export default function SceneTab(props: Props) {
  const dispatch = useAppDispatch()

  const activeScene = useAppSelector(state => state.get('UIData').get('designLab').get('activeScene'));
  const resolutionOk = useAppSelector(state => state.get('UIData').get('designLab').get('warnings').get('resolution').get(props.slug));
  const templatesCoveredOk = useAppSelector(state => state.get('UIData').get('designLab').get('warnings').get('templates_covered').get(props.slug));

  const onClick = useCallback(() => {
    dispatch(EditActiveScene(props.slug))
    dispatch(SelectLayer(null));
  }, [props.slug])

  return <ButtonTab
    key={props.slug}
    className={activeScene === props.slug ? 'active' : ''}
    onClick={onClick}
  >
    <ValidIndicator valid={Boolean(templatesCoveredOk && resolutionOk)} iconSize="small" />
    {props.name}
  </ButtonTab>
}