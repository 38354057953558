import { UserProductDesignStore } from '../../../UserData/_stores/UserProductDesignStore';
import { SaveState } from '../../_components/DesignLab';
import SaveActions from './SaveActions';
import SaveGenerating from './SaveGenerating';
import DialogPaper from '../../../Common/Dialog/DialogPaper';
import Dialog from '../../../Common/Dialog/Dialog';
import { styled } from '@mui/material';

const StyledDialogPaper = styled(DialogPaper)({
    backgroundImage: 'url('+require('@resources/img/theme/dialog/ornaments-shapes-1.svg?url')+')',
    backgroundSize: '100%',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
})

type Props = {
    saveState: SaveState
    productDesign: UserProductDesignStore|null
    similarProducts: Record<string,any>[]
    onClose?: () => void
}

export default function SaveDialog(props: Props) {
    return <Dialog
        open={props.saveState !== 'none'}
        maxWidth={"md"}
        PaperComponent={StyledDialogPaper}
    >
        { props.saveState === 'generating' ? <SaveGenerating /> : null }
        { props.saveState === 'done' && props.productDesign ? <SaveActions
            productDesign={props.productDesign}
            similarProducts={props.similarProducts}
            onClose={props.onClose} 
        /> : null }
    </Dialog>
}