THREE.LUTShader = {
	uniforms: {
		"tDiffuse": { value: null },
		"uLookup": {value: null}
	},

	vertexShader: [
		"varying vec2 vUv;",
		"void main() {",
			"vUv = uv;",
			"gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
		"}"
	].join( "\n" ),

	fragmentShader: [
		"precision mediump float;",

		"uniform sampler2D tDiffuse;",
		"uniform sampler2D uLookup;",

		"varying vec2 vUv;",

		"vec4 lookup(in vec4 textureColor, in sampler2D lookupTable) {",
				// Clamp the blue even though it should already be between those 
			    "textureColor = clamp(textureColor, 0.0, 1.0);",
			    "mediump float blueColor = textureColor.b * 63.0;",

			    // Get the first possible square by floor'ing the blue
			    "mediump vec2 quad1;",
			    "quad1.y = floor(floor(blueColor) / 8.0);",
			    "quad1.x = floor(blueColor) - (quad1.y * 8.0);",

			    // Get the second possible square by ceil'ing the blue
			    "mediump vec2 quad2;",
			    "quad2.y = floor(ceil(blueColor) / 8.0);",
			    "quad2.x = ceil(blueColor) - (quad2.y * 8.0);",

			    // Get the color coordinates from the first square and y flip it
			    "highp vec2 texPos1;",
			    "texPos1.x = (quad1.x * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.r);",
			    "texPos1.y = (quad1.y * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.g);",
			    "texPos1.y = 1.0-texPos1.y;",

			    // Get the color coordinates from the second square and y flip it
			    "highp vec2 texPos2;",
			    "texPos2.x = (quad2.x * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.r);",
			    "texPos2.y = (quad2.y * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.g);",
			    "texPos2.y = 1.0-texPos2.y;",

			    // Get the colors at the coordinates
			    "lowp vec4 newColor1 = texture2D(lookupTable, texPos1);",
			    "lowp vec4 newColor2 = texture2D(lookupTable, texPos2);",

			    // Mix them using the blue's offset
			    "lowp vec4 newColor = mix(newColor1, newColor2, fract(blueColor));",

			    // Return the color
			    "return newColor;",
			"}",

		"void main() {",
			"vec4 color = texture2D(tDiffuse, vUv);",
			"gl_FragColor = lookup(color, uLookup);",
		"}"
	].join( "\n" )

};