import Skeleton from '@mui/material/Skeleton';
import { Box, Button, Grid, IconButton, Pagination as MuiPagination, Paper, Stack, Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../Common/_components/Loader';
import { UploadUserImage } from '../../../UserData/_actions/UserImageActions';
import WebsiteStore from '../../../WebsiteStore';
import CloseIcon from '@mui/icons-material/Close';
import ItemList from '../../../Common/ItemList/ItemList';
import Dialog from '../../../Common/Dialog/Dialog';
import DialogContent from '../../../Common/Dialog/DialogContent';
import DialogPaper from '../../../Common/Dialog/DialogPaper';
import SearchBar from '../../../Common/ItemList/SearchBar';
import { useAppDispatch } from '../../../Common/_hooks/useAppDispatch';
import Image from '../../../Account/Images/Image';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import { UserImageStore } from '../../../UserData/_stores/UserImageStore';
import { useAppSelector } from '../../../Common/_hooks/useAppSelector';

const IMAGES_PER_PAGE = 20;

const StyledDialogPaper = styled(DialogPaper)({
  height: '90%',
})

const StyledDialogContent = styled(DialogContent)({
	padding: '40px 50px 0',
	overflow: 'hidden',
	display: 'flex',

	'@media (max-width: 899px)': {
		padding: '20px 20px 0',
	}
})

const OverflowGridContainer = styled(Grid)({
	'@media (max-width: 899px)': {
		flexDirection: 'column',
		flexWrap: 'nowrap',
	}
})

const OverflowGrid = styled(Grid)({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	overflow: 'hidden',

	'@media (max-width: 899px)': {
		'&.categories': {
			height: 'auto',
			flexBasis: 'auto',
			flexShrink: 0,
			flexGrow: 0,
		},

		'&:not(.categories)': {
			height: 'auto',
			flexBasis: 'auto',
			flexShrink: 1,
			flexGrow: 1,
			paddingTop: '15px',
			marginTop: '-15px',
		}
	}
})

const ScrollableGrid = styled(Grid)({
	overflow: 'auto',
	marginBottom: 0,
	paddingBottom: '30px',
	width: 'calc(100% + 16px)',
	paddingRight: '5px',
	flex: 1,
	alignContent: 'flex-start',

	'&::-webkit-scrollbar': {
		width: '10px',
		height: '10px',
	},

	'&::-webkit-scrollbar-track': {
		backgroundColor: 'transparent',
	},

	'&::-webkit-scrollbar-thumb': {
		backgroundColor: 'rgba(0, 0, 0, 0.15)',
		borderRadius: '10px',

		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.25)',
		}
	}
})

export type Props = {
	opened: boolean
	onClose?: () => void
	onSelect?: Function
	onUpload?: Function
}

export default function ImageLibraryBrowser(props: Props) {
	const dispatch = useAppDispatch();
	const [t] = useTranslation('design-lab');

	const [isDragActive, setIsDragActive] = useState(false);
	const dropzoneRef = useRef<DropzoneRef>(null);

	const loading = useAppSelector(state => state.get('UIData').get('loaders').get('images'));
	const isUploading = useAppSelector(state => state.get('userData').get('images').get('isUploading'));

	const [search, setSearch] = useState('');

	const onDragEnter = useCallback(() => setIsDragActive(true), [])
  const onDragLeave = useCallback(() => setIsDragActive(false), [])

  const onUpload = useCallback((files: File[]) => {
    setIsDragActive(false)
    files.forEach(file => {
      const fd = new FormData()
      fd.append('file', file)
      dispatch(UploadUserImage(fd))
    })
  }, [])

	const openDropzone = useCallback(() => {
    if (!dropzoneRef.current) return
    dropzoneRef.current.open()
  }, [])

	const loadingGrid = useMemo(() => {
		const listSkeletons = [];

		for (let i = 0; i < IMAGES_PER_PAGE; i++) {
			listSkeletons.push(<Grid key={i} item xs={6} sm={4} lg={3} xl={2}><Skeleton variant="rounded" width={"100%"} height={205} /></Grid>);
		}

		return listSkeletons;
	}, []);

	const renderItem = useCallback((item: UserImageStore) => {
    return <Image 
			item={item}
			onSelect={(item) => {
				props.onSelect && props.onSelect(item);
				props.onClose && props.onClose();
			}}
		/>
  }, [props.onSelect, props.onClose])

	const renderList = useCallback((items: JSX.Element | null, links: JSX.Element | null, loading: boolean) => {
    return <>
			<ScrollableGrid container spacing={2}>
				{loading
					? loadingGrid
					: (items || isUploading ? <>
						{isUploading && <Grid item xs={6} sm={4} lg={3} xl={2}><Skeleton variant="rounded" width={"100%"} height={205} /></Grid>}
						{items}
					</> : <Grid item xs={12}>
						<span>{t('No Results')}</span>
					</Grid>)
				}
			</ScrollableGrid>

      {links}
    </>
  }, [loadingGrid, isUploading, t])

	return <>
		<Dialog
			open={props.opened}
			onClose={props.onClose}
			fullWidth={true}
			maxWidth="xl"
			PaperComponent={StyledDialogPaper}
			showCloseButton
		>
			<StyledDialogContent>
				<Dropzone 
					ref={dropzoneRef}
					noClick
					multiple
					accept={{
						'image/jpeg': ['.jpg','.jpeg'],
						'image/png': ['.png'],
						'image/gif': ['.gif'],
					}} 
					onDrop={onUpload}
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
				>
					{({ getRootProps, getInputProps }) => (
						<OverflowGridContainer container spacing={5} {...getRootProps()}>
							<input {...getInputProps({ disabled: false })} />
							<OverflowGrid
								className="categories"
								item
								xs={12}
								md="auto"
								sx={{
									width: {
										xs: 'auto',
										md: '300px',
									}
								}}
							>
								<Typography
									variant="h2"
									style={{
										marginBottom: '20px',
										paddingRight: '20px',
									}}
								>{t('Image Library')}</Typography>
								<Button
									variant="contained"
									color="primary"
									disableElevation
									style={{
										marginBottom: '20px',
									}}
									onClick={openDropzone}
									startIcon={<img src={require('@resources/img/create/design-lab-v4/icons/add-image.svg?url')} alt={t('Add Image icon')} />}
								>
									<span>{t('Upload')}</span>
								</Button>
								<Typography
									variant="body1"
									sx={{
										display: {
											xs: 'none',
											md: 'block',
										}
									}}
								>{t('Or drag and drop your image in this box to upload it to your library.')}</Typography>


							</OverflowGrid>
							<OverflowGrid item xs={12} md>
								<SearchBar
									placeholder={t('Search your images...')}
									value={search}
									onChange={setSearch}
								>
								</SearchBar>
								<ItemList
									dataType={'images'}
									item={renderItem}
									controlled={true}
									searchQuery={search}
									limit={IMAGES_PER_PAGE}
									render={renderList}
								/>
							</OverflowGrid>
							{isDragActive ? <Stack spacing={1} sx={{
								position: 'absolute',
								top: 0,
								left: 0,
								width: '100%',
								height: '100%',
								backgroundColor: 'rgba(255, 255, 255, 0.75)',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}>
								<Typography variant="h2" component="h3">{t('Drop your files here')}</Typography>
								<SaveAltRoundedIcon
									color="secondary"
									sx={{
										fontSize: '4rem',
									}}
								/>
							</Stack> : null}
						</OverflowGridContainer>
					)}
				</Dropzone>
			</StyledDialogContent>
			{loading && <Loader noModal />}
		</Dialog>
	</>;
}