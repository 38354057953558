import { useTranslation } from "react-i18next"
import { useAppSelector } from "../_hooks/useAppSelector";

export default function useErrorText(errorKey?: string) {
  const [t] = useTranslation('common');

  const error = useAppSelector(state => errorKey ? state.get('UIData').get('apiErrors').get(errorKey) : undefined)

  if (error?.error) {
    switch (error.error) {
      //Auth / Security
      case 'invalid-password':
        return t('Invalid password')
      case 'email-exists':
        return t('An account with that email already exists.')

      case 'fb-exists-error':
        return t('This Facebook account is already linked to another Art of Where account.')
      case 'fb-email-error':
        return t('Make sure you accept to provide your email address, or we won\'t be able to create your account.')
      case 'fb-login-cancelled':
        return t('It looks like you cancelled the login through Facebook. Make sure you click the Continue button on the Facebook screen.')
      case 'fb-error':
        return t('An error occured while connecting with Facebook. Please try again.')

      case 'g-exists-error':
        return t('This Google account is already linked to another Art of Where account.')
      case 'g-error':
        return t('An error occured while connecting with Google. Please try again.')

      case 'account-link-error':
        return t('An error occured while linking your account. Please refresh the page and try again.')

      case 'invalid-reset-password-token':
        return t('The link for resetting your password has expired.')

      //Addresses
      case 'invalid-country':
        return t('Please select a country.')
      case 'invalid-postal-code':
        return t('Please enter a valid postal code.')
      case 'invalid-address':
        return t('Address doesn\'t exist.')
    }
  }

  return null
}