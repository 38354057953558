import { Fade, Grid, Paper, styled } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ProductStore from "../../AppData/_stores/ProductStore";
import ErrorBoundary from "../../Common/_components/ErrorBoundary";
import ProductImage from "./ProductImage";
import useRenderingTrace from "../../Common/_hooks/useRenderingTrace";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";

const StyledCard = styled(Paper)({
  cursor: 'pointer',
  borderRadius: '20px',
  position: 'relative',
  overflow: 'hidden',
  userSelect: 'none',
  //boxShadow: '0 0 6px 2px rgba(0,0,0,.1)',
  height: '100%',

  '&:before': {
    border: '2px solid transparent',
    borderRadius: '20px',
    bottom: 0,
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: 'border 250ms',
  },

  '&.selected:before': {
    borderColor: '#000',
  }
})

interface Props {
  product: ProductStore
  onSelectorHide: () => void
}

export default function ProductCard(props: Props) {
  const [tR] = useTranslation('routes');
  const isSelected = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId') === props.product.get('id'));

  const onClick = useCallback(() => {
    if (isSelected) {
      props.onSelectorHide()
    }
  }, [props.onSelectorHide, isSelected])

  return <Grid item xs={6} sm={4} md={4} lg={3} xl={2}>
    <Link to={tR('/create') + '/' + props.product.get('url_slug')} replace style={{ textDecoration: 'none', display: 'block', height: '100%', }}>
      <StyledCard
        elevation={3}
        className={isSelected ? 'selected' : ''}
        onDoubleClick={props.onSelectorHide}
        onClick={onClick}
      >
        <ErrorBoundary fallback={<></>}>
          <ProductImage
            slug={props.product.get('slug')}
            width={292}
            height={292}
          />
        </ErrorBoundary>
        <div
          style={{
            color: 'black',
            fontSize: '16px',
            fontWeight: 500,
            padding: '5px 10px 10px',
          }}
        >{props.product.get('name')}</div>
      </StyledCard>
    </Link>
  </Grid>
}