import { Box, Container, Divider, Grid, Paper, Stack, Typography, styled } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import AddToCartButton from "../Cart/AddToCartButton";
import { useCallback, useMemo } from "react";
import { useAppDispatch } from "../Common/_hooks/useAppDispatch";
import { UpdateCartItem } from "../UserData/_actions/UserCartActions";
import { useAppSelector } from "../Common/_hooks/useAppSelector";
import DineroFactory from "dinero.js";
import SamplePack from "./SamplePack";

export default function SamplePacks() {
  const [t] = useTranslation()

  const userCurrency = useAppSelector(state => state.get('userData').get('prefs').get('currency'))

  const priceFabric = useMemo(() => {
    return DineroFactory({
      amount: 1000,
      currency: userCurrency
    })
  },[userCurrency])

  const pricePaper = useMemo(() => {
    return DineroFactory({
      amount: 500,
      currency: userCurrency
    })
  },[userCurrency])

  return <Box component="div" sx={{
    backgroundImage: 'url(' + require('@resources/img/theme/page/ornaments-1.svg?url') + ')',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    pt: 6,
    pb: 15,
    flex: '1 0 auto',
  }}>
    <Container
      maxWidth="md"
    >
      <Helmet>
        <title>{t('Sample Packs')}</title>
      </Helmet>

      <Stack spacing={3}>
        <Typography variant="h1" textAlign="center">{t('Order sample packs')}</Typography>
        <Paper
          elevation={0}
        >
          <Stack
            divider={<Divider />}
          >
            <SamplePack
              type="sample_pack_preprinted_synthetic"
              price={priceFabric}
              img={require('@resources/img/pages/sample-packs/sample-packs.jpg')}
              title={t('Fabric sample pack')}
              text={t('All of our current fabrics printed with our standard test image so you can see the difference between fabrics and how each synthetic and natural fabric will print.')}
            />
            <SamplePack
              type="sample_pack_preprinted_paper"
              price={pricePaper}
              img={require('@resources/img/pages/sample-packs/paper-sample-pack.jpg')}
              title={t('Paper sample pack')}
              text={t('All of our current paper options for fine art prints so you can see the difference and decide what is best for your art. Also included is a sample of our notebook covers and paper options.')}
            />
          </Stack>
        </Paper>
      </Stack>
    </Container>
  </Box>
}