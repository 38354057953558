import { ElementRef, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import LabLoader from "./LabLoader";
import ModelViewer from "../../ModelViewer/ModelViewer";
import useLabData from "../_hooks/useLabData";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import Renderer from "../Renderer/Renderer";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import ErrorBoundary from "../../Common/_components/ErrorBoundary";
import { LabData } from "../../AppData/LabData";

export default function LabModelViewer() {
  const [t] = useTranslation('design-lab');
  const rendererRef = useRef<ElementRef<typeof Renderer>>(null)
  const labData = useLabData()
  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'))
  const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'))
  const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'))
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)))
  const activeOptions = useAppSelector(state => state.get('UIData').get('designLab').get('activeOptions'))

  const { subproductRef, subproductRef_labData } = useMemo(() => {
    const productLabData = productData?.get('labData');

    let tmpSubproductRef = productLabData?.has_subproducts ? Object.keys(productLabData?.subproducts)[0] : undefined
    let tmpData:LabData|undefined = undefined

    if (productLabData?.has_subproducts && tmpSubproductRef) {
      tmpData = productLabData?.subproducts[tmpSubproductRef].originalData
    }

    return {
      subproductRef: tmpSubproductRef,
      subproductRef_labData: tmpData,
    }
  }, [productData]);

  const { textureCanvas, textureContext } = useMemo(() => {
    const textureCanvas = new OffscreenCanvas(1, 1);
    const textureContext = textureCanvas.getContext('2d');

    return {
      textureCanvas,
      textureContext,
    }
  }, [])

  const renderTexture = useCallback(() => {
    if (rendererRef.current === null) return

    const stage = rendererRef.current.getStage()
    if (stage === null || textureContext === null) return

    textureCanvas.width = stage.width()
    textureCanvas.height = stage.height()

    for (let layer of stage.children) {
      if (!layer.isVisible()) {
        return;
      }

      textureContext.drawImage(layer.canvas._canvas, 0, 0, layer.width(), layer.height());
    }
    return textureCanvas;
  }, [])

  const modelOptions = useMemo(() => {
    let tmpModelOptions = activeOptions;

    //Just return activeOptions if no subproduct
    if (!productData?.get('has_subproducts') || !activeSubproduct) {
      return tmpModelOptions;
    }

    //Add selected subproduct to modelOptions
    //TODO: This should support having subproducts based on multiple option types though none exist at the time of writing
    const listOptionSlugs = activeSubproduct.split('|');
    listOptionSlugs.forEach(optionSlug => {
      //Find option that matches the slug
      productData.get('availableOptionTypes').forEach(optionType => {
        let found = false;

        optionType.get('options').forEach(option => {
          if (option.get('slug') === optionSlug) {
            tmpModelOptions = tmpModelOptions.set(optionType.get('slug'), optionSlug);
            found = true;
            return false;
          }
        })

        if (found) return false;
      })
    })

    return tmpModelOptions;
  }, [activeOptions, productData, activeSubproduct])

  if (!labData || activeProductId === null) return null

  return <>
    <Suspense
      fallback={<LabLoader />}
    >
      <ErrorBoundary fallback={<Alert
        severity="error"
      >{t('An error occurred while loading the 3D preview.')}</Alert>}>
        <ModelViewer
          renderTexture={renderTexture}
          viewerId="design-lab"
          productId={activeProductId}
          labData={labData}
          options={modelOptions}
          variant={activeVariant}
        />
      </ErrorBoundary>
    </Suspense>
    <Renderer
      ref={rendererRef}
      labData={labData}
      subproductRef={subproductRef}
      subproductRef_labData={subproductRef_labData}
      inputType="lab"
      outputType="texture"
    />
  </>
}