import { Button, IconButton, Paper, Stack, styled, Typography } from "@mui/material";
import { ExpandLessRounded as ExpandLessRoundedIcon, CloseRounded as CloseRoundedIcon } from '@mui/icons-material';
import classNames from "classnames";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";

const SidebarContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  transition: 'transform 300ms, opacity 300ms',

  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    bottom: '0px',
    zIndex: '10',
    left: '20px',
    right: '20px',
    display: 'none',

    '&.has-selected-product': {
      display: 'block',
    }
  },

  ['@media (max-width: 600px)']: {
    left: '10px',
    right: '10px',
  },

  '&.hidden': {
    transform: 'translateX(-100px)',
    opacity: '0',
  },

  '&:before': {
    content: '""',
    height: '130px',
    width: '130px',
    borderRadius: '50%',
    border: '20px solid #7FD3F3',
    display: 'block',
    position: 'absolute',
    zIndex: '-1',
    right: '-40px',
    top: '40px',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },

  '&:after': {
    content: '""',
    height: '150px',
    width: '150px',
    border: '20px solid #FCF57F',
    display: 'block',
    position: 'absolute',
    zIndex: '-1',
    right: '-60px',
    bottom: '120px',
    margin: 'auto',
    transform: 'rotate(-20deg)',

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
}));

const Sidebar = styled(Paper)(({ theme }) => ({
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  background: '#fff',
  marginRight: '30px',
  width: '350px',
  flexShrink: '0',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  position: 'relative',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',

  ['@media (max-width: 1510px), (max-height: 1070px)']: {
    marginRight: '15px',
  },

  [theme.breakpoints.down('md')]: {
    width: 'auto',
    flex: 1,
    margin: '0',
    paddingTop: '0',
    paddingBottom: theme.spacing(3),
    minHeight: '0',
    transition: 'min-height 250ms',
    overflow: 'hidden',

    '&.opened': {
      minHeight: '75vh',
      maxHeight: '75vh',
      overflow: 'auto',
    }
  },

  [theme.breakpoints.down('sm')]: {
    paddingBottom: theme.spacing(2),
  }
}));

const SidebarMobileButton = styled(Button)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const SidebarHeader = styled('div')(({ theme }) => ({
  h2: {
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
    },
  }
}));

const SidebarContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '600px',
    display: 'none',
  },

  '&.opened': {
    display: 'block',
  }
}));

const StartButton = styled(Button)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Bebas Neue',
  fontSize: '26px',
  padding: '0 40px',
  height: '55px',
  borderRadius: '55px',
  flexShrink: '0',
}));

const OptionUl = styled('ul')({
  paddingLeft: '25px',
  margin: '5px 0',
  lineHeight: '30px',

  '&.col2': {
    display: 'flex',
    flexWrap: 'wrap',

    li: {
      flexBasis: '50%',
      paddingRight: '30px',
    }
  }
})

const OptionLi = styled('li')``

const ProductLink = styled('a')({
  color: '#00aced',
})

const StartingPrice = styled('div')({
  fontSize: '12px',
  textAlign: 'right',
  marginTop: '-15px',

  '& > div': {
    fontFamily: 'Bebas Neue',
    fontSize: '40px',
    lineHeight: 1,
  }
})

interface Props {
  onStart: () => void
  hidden: boolean
}

export default function ProductSidebar(props:Props) {
  const [t] = useTranslation('design-lab');
  const [tR] = useTranslation('routes');

  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));

  const [sidebarOpened, setSidebarOpened] = useState(false);

  const onSidebarToggle = useCallback(() => {
    setSidebarOpened(value => !value)
  }, [])

  const listSizes = useMemo(() => {
      const options = productData?.get('availableOptionTypes').get('1')?.get('options');
      if (!options) return undefined;
  
      let list: string[] = [];
      options.forEach(option => {
        if (list.includes(option.get('name'))) return;
        list.push(option.get('name'));
      });
      return list;
    }, [productData]);
  
    const listFabrics = useMemo(() => {
      const options = productData?.get('availableOptionTypes').get('2')?.get('options');
      if (!options) return undefined;
  
      let list: string[] = [];
      options.forEach(option => {
        if (list.includes(option.get('name'))) return;
        list.push(option.get('name'));
      });
      return list;
    }, [productData]);

  return <SidebarContainer className={classNames({
    hidden: props.hidden,
    'has-selected-product': productData !== undefined
  })}>
    <Sidebar
      elevation={16}
      className={(props.hidden ? 'hidden' : '') + (sidebarOpened ? ' opened' : '')}
    >
      {productData ? <>
        <SidebarMobileButton
          color="secondary"
          fullWidth
          onClick={onSidebarToggle}
          sx={{
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }}
        ><ExpandLessRoundedIcon
            fontSize="large"
            sx={{
              transform: sidebarOpened ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 250ms',
            }}
          /></SidebarMobileButton>
        <Stack
          direction={{
            xs: 'column',
            sm: sidebarOpened ? 'column' : 'row',
            md: 'column',
          }}
          spacing={2}
          sx={{
            px: {
              xs: 2,
              sm: 3,
            },
            alignItems: {
              xs: 'flex-start',
              sm: sidebarOpened ? 'flex-start' : 'center',
              md: 'flex-start',
            },
            justifyContent: {
              xs: 'flex-start',
              sm: sidebarOpened ? 'flex-start' : 'space-between',
              md: 'flex-start',
            }
          }}
        >
          <SidebarHeader>
            <Typography variant="h2">{productData.get('name')}</Typography>
            { /*<StartingPrice>
                                { t('Starting at') }
                                <div>{ t('30.00$') }</div>
                            </StartingPrice>*/ }
          </SidebarHeader>
          <StartButton
            color="accent"
            variant="contained"
            disableElevation
            className={sidebarOpened ? 'opened' : ''}
            onClick={props.onStart}
          >{t('Create new')}</StartButton>
          <SidebarContent className={sidebarOpened ? 'opened' : ''}>
            <p
              style={{
                fontSize: '15px',
                lineHeight: '1.5em',
                marginTop: '5px',
              }}
            >
              {productData.get('shop_description')}
              <br />
              { /*<ProductLink href={'/products/'} target="_blank">{ t('Learn more') }</ProductLink>*/ }
            </p>

            {listFabrics ? <div style={{ marginBottom: '20px' }}>
              <Typography
                variant="h4"
              >{t('Fabrics available')}</Typography>
              <OptionUl>
                {listFabrics.map(option => {
                  return <OptionLi key={option}>{option}</OptionLi>
                })}
              </OptionUl>
            </div> : null}

            {listSizes ? <div style={{ marginBottom: '20px' }}>
              <Typography
                variant="h4"
              >{t('Sizes available')}</Typography>
              <OptionUl className={listSizes.length > 10 ? 'col2' : ''}>
                {listSizes.map(option => {
                  return <OptionLi key={option}>{option}</OptionLi>
                })}
              </OptionUl>
            </div> : null}
          </SidebarContent>
        </Stack>
      </> : null}

    </Sidebar>

    <IconButton
      color="secondary"
      component={Link}
      to={tR('/create')}
      replace
      sx={{
        position: 'absolute',
        right: 12,
        top: 12,
        display: {
          xs: 'inline-flex',
          md: 'none',
        }
      }}
    >
      <CloseRoundedIcon fontSize="large" color="inherit" />
    </IconButton>
  </SidebarContainer>
}