import { IconButton, Paper, styled } from "@mui/material";
import ParsedTextInput from "../../Common/_components/ParsedTextInput";
import NumberInput from "../../Common/_components/NumberInput";

export const PropertyColumnWrap = styled('div')({
  height: '100%',

  '&.left': {
      borderRight: '1px solid rgba(255, 255, 255, 0.1)',
  }
})

export const PropertyH5 = styled('h5')({
  margin: 0,
  fontSize: '14px',
  fontWeight: 400,
  marginBottom: '5px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
})

export const PropertyTextInput = styled(ParsedTextInput)({
  background: '#515151',
  borderRadius: '20px',
  height: '28px',
  fontSize: '12px',
  padding: '0 10px',
  color: '#fff',
  width: '70px',
  boxSizing: 'border-box',
  border: 0,
  outline: 0,
  display: 'block',
  marginBottom: '10px',
})

export const PropertyNumberInput = styled(NumberInput)({
  background: '#515151',
  borderRadius: '20px',
  height: '28px',
  fontSize: '12px',
  padding: '0 10px',
  color: '#fff',
  width: '70px',
  boxSizing: 'border-box',
  border: 0,
  outline: 0,
  display: 'block',
  marginBottom: '10px',
})

export const PropertyLabel = styled('label')({
  color: '#8e8e8e',
  fontSize: '12px',
  display: 'block',
})

export const PanelCloseButton = styled(IconButton)({
  position: 'absolute',
  top: '-6px',
  right: '-6px',
  color: '#fff',
})

export type PropertyPanelType = 'none' | 'warning' | 'properties' | 'font' | 'color' | 'repeat';

const PropertyPanel = styled(Paper)({
  position: 'absolute',
  top: 0,
  left: 0,
  boxSizing: 'border-box',
  background: '#414141',
  color: '#fff',
  width: '100%',
  padding: '10px 35px 10px 15px',
  height: '100%',
  transition: 'transform 200ms, opacity 200ms',
  opacity: 0,
  transform: 'scaleY(0)',
  transformOrigin: 'bottom',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 20,
  borderRadius: '15px',

  '&.opened': {
      transform: 'scaleY(1)',
      opacity: 1,
  }
})

export default PropertyPanel;