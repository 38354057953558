import { Grid } from "@mui/material";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import PropertyPanel, { PanelCloseButton, PropertyColumnWrap, PropertyH5, PropertyLabel, PropertyNumberInput } from "./PropertyPanel";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { useCallback } from "react";
import { EditLayer } from "../../UIData/_actions/DesignLabStoreActions";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import { useHotkeys } from "react-hotkeys-hook";

interface Props {
  layerId: string
  onClose: () => void
  opened?: boolean
}

export default function TransformPropertyPanel(props: Props) {
  const [t] = useTranslation('design-lab');
  const dispatch = useAppDispatch()
  const layer = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId));
  const isSelected = useAppSelector(state => state.get('UIData').get('designLab').get('selectedLayer') === props.layerId);

  const onXChange = useCallback((value: number) => {
    dispatch(EditLayer(props.layerId, {
      x: value,
    }));
  }, [props.layerId])

  const onYChange = useCallback((value: number) => {
    dispatch(EditLayer(props.layerId, {
      y: value,
    }));
  }, [props.layerId])

  const onRotationChange = useCallback((value: number) => {
    dispatch(EditLayer(props.layerId, {
      rotation: value,
    }));
  }, [props.layerId])

  const onScaleChange = useCallback((value: number) => {
    dispatch(EditLayer(props.layerId, {
      scaleX: value,
      scaleY: value,
    }));
  }, [props.layerId])

  const moveHotkey = useCallback((data: { x?: number, y?: number }) => {
    return (e: KeyboardEvent) => {
      if (!isSelected) return;
      e.preventDefault();

      dispatch(EditLayer(props.layerId, data));
    }
  }, [props.layerId, isSelected])

  useHotkeys('up', layer ? moveHotkey({ y: layer.get('y') - 1 }) : () => { }, {
    scopes: ['design-lab']
  }, [layer, moveHotkey]);
  useHotkeys('shift+up', layer ? moveHotkey({ y: layer.get('y') - 10 }) : () => { }, {
    scopes: ['design-lab']
  }, [layer, moveHotkey]);
  useHotkeys('down', layer ? moveHotkey({ y: layer.get('y') + 1 }) : () => { }, {
    scopes: ['design-lab']
  }, [layer, moveHotkey]);
  useHotkeys('shift+down', layer ? moveHotkey({ y: layer.get('y') + 10 }) : () => { }, {
    scopes: ['design-lab']
  }, [layer, moveHotkey]);
  useHotkeys('left', layer ? moveHotkey({ x: layer.get('x') - 1 }) : () => { }, {
    scopes: ['design-lab']
  }, [layer, moveHotkey]);
  useHotkeys('shift+left', layer ? moveHotkey({ x: layer.get('x') - 10 }) : () => { }, {
    scopes: ['design-lab']
  }, [layer, moveHotkey]);
  useHotkeys('right', layer ? moveHotkey({ x: layer.get('x') + 1 }) : () => { }, {
    scopes: ['design-lab']
  }, [layer, moveHotkey]);
  useHotkeys('shift+right', layer ? moveHotkey({ x: layer.get('x') + 10 }) : () => { }, {
    scopes: ['design-lab']
  }, [layer, moveHotkey]);

  if (!layer) return null;

  return <PropertyPanel elevation={4} className={props.opened ? 'opened' : ''}>
    <PanelCloseButton
      onClick={props.onClose}
      size="large"
    >
      <CloseIcon />
    </PanelCloseButton>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PropertyColumnWrap className="left">
          <PropertyH5>{t('Position')}</PropertyH5>
          <PropertyLabel htmlFor={"layer" + props.layerId + "_x"}>X</PropertyLabel>
          <PropertyNumberInput
            id={"layer" + props.layerId + "_x"}
            value={layer.get('x')}
            onChange={onXChange}
          />

          <PropertyLabel htmlFor={"layer" + props.layerId + "_y"}>Y</PropertyLabel>
          <PropertyNumberInput
            id={"layer" + props.layerId + "_y"}
            value={layer.get('y')}
            onChange={onYChange}
          />
        </PropertyColumnWrap>
      </Grid>
      <Grid item xs={6}>
        <PropertyColumnWrap className="right">
          <PropertyH5>{t('Transform')}</PropertyH5>
          <PropertyLabel htmlFor={"layer" + props.layerId + "_rotation"}>{t('Rotation')}</PropertyLabel>
          <PropertyNumberInput
            id={"layer" + props.layerId + "_rotation"}
            value={layer.get('rotation')}
            onChange={onRotationChange}
          />

          <PropertyLabel htmlFor={"layer" + props.layerId + "_scale"}>{t('Scale')}</PropertyLabel>
          <PropertyNumberInput
            id={"layer" + props.layerId + "_scale"}
            value={layer.get('scaleX')}
            onChange={onScaleChange}
          />
        </PropertyColumnWrap>
      </Grid>
    </Grid>
  </PropertyPanel>
}