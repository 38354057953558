import { useCallback } from 'react';
import { Layer as LayerNode, Line } from 'react-konva';
import { GuideStore } from '../../UIData/_stores/DesignLab/GuideStore';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

export default function GuideLayer() {
  const zoomScale = useAppSelector(state => state.get('UIData').get('designLab').get('zoomScale'));
  const zoomOffsetX = useAppSelector(state => state.get('UIData').get('designLab').get('zoomOffsetX'));
  const zoomOffsetY = useAppSelector(state => state.get('UIData').get('designLab').get('zoomOffsetY'));
  const guides = useAppSelector(state => state.get('UIData').get('designLab').get('guides'))

  const renderGuide = useCallback((guide: GuideStore) => {
    return <Line
      key={guide.get('lineGuide')}
      points={guide.get('orientation') === 'H' ? [-6000, 0, 6000, 0] : [0, -6000, 0, 6000]}
      stroke="#f600be"
      strokeWidth={1 * (1 / zoomScale)}
      x={guide.get('orientation') === 'H' ? 0 : guide.get('lineGuide')/zoomScale + zoomOffsetX}
      y={guide.get('orientation') === 'H' ? guide.get('lineGuide')/zoomScale + zoomOffsetY : 0}
      listening={false}
    />
  }, [zoomScale, zoomOffsetX, zoomOffsetY])

  return <LayerNode>
    {guides.map(renderGuide)}
  </LayerNode>
}