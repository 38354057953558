import { LabData } from "../../AppData/LabData";

export class ModelViewerTexture {
    pre = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: any, variant: string) => {}
    post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: any, variant: string) => {}
}

const load_image = (url: URL) => {
    let image = new Image()
    image.crossOrigin = "anonymous"
    image.src = url.href
    return image
};

export const Resources:Record<string,HTMLImageElement|null> = {
    'unisex-joggers-mask': null,
    'blanket_overlay_white': null,
    'blanket_overlay_black': null,
}

export default function useCustomProductTexture(product: number, labData: LabData): ModelViewerTexture {
    var modelViewerTexture = new ModelViewerTexture

    switch(product) {
        case 8: //Pillows
        case 10:
        case 31:
        case 32:
        case 33:
            modelViewerTexture.post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: any, variant: string) => { 
                if (productTextureCanvas == null) return
                let product_texture_context = productTextureCanvas.getContext("2d")
                if (product_texture_context === null) return
                product_texture_context.globalCompositeOperation = "source-over"
                const color = options['color'] || 'designed'
                switch(color) {
                    case "designed": // keep texture
                        break
                    case "printed": // redraw half offset
                        product_texture_context.drawImage(productTextureCanvas, 1024*resolution, 0);
                        break
                    default:
                        let colors = {
                            'white': '#ffffff',
                            'natural-white': '#ffffff',
                            'black': '#050505',
                            'red': '#952122',
                            'navy-blue': '#34375e',
                            'dark-brown': '#49321c',
                            'fuschia': '#a41759',
                            'bronze': '#421c08',
                            'dark-grey': '#5c7587',
                            'deep-purple': '#3f0c34',
                        } as any
                        product_texture_context.fillStyle = colors[color] || colors['white'];
						product_texture_context.fillRect(1024*resolution, 0, 1024*resolution, 1024*resolution);
                }
            }
            break
        case 117: //Blanket
            modelViewerTexture.post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: any, variant: string) => { 
                if (productTextureCanvas == null) return
                let product_texture_context = productTextureCanvas.getContext("2d")
                if (product_texture_context === null) return

                const thread_color = options['thread-color'] ?? 'black'

                if (!Resources['blanket_overlay_white']) Resources['blanket_overlay_white'] = load_image(new URL('@resources/3d-models/textures/blanket_front_white.png', import.meta.url))
                if (!Resources['blanket_overlay_black']) Resources['blanket_overlay_black'] = load_image(new URL('@resources/3d-models/textures/blanket_front_black.png', import.meta.url))

                if (Resources['blanket_overlay_'+thread_color]?.complete && Resources['blanket_overlay_'+thread_color]?.src) {
                    product_texture_context.globalCompositeOperation = "source-over"
                    product_texture_context.drawImage(Resources['blanket_overlay_'+thread_color] as CanvasImageSource, 0, 0, productTextureCanvas.width, productTextureCanvas.height)
                }
            }
            break
        case 122: //Permanent sticker
            modelViewerTexture.post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: any, variant: string) => {
                if (fxTextureCanvas == null) return
                let product_texture_context = fxTextureCanvas.getContext("2d")
                if (product_texture_context === null) return

                product_texture_context.fillStyle = "#FFFFFF"
                product_texture_context.globalCompositeOperation = "source-over"
                product_texture_context.fillRect(0, 0, fxTextureCanvas.width, fxTextureCanvas.height)
                product_texture_context.globalCompositeOperation = "destination-in"
                product_texture_context.drawImage(productTextureCanvas, 0, 0)
            }
            break
        case 126: //Unisex Polo shirt
        case 127: //Fitted Polo shirt
            modelViewerTexture.post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: any, variant: string) => {
                if (fxTextureCanvas == null) return
                let product_texture_context = fxTextureCanvas.getContext("2d")
                if (product_texture_context === null) return

                if (!("color" in options)) return
                const color = (labData.options.color?.layout === 'color' ? labData.options.color?.colors[options.color] : undefined) ?? '#000000';

                product_texture_context.fillStyle = color
                product_texture_context.globalCompositeOperation = "source-over"
                product_texture_context.fillRect(0, 0, fxTextureCanvas.width, fxTextureCanvas.height)
                product_texture_context.drawImage(productTextureCanvas, 0, 0)
                product_texture_context.fillRect(459*resolution, 675*resolution, 130*resolution, 176*resolution)
            }
            break
        case 128: //Unisex Joggers
            modelViewerTexture.post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: any, variant: string) => {
                if (fxTextureCanvas == null) return
                let product_texture_context = fxTextureCanvas.getContext("2d")
                if (product_texture_context === null) return

                if (!("color" in options)) return
                const color = (labData.options.color?.layout === 'color' ? labData.options.color?.colors[options.color] : undefined) ?? '#000000';

                product_texture_context.clearRect(0, 0, fxTextureCanvas.width, fxTextureCanvas.height)    
                product_texture_context.globalCompositeOperation = "source-over"
                product_texture_context.drawImage(productTextureCanvas, 0, 0)

                if(!Resources['unisex-joggers-mask']) Resources['unisex-joggers-mask'] = load_image(new URL('@resources/3d-models/textures/unisex-joggers-mask.png', import.meta.url))
                
                if (Resources['unisex-joggers-mask']?.complete && Resources['unisex-joggers-mask']?.src) {
                    product_texture_context.globalCompositeOperation = "destination-in"
                    product_texture_context.drawImage(Resources['unisex-joggers-mask'] as CanvasImageSource, 0, 0, fxTextureCanvas.width, fxTextureCanvas.height)
                }

                product_texture_context.globalCompositeOperation = "destination-over"
                product_texture_context.fillStyle = color
                product_texture_context.fillRect(0, 0, fxTextureCanvas.width, fxTextureCanvas.height)
            }
            break
        case 129: // Desk Mats
            modelViewerTexture.post = (productTextureCanvas: OffscreenCanvas, fxTextureCanvas: OffscreenCanvas|null, scene: Object, resolution: number, options: any, variant: string) => { 
                if (productTextureCanvas == null) return
                let product_texture_context = productTextureCanvas.getContext("2d")
                if (product_texture_context === null) return

                if (!Resources['desk_mat_9x8_overlay']) Resources['desk_mat_9x8_overlay'] = load_image(new URL('@resources/3d-models/textures/desk_mat_9_overlay.png', import.meta.url))
                if (!Resources['desk_mat_35x16_overlay']) Resources['desk_mat_35x16_overlay'] = load_image(new URL('@resources/3d-models/textures/desk_mat_35_overlay.png', import.meta.url))

                if (Resources['desk_mat_'+variant+'_overlay']?.complete && Resources['desk_mat_'+variant+'_overlay']?.src) {
                    product_texture_context.globalCompositeOperation = "source-over"
                    product_texture_context.drawImage(Resources['desk_mat_'+variant+'_overlay'] as CanvasImageSource, 0, 0, productTextureCanvas.width, productTextureCanvas.height)
                }
            }
            break
    }

    return modelViewerTexture
}