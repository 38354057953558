import { SetFixedViewTime } from '../_actions/ModelViewerStoreActions';
import ModelViewerStore from '../_stores/ModelViewerStore';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

export default function useFixedViewTime(): [ModelViewerStore['fixedViewTime'], (view: ModelViewerStore['fixedViewTime']) => void] {
    const dispatch = useAppDispatch();
    const fixedViewTime = useAppSelector(state => state.get('UIData').get('designLab').get('modelViewer').get('fixedViewTime'))
    return [
        fixedViewTime,
        (time: number) => dispatch(SetFixedViewTime(time)),
    ]
}