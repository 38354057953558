import { CssBaseline, ScopedCssBaseline, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import LabLoader from './LabLoader';
import { useTranslation } from 'react-i18next';

const LabLoaderContainer = styled('div')({
  position: 'absolute',
  background: '#eee',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  transition: 'opacity 300ms',

  '&.active': {
    display: 'flex',
  },

  '&.hidden': {
    opacity: 0,
  }
})

type Props = {
  hide?: boolean
}

export default function FullPageLabLoader(props: Props) {
  const [t] = useTranslation('design-lab');

  const [opened, setOpened] = useState(!props.hide);

  useEffect(() => {
    if (props.hide) {
      setTimeout(() => {
        setOpened(false);
      }, 300);
    }
  }, [props.hide])

  return <LabLoaderContainer className={(opened ? 'active' : '') + (props.hide ? ' hidden' : '')}>
    <Typography
      variant="h2"
      style={{
        marginBottom: '20px',
      }}
    >{t('Loading')}</Typography>
    <LabLoader />
  </LabLoaderContainer>
}