import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

export default function useLabData() {
    const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
    const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));
    const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));

    const fullLabData = productData?.get('labData');

    if(!fullLabData) return undefined;

    if(fullLabData.has_subproducts) {
        return activeSubproduct ? fullLabData.subproducts[activeSubproduct]?.originalData : undefined;
    }

    return fullLabData.originalData;
}