import { styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Renderer from '../../Renderer/Renderer';
import useLabData from '../../_hooks/useLabData';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../../WebsiteStore';
import usePreviewSize from '../../_hooks/usePreviewSize';
import useWindowSize from '../../../Common/_hooks/useWindowSize';
import { LabData } from '../../../AppData/LabData';

const FabricPreviewWrapper = styled('div')(({theme}) => ({
    flexGrow: '1',
    display: 'flex',
    justifyContent: 'center',

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        height: 'calc(100% - 40px)',
        marginTop: '40px',
        alignItems: 'center',
    },
}));

const FabricPreviewContainer = styled('div')(({theme}) => ({
    position: 'relative',
    padding: '80px 80px 0',

    ['@media (max-width: 1510px), (max-height: 1070px)']: {
        padding: '60px 60px 0',
    }
}));

const FabricWidth = styled('div')(({theme}) => ({
    position: 'absolute',
    top: 30,
    left: 80,
    textAlign: 'center',
    '.line': {
        display: 'block',
        height: '2px',
        left: 0,
        right: 0,
        background: '#BCBCBC',
        position: 'absolute',
        top: '30px',
        '.top': {
            background: '#BCBCBC',
            position: 'absolute',
            top: '-4px',
            left: '0',
            width: '2px',
            height: '10px',
            display: 'block',
        },
        '.bottom': {
            background: '#BCBCBC',
            position: 'absolute',
            top: '-4px',
            right: '0',
            width: '2px',
            height: '10px',
            display: 'block',
        }
    },

    ['@media (max-width: 1510px), (max-height: 1070px)']: {
        top: 15,
        left: 60
    }
}));

const FabricLength = styled('div')(({theme}) => ({
    position: 'absolute',
    top: 80,
    left: 30,
    display: 'flex',
    alignItems: 'center',
    'span': {
        transform: 'rotate(-90deg)',
        display: 'block',
    },
    '.line': {
        display: 'block',
        width: '2px',
        top: 0,
        bottom: 0,
        left: 30,
        background: '#BCBCBC',
        position: 'absolute',
        '.top': {
            background: '#BCBCBC',
            position: 'absolute',
            top: '0',
            left: '-4px',
            height: '2px',
            width: '10px',
            display: 'block',
        },
        '.bottom': {
            background: '#BCBCBC',
            position: 'absolute',
            bottom: '0',
            left: '-4px',
            height: '2px',
            width: '10px',
            display: 'block',
        }
    },

    ['@media (max-width: 1510px), (max-height: 1070px)']: {
        top: 60,
        left: 15,
    }
}));

const FabricPreview = styled('div')(({theme}) => ({
    width: '100%',
}));

type Props = {
    labData: LabData,
    variant: string
    inLab: boolean
    textureUrl?: string
    options?:Immutable.Map<string,string>
}

export default function FabricViewer(props:Props) {
    const windowSize = useWindowSize();
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerSize, setContainerSize] = useState({
        width: 1,
        height: 1,
    });

    const [widthIn, heightIn, ratio] = useMemo(() => {
        if(!props.labData?.variants[props.variant]) return [0, 0, 0];

        return [
            props.labData.variants[props.variant].dimensions.render.width/props.labData.dpi,
            props.labData.variants[props.variant].dimensions.render.height/props.labData.dpi,
            props.labData.variants[props.variant].dimensions.render.height / props.labData.variants[props.variant].dimensions.render.width,
        ]
    }, [props.labData, props.variant]);

    const previewSize = useMemo(() => {
        //TODO: This is based on average 3m ratio, but should be modified so we can find this ratio automatically based on data
        //We use a max ratio so that the preview doesn't change width when changing fabric size. Otherwise, it makes the UI kinda weird if it's moving around a lot
        const maxRatio = 2.2;

        let paddingHorizontal = 160;
        let paddingVertical = 110;

        if(windowSize.width <= 1510 || windowSize.height <= 1070) {
            paddingHorizontal = 120;
            paddingVertical = 75;
        }


        let width = containerSize.width - paddingHorizontal;
        let height = containerSize.height - paddingVertical;

        //Only adapt to height in the lab
        if(props.inLab) {
            if(maxRatio > height / width) {
                width = height / maxRatio;    
            }
        }

        return {
            width: width,
            height: height,
        }
    }, [containerSize, windowSize, ratio]);

    const scale = useMemo(() => {
        if(!props.labData?.variants[props.variant]) return 1;

        /*if(ratio > previewSize.width / previewSize.height) {
            return previewSize.height / props.labData.variants[props.variant].dimensions.texture.height;    
        }*/

        return previewSize.width / props.labData.variants[props.variant].dimensions.texture.width;
    }, [props.labData, props.variant, previewSize, ratio]);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            setContainerSize({
                width: entries[0]?.contentRect.width || 0,
                height: entries[0]?.contentRect.height || 0
            });
        });

        if(containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            observer.disconnect();
        }
    }, [containerRef]);

    const [textureImg,setTextureImg] = useState<HTMLImageElement|undefined>(undefined);

    useEffect(() => {
        if(!props.inLab && props.textureUrl) {
            const img = new Image();
            img.onload = () => {
                setTextureImg(img);
            }
            img.src = props.textureUrl;
        }
    }, [props.textureUrl]);

    const textureScale = useMemo(() => {
        if(!props.labData?.variants[props.variant]) return;
        
        return previewSize.width / (props.labData.variants[props.variant].dimensions.render.width / 5);
    }, [props.labData, props.variant, previewSize.width])

    const isRepeat = useMemo(() => {
        return props.options?.get('layout') === 'repeat'
    }, [props.options])

    return <FabricPreviewWrapper ref={containerRef}>
        <FabricPreviewContainer>
            <FabricWidth style={{width: previewSize.width}}>
                <span>{ widthIn+'"' }</span>
                <div className="line">
                    <div className="top"></div>
                    <div className="bottom"></div>
                </div>
            </FabricWidth>
            <FabricLength style={{height: previewSize.width*ratio}}>
                <span>{ heightIn+'"' }</span>
                <div className="line">
                    <div className="top"></div>
                    <div className="bottom"></div>
                </div>
            </FabricLength>
            <FabricPreview>
                { props.inLab ? <Renderer
                    labData={props.labData}
                    inputType="lab"
                    outputType="texture"
                    width={previewSize.width}
                    height={previewSize.width*ratio}
                    scaleX={scale}
                    scaleY={scale}
                    style={{
                        display: 'block',
                    }}
                /> : ( textureImg !== undefined && textureScale !== undefined ? <div style={{
                    width: previewSize.width,
                    height: previewSize.width*ratio,
                    backgroundColor: '#fff',
                    backgroundImage: 'url('+props.textureUrl+')',
                    backgroundSize: (textureImg.width*textureScale)+'px '+(textureImg.height*textureScale)+'px ',
                    backgroundPosition: isRepeat ? 'top left' : 'center center',
                    backgroundRepeat: isRepeat ? 'repeat' : 'no-repeat',
                }}></div> : null ) }
            </FabricPreview>
        </FabricPreviewContainer>
    </FabricPreviewWrapper>
}