import { styled } from "@mui/material";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { ChangeEvent, useCallback } from "react";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { EditLayer } from "../../UIData/_actions/DesignLabStoreActions";
import { useTranslation } from "react-i18next";

const StyledInput = styled('input')({
  background: '#f4f4f4',
  borderRadius: '20px',
  height: '36px',
  fontSize: '14px',
  padding: '0 10px',
  color: '#111',
  width: '100%',
  boxSizing: 'border-box',
  border: 0,
  outline: 0,
  display: 'block',
})

interface Props {
  layerId: string
}

export default function LayerTextInput(props: Props) {
  const [t] = useTranslation('design-lab');
  const dispatch = useAppDispatch()

  const layerText = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId)?.get('text') ?? '');

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    dispatch(EditLayer(props.layerId, {
      text: e.target.value,
      width: 0, //This is used to force recalculating width/height of text
      height: 0,
    }))
  }, [props.layerId])

  return <StyledInput
    placeholder={t('Enter your text here')}
    value={layerText}
    onChange={onChange}
  />
}