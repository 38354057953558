import { Button, Menu, MenuItem, Tooltip, styled } from "@mui/material";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { LAB_FONTS, LAB_TEMPLATE_HEIGHT, LAB_TEMPLATE_WIDTH } from "../../config";
import React, { MouseEvent, useCallback, useState } from "react";
import useCalcFitTemplate from "../_hooks/useCalcFitTemplate";
import DesignLabLayerStore from "../../UIData/_stores/DesignLab/DesignLabLayerStore";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { EditLayer } from "../../UIData/_actions/DesignLabStoreActions";
import useCalcFitToSide from "../_hooks/useCalcFitToSide";
import useSceneData from "../_hooks/useSceneData";
import { PropertyPanelType } from "./PropertyPanel";

const ToolsList = styled('div')({
  position: 'relative',
  background: '#f4f4f4',
  display: 'flex',
  flex: 1,
  width: '100%',
  overflow: 'hidden',
  borderRadius: '0px 0px 15px 15px',
  height: 0,
  opacity: 0,
  transition: 'height 150ms, opacity 150ms, padding 150ms',
  boxSizing: 'border-box',
  padding: 0,

  '&.opened': {
    height: '34px',
    opacity: 1,
  }
})

const TextToolsList = styled(ToolsList)({
  padding: '0 10px',
  gap: '10px',

  '&.opened': {
    padding: '5px 10px',
  },
})

const ToolButton = styled(Button)({
  border: 0,
  outline: 0,
  width: '25%',
  minWidth: 0,
  borderRadius: 0,
  flexShrink: 0,
})

const TextToolButton = styled(Button)({
  borderRadius: '20px',
  width: '50%',
  background: 'rgba(0,0,0,0.1)',
  textTransform: 'none',
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'inherit',
  textAlign: 'left',
  minWidth: 0,
  justifyContent: 'flex-start',
  padding: '0 5px 0 0',
})

const TextToolIcon = styled('div')({
  width: '22px',
  height: '22px',
  borderRadius: '20px',
  marginRight: '5px',
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '8px',
})

const TextToolTitle = styled('div')({
  flex: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  color: '#000',
})

const FontContainer = styled('div')({
  overflow: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '5px',
})

const FontButton = styled(Button)({
  textTransform: 'none',
  background: '#515151',
  borderRadius: '20px',
  fontSize: '12px',
  padding: '0 10px',
  height: '28px',
  color: '#fff',
  width: 'calc(50% - 3px)',
})

interface Props {
  layerId: string
  opened?: boolean
  onOpenPanel: (panel:PropertyPanelType|((panel:PropertyPanelType) => PropertyPanelType)) => void
}

export default function LayerMainTools(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('design-lab');
  const layer = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId));
  const sceneData = useSceneData();
  const [fontAnchorEl, setFontAnchorEl] = useState<null | HTMLElement>(null);

  const calcFitTemplate = useCalcFitTemplate();
  const calcFitToSide = useCalcFitToSide();

  const onClickFitTemplate = useCallback(() => {
    if(!layer) return;
    const data = calcFitTemplate(layer.get('width'), layer.get('height'));

    dispatch(EditLayer(layer.get('id'), data));
  }, [calcFitTemplate, layer]);

  const onClickFitToLeft = useCallback(() => {
    if(!layer) return;
    const data = calcFitToSide(layer.get('width'), layer.get('height'), 'left');

    dispatch(EditLayer(layer.get('id'), data));
  }, [calcFitToSide, layer])

  const onClickFitToRight = useCallback(() => {
    if(!layer) return;
    const data = calcFitToSide(layer.get('width'), layer.get('height'), 'right');

    dispatch(EditLayer(layer.get('id'), data));
  }, [calcFitToSide, layer])

  const onClickFitCenter = useCallback(() => {
    dispatch(EditLayer(props.layerId, {
      x: LAB_TEMPLATE_WIDTH / 2,
      y: LAB_TEMPLATE_HEIGHT / 2,
    }))
  }, [props.layerId])

  const onClickFont = useCallback((e:MouseEvent<HTMLButtonElement>) => {
    setFontAnchorEl(e.currentTarget);
  }, [])

  const onClickColor = useCallback(() => {
    props.onOpenPanel(panel => panel === 'color' ? 'none' : 'color')
  }, [props.onOpenPanel])

  const onCloseFontMenu = useCallback(() => {
    setFontAnchorEl(null)
  }, [])

  const renderFont = useCallback((font:string) => {
    //Remove appended weight
    let name = font;
    if (font.indexOf(':') !== -1) {
      name = font.substring(0, font.indexOf(':'));
    }
    return <MenuItem
      key={font}
      style={{
        fontFamily: name
      }}
      onClick={() => {
        dispatch(EditLayer(props.layerId, {
          font: name,
          width: 0,
          height: 0,
        }));
        setFontAnchorEl(null);
      }}
    >{name}</MenuItem>
  }, [props.layerId])

  if (!layer) return null;

  return <>
    {layer.get('type') === 'img' || layer.get('type') === 'pattern' ? <ToolsList className={props.opened ? 'opened' : ''}>
      {sceneData?.tools.includes('fit_side') ? <Tooltip title={t('Fit to Left Template')} placement="bottom">
        <ToolButton
          onClick={onClickFitToLeft}
        ><img src={require('@resources/img/create/design-lab-v4/icons/fit-left.svg?url')} style={{ height: '22px' }} /></ToolButton>
      </Tooltip> : null}

      {sceneData?.tools.includes('fit_side') ? <Tooltip title={t('Fit to Right Template')} placement="bottom">
        <ToolButton
          onClick={onClickFitToRight}
        ><img src={require('@resources/img/create/design-lab-v4/icons/fit-right.svg?url')} style={{ height: '22px' }} /></ToolButton>
      </Tooltip> : null}

      {sceneData?.tools.includes('fit_center') ? <Tooltip title={t('Fit to Template')} placement="bottom">
        <ToolButton
          onClick={onClickFitTemplate}
        ><img src={require('@resources/img/create/design-lab-v4/icons/fit-template.svg?url')} style={{ height: '18px' }} /></ToolButton>
      </Tooltip> : null}

      {sceneData?.tools.includes('fit_center') ? <Tooltip title={t('Center')} placement="bottom"><ToolButton
        onClick={onClickFitCenter}
      ><img src={require('@resources/img/create/design-lab-v4/icons/center.svg?url')} style={{ height: '22px' }} /></ToolButton>
      </Tooltip> : null}

      { /* <ToolButton
                    onClick={() => {
                        dispatch(EditLayer(layer.get('id'), {
                            rotation: layer.get('rotation') - 90
                        }))
                    }}
                ><img src={require('@resources/img/create/design-lab-v4/icons/rotate-right.svg?url')} style={{height: '22px'}}/></ToolButton>
                <ToolButton
                    onClick={() => {
                        dispatch(EditLayer(layer.get('id'), {
                            rotation: layer.get('rotation') + 90
                        }))
                    }}
                ><img src={require('@resources/img/create/design-lab-v4/icons/rotate-left.svg?url')} style={{height: '22px'}}/></ToolButton> */ }
    </ToolsList> : null}

    {layer.get('type') === 'text' ? <TextToolsList className={props.opened ? 'opened' : ''}>
      <TextToolButton
        onClick={onClickColor}
      >
        <TextToolIcon style={{
          backgroundColor: layer.get('color')
        }} />
        <TextToolTitle>{layer.get('color')}</TextToolTitle>
      </TextToolButton>
      <TextToolButton
        onClick={onClickFont}
      >
        <TextToolIcon>
          <img src={require('@resources/img/create/design-lab-v4/icons/text-font.svg?url')} style={{ height: '14px' }} />
        </TextToolIcon>
        <TextToolTitle>{layer.get('font')}</TextToolTitle>
      </TextToolButton>
      <Menu
        anchorEl={fontAnchorEl}
        open={Boolean(fontAnchorEl)}
        onClose={onCloseFontMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {LAB_FONTS.map(renderFont)}
      </Menu>
    </TextToolsList> : null}
  </>
}