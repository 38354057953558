import { Component, Suspense, useMemo } from 'react';
import { Navigate, Outlet, RouteObject, RouterProvider, ScrollRestoration, createBrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import DesignLab from './DesignLab/_components/DesignLab';
import ProductDesignList from './Account/Products/ProductDesignList';
import ImageList from './Account/Images/ImageList';
import AddressList from './Account/Settings/Addresses/AddressList';
import CreditCardList from './Account/Settings/CreditCards/CreditCardList';
import TaxNumberList from './Account/Settings/TaxNumbers/TaxNumberList';
import Account from './Account/Account';
import ApplicationDataLayer from './AppData/_components/ApplicationDataLayer';
import CartOverview from './Cart/Overview/CartOverview';
import CartShipping from './Cart/Shipping/CartShipping';
import CartPayment from './Cart/Payment/CartPayment';
import Cart from './Cart/Cart';
import { Helmet } from 'react-helmet-async';
import i18next, { TFunction } from 'i18next';
import CartConfirmation from './Cart/Confirmation/CartConfirmation';
import DevPlayground from './Dev/DevPlayground';
import ProductsPageContent from './ProductsPage/ProductsPageContent';
import { CssBaseline } from '@mui/material';
import Security from './Account/Settings/Security';
import Info from './Account/Settings/Info';
import Notifications from './Account/Settings/Notifications';
import OrderList from './Account/Orders/OrderList';
import OrderPage from './Account/Orders/OrderPage';
import BrandingOptionList from './Account/BrandingOptions/BrandingOptionList';
import DashboardOrderList from './Dashboard/DashboardOrderList';
import DashboardOrderPage from './Dashboard/DashboardOrderPage';
import SamplePacks from './Info/SamplePacks';
import InvoicePage from './Invoice/InvoicePage';
import AccountNotValidated from './Auth/AccountNotValidated';
import LoginPage from './Auth/LoginPage';
import SignupPage from './Auth/SignupPage';
import SignedUpPage from './Auth/SignedUpPage';
import ForgotPasswordPage from './Auth/ForgotPasswordPage';
import ResetPasswordPage from './Auth/ResetPasswordPage';
import AccountValidated from './Auth/AccountValidated';
import PageLoader from './Common/Loader/PageLoader';
import OrderPageOverview from './Account/Orders/OrderPageOverview';
import OrderPageShipping from './Account/Orders/OrderPageShipping';
import OrderPagePayment from './Account/Orders/OrderPagePayment';
import InvoicePageOverview from './Invoice/InvoicePageOverview';
import InvoicePagePayment from './Invoice/InvoicePagePayment';
import DashboardOrderPageOverview from './Dashboard/DashboardOrderPageOverview';
import DashboardOrderPageShipping from './Dashboard/DashboardOrderPageShipping';
import DashboardOrderPagePayment from './Dashboard/DashboardOrderPagePayment';
import PhotoLabCreationList from './Account/PhotoLab/PhotoLabCreationList';
import YogaShortsPage from './Product/Bottoms/Shorts/YogaShortsPage';
import GlitterYogaShortsPage from './Product/Bottoms/Shorts/GlitterYogaShortsPage';
import GlitterAthleticCropTopPage from './Product/Tops/TankTops/GlitterAthleticCropTopPage';
import GlitterFlareSkirtPage from './Product/Bottoms/Skirts/GlitterFlareSkirtPage';
import GlitterFittedSkirtPage from './Product/Bottoms/Skirts/GlitterFittedSkirtPage';
import PermanentStickerPage from './Product/Stationery/PermanentStickerPage';
import AccountLink from './Auth/AccountLink';
import VeganLeatherBackpackPage from './Product/BagsAndTotes/VeganLeatherBackpackPage';
import CheekyBriefsPage from './Product/Underwear/CheekyBriefsPage';
import BoyshortsPage from './Product/Underwear/BoyshortsPage';
import BoxerBriefsPage from './Product/Underwear/BoxerBriefsPage';
import ScoopBralettePage from './Product/Underwear/ScoopBralettePage';
import DrawstringBagPage from './Product/BagsAndTotes/DrawstringBagPage';
import StainlessSteelTumblerPage from './Product/HomeDecor/BottlesAndTumblers/StainlessSteelTumblerPage';
import CanvasPrintPage from './Product/HomeDecor/WallArt/CanvasPrintPage';
import UnisexPoloShirtPage from './Product/Tops/TShirts/UnisexPoloShirtPage';
import FittedPoloShirtPage from './Product/Tops/TShirts/FittedPoloShirtPage';
import UnisexJoggersPage from './Product/Bottoms/LeggingsAndPants/UnisexJoggersPage';
import DeskMatPage from './Product/HomeDecor/Office/DeskMatPage';

export const WebsiteRoutes = (tR:TFunction) => [
    { 
        path: '/',
        Component: Website,
        errorElement: process.env.NODE_ENV === 'production' ? <></> : undefined,
        children: [
            { path: tR('/products/bottoms/shorts/yoga-shorts'), Component: YogaShortsPage },
            { path: tR('/products/bottoms/shorts/glitter-yoga-shorts'), Component: GlitterYogaShortsPage },
            { path: tR('/products/tops/tank-tops/glitter-athletic-crop-tops'), Component: GlitterAthleticCropTopPage },
            { path: tR('/products/tops/tshirts/unisex-polo-shirts'), Component: UnisexPoloShirtPage },
            { path: tR('/products/tops/tshirts/fitted-polo-shirts'), Component: FittedPoloShirtPage },
            { path: tR('/products/bottoms/skirts/glitter-fitted-skirts'), Component: GlitterFittedSkirtPage },
            { path: tR('/products/bottoms/skirts/glitter-flare-skirts'), Component: GlitterFlareSkirtPage },
            { path: tR('/products/stationery/permanent-stickers'), Component: PermanentStickerPage },
            { path: tR('/products/bags-and-totes/vegan-leather-backpacks'), Component: VeganLeatherBackpackPage},
            { path: tR('/products/underwear/cheeky-briefs'), Component: CheekyBriefsPage},
            { path: tR('/products/underwear/boyshorts'), Component: BoyshortsPage},
            { path: tR('/products/underwear/boxer-briefs'), Component: BoxerBriefsPage},
            { path: tR('/products/underwear/scoop-bralettes'), Component: ScoopBralettePage},
            { path: tR('/products/bags-and-totes/drawstring-bags'), Component: DrawstringBagPage},
            { path: tR('/products/home-decor/bottles-and-tumblers/stainless-steel-tumblers'), Component: StainlessSteelTumblerPage},
            { path: tR('/products/home-decor/wall-art/canvases'), Component: CanvasPrintPage},
            { path: tR('/products/home-decor/office/desk-mats'), Component: DeskMatPage},
            { path: tR('/products/bottoms/leggings-and-pants/unisex-joggers'), Component: UnisexJoggersPage},
            { path: tR('/products/:category1?/:category2?'), Component: ProductsPageContent },
            { path: tR('/create/:product?'), Component: DesignLab },
            { 
                path: tR('/account'),
                Component: Account,
                children: [
                    { path: tR('/account'), element: <Navigate to={tR('/account/products')} /> },
                    { path: tR('/account/products'), Component: ProductDesignList },
                    { path: tR('/account/images'), Component: ImageList },
                    { path: tR('/account/orders'), Component: OrderList },
                    { 
                        path: tR('/account/orders/:refId'), 
                        Component: OrderPage,
                        children: [
                            { path: tR('/account/orders/:refId/'), Component: OrderPageOverview },
                            { path: tR('/account/orders/:refId/shipping'), Component: OrderPageShipping },
                            { path: tR('/account/orders/:refId/payment'), Component: OrderPagePayment }
                        ]
                    },
                    { path: tR('/account/branding-options'), Component: BrandingOptionList },
                    { path: tR('/account/photo-lab'), Component: PhotoLabCreationList },
                    { path: tR('/account/settings/addresses'), Component: AddressList },
                    { path: tR('/account/settings/credit-cards'), Component: CreditCardList },
                    { path: tR('/account/settings/tax-numbers'), Component: TaxNumberList },
                    { path: tR('/account/settings/security'), Component: Security },
                    { path: tR('/account/settings/info'), Component: Info },
                    { path: tR('/account/settings/notifications'), Component: Notifications },
                ]
            },
            { 
                path: tR('/cart'),
                Component: Cart,
                children: [
                    { path: tR('/cart'), Component: CartOverview },
                    { path: tR('/cart/shipping'), Component: CartShipping },
                    { path: tR('/cart/payment'), Component: CartPayment },
                ]
            },
            { path: tR('/cart/confirmation'), Component: CartConfirmation },
            { path: tR('/d/:storeSlug/orders'), Component: DashboardOrderList },
            { 
                path: tR('/d/:storeSlug/orders/:refId'),
                Component: DashboardOrderPage,
                children: [
                    { path: tR('/d/:storeSlug/orders/:refId/'), Component: DashboardOrderPageOverview },
                    { path: tR('/d/:storeSlug/orders/:refId/shipping'), Component: DashboardOrderPageShipping },
                    { path: tR('/d/:storeSlug/orders/:refId/payment'), Component: DashboardOrderPagePayment }
                ]
            },
            { path: tR('/info/sample-packs'), Component: SamplePacks },
            { 
                path: tR('/invoice/:invoiceId'), 
                Component: InvoicePage,
                children: [
                    { path: tR('/invoice/:invoiceId/'), Component: InvoicePageOverview },
                    { path: tR('/invoice/:invoiceId/payment'), Component: InvoicePagePayment }
                ]
            },
            { path: tR('/auth/login'), Component: LoginPage },
            { path: tR('/auth/signup'), Component: SignupPage },
            { path: tR('/auth/signed-up'), Component: SignedUpPage },
            { path: tR('/auth/forgot-password'), Component: ForgotPasswordPage },
            { path: tR('/auth/reset-password'), Component: ResetPasswordPage },
            { path: tR('/auth/validate-account'), Component: AccountValidated },
            { path: tR('/auth/account-not-validated'), Component: AccountNotValidated },
            { path: tR('/auth/signup-confirm-account-link'), Component: AccountLink },
            //{ path: tR('/dev-playground'), Component: DevPlayground },
        ]
    },
]

export default function Website() {
    return <ApplicationDataLayer>
        <Helmet
			titleTemplate="%s | Art of Where"
			defaultTitle="Art of Where | Drop ship custom leggings, scarves and more"
		>

        </Helmet>
        <CssBaseline>
            <Suspense fallback={<PageLoader/>}>
                <Outlet/>
            </Suspense>
            <ScrollRestoration/>
        </CssBaseline>
    </ApplicationDataLayer>
}