import { Tooltip } from "@mui/material";
import { SideButton } from "./Layer";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { AddImgLayer, AddPatternLayer, AddTextLayer } from "../../UIData/_actions/DesignLabStoreActions";

interface Props {
  layerId: string
}

export default function DuplicateButton(props:Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('design-lab');

  const layer = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId));
  const isSelected = useAppSelector(state => state.get('UIData').get('designLab').get('selectedLayer') === props.layerId);

  const duplicateLayer = useCallback(() => {
    if (!layer) return;

    if (layer.get('type') === 'img') {
      dispatch(AddImgLayer({
        ...layer.toObject(),
        x: layer.get('x') + 20,
        y: layer.get('y') + 20,
      }))
    } else if (layer.get('type') === 'pattern') {
      dispatch(AddPatternLayer({
        ...layer.toObject(),
        x: layer.get('x') + 20,
        y: layer.get('y') + 20,
      }))
    } else if (layer.get('type') === 'text') {
      dispatch(AddTextLayer({
        ...layer.toObject(),
        x: layer.get('x') + 20,
        y: layer.get('y') + 20,
      }))
    }
  }, [layer]);

  useHotkeys('ctrl+j', (e) => {
    if (!isSelected) return;
    e.preventDefault();

    duplicateLayer();
  }, {
    scopes: ['design-lab']
  }, [duplicateLayer, isSelected]);

  return <Tooltip title={t('Duplicate')} placement="right">
    <SideButton
      color="secondary"
      className={"btn-duplicate"}
      onClick={duplicateLayer}
    >
      <img src={require('@resources/img/create/design-lab-v4/icons/duplicate.svg?url')} style={{ height: '18px' }} />
    </SideButton>
  </Tooltip>
}