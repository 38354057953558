import { Button, Grid, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { OpenAuthModal } from '../Auth/_actions/AuthActions'
import { useAppDispatch } from '../Common/_hooks/useAppDispatch'
import CartGridContainer from './CartContent/CartGridContainer'
import { CartHeaderH1 } from './CartContent/CartHeader'
import CartListGrid from './CartContent/CartListGrid'
import { CartStack } from './CartContent/CartStack'
import { useCallback } from 'react'
import { useAppSelector } from '../Common/_hooks/useAppSelector'

export default function EmptyCart() {
  const [t] = useTranslation('cart')
  const [tR] = useTranslation('routes')
  const dispatch = useAppDispatch()

  const loggedIn = useAppSelector(state => state.get('userData').get('loggedIn'))

  const onOpenLogin = useCallback(() => {
    dispatch(OpenAuthModal(false))
  }, [])

  const onOpenSignup = useCallback(() => {
    dispatch(OpenAuthModal(true))
  }, [])

  return <Stack spacing={2}>
    <CartHeaderH1><span className="title">{t('Cart')}</span></CartHeaderH1>
    <div>
      <CartGridContainer>
        <CartListGrid xs sm md lg xl>
          <Paper
            elevation={0}
            style={{
              minHeight: '400px',
              height: '100%'
            }}
          >
            <CartStack
              spacing={2}
              style={{
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant="h3"
                style={{
                  fontSize: '2rem',
                  textAlign: 'center'
                }}
              >{t('You have nothing in your cart')}</Typography>
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'row'
                }}
                spacing={2}
              >
                <Button
                  color="accent"
                  variant="contained"
                  size="large"
                  href={tR('/create')}
                >{t('Create a product')}</Button>
                {loggedIn ? <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  component={RouterLink}
                  to={tR('/account/products')}
                >{t('Go to Account')}</Button> : null}
              </Stack>
            </CartStack>
          </Paper>
        </CartListGrid>
        { !loggedIn ? <Grid item xs={12} md={4} lg={3}>
          <Paper
            elevation={0}
            style={{
              backgroundImage: 'url(' + require('@resources/img/theme/dialog/ornaments-shapes-2.svg?url') + ')',
              backgroundPosition: 'right top',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '80%'
            }}
          >
            <CartStack spacing={4}>
              <Stack spacing={2}>
                <Typography
                  variant="h2"
                  style={{
                    fontSize: '2.5rem'
                  }}
                >{t('Everything\'s easier with an account')}</Typography>
                <Typography variant="body1">{t('Benefit from our Goals & Rewards program, brand your products and packaging for reselling, integrate your own Etsy, Shopify or Squarespace store.')}</Typography>
              </Stack>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onOpenLogin}
                >{t('Login')}</Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={onOpenSignup}
                >{t('Sign up')}</Button>
              </Stack>
            </CartStack>
          </Paper>
        </Grid> : null }
      </CartGridContainer>
    </div>
  </Stack>
}
