import { useCallback, useContext, useEffect } from "react"
import { Color, MeshStandardMaterial, Vector2 } from "three"
import { FindMaterial, FindObject } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function Canvas(props: ProductModelProps) {
    /* Gotta load all the models this way because dynamic paths in URL() aren't picked up by webpack */
    const getUrl = useCallback(() => {
        return {
            '1-1': new URL('@resources/3d-models/glb/canvas-12x12.glb', import.meta.url),
            '2-3': new URL('@resources/3d-models/glb/canvas-12x18.glb', import.meta.url),
            '3-4': new URL('@resources/3d-models/glb/canvas-12x16.glb', import.meta.url),
            '3-2': new URL('@resources/3d-models/glb/canvas-18x12.glb', import.meta.url),
            '4-3': new URL('@resources/3d-models/glb/canvas-16x12.glb', import.meta.url),
            '11x14': new URL('@resources/3d-models/glb/canvas-11x14.glb', import.meta.url),
            '12x12': new URL('@resources/3d-models/glb/canvas-12x12.glb', import.meta.url),
            '12x16': new URL('@resources/3d-models/glb/canvas-12x16.glb', import.meta.url),
            '12x18': new URL('@resources/3d-models/glb/canvas-12x18.glb', import.meta.url),
            '14x11': new URL('@resources/3d-models/glb/canvas-14x11.glb', import.meta.url),
            '16x12': new URL('@resources/3d-models/glb/canvas-16x12.glb', import.meta.url),
            '16x16': new URL('@resources/3d-models/glb/canvas-16x16.glb', import.meta.url),
            '16x20': new URL('@resources/3d-models/glb/canvas-16x20.glb', import.meta.url),
            '18x12': new URL('@resources/3d-models/glb/canvas-18x12.glb', import.meta.url),
            '20x16': new URL('@resources/3d-models/glb/canvas-20x16.glb', import.meta.url),
        }[props.variant]
    }, [props.variant])

    const model = useGLB(getUrl() || new URL(''), props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    const normalScale = new Vector2(0.2, -0.2)

    useEffect(() => {
        if(!elements) return
        let mat: MeshStandardMaterial | null

        mat = FindMaterial('canvas design', elements)
        if (mat) {
            mat.normalScale = normalScale
            mat.transparent = true
            mat.opacity = 0.98
        }

        mat = FindMaterial('canvas', elements)
        if (mat) {
            mat.normalScale = normalScale
        }

        mat = FindMaterial('canvas back', elements)
        if (mat) {
            mat.normalScale = normalScale
            mat.side = 0
            mat.transparent = true
            mat.opacity = 0.8
        }

        mat = FindMaterial('pad', elements)
        if (mat) {
            mat.opacity = 0.4
        }

    }, [elements])

    // Update frame
    const option_frame = props.options.get('canvas-frame')
    useEffect(() => {
        var frame_obj = FindObject("frame", elements)
        var hook_obj = FindObject("hook", elements)
        var staples_frame_obj = FindObject("staples_frame", elements)
        var pads_obj = FindObject("pads", elements)
        if(!frame_obj || !hook_obj || !staples_frame_obj || !pads_obj) return
        switch(option_frame){
            case 'none':
                frame_obj.visible = false
                hook_obj.visible = false
                staples_frame_obj.visible = false
                pads_obj.visible = false
                break;
            case 'black':
                frame_obj.visible = true
                frame_obj.material.color = new Color(0x181818)
                hook_obj.visible = true
                staples_frame_obj.visible = true
                pads_obj.visible = true
                break;
            case 'white':
            default:
                frame_obj.visible = true
                frame_obj.material.color = new Color(0xFAFAFA)
                hook_obj.visible = true
                staples_frame_obj.visible = true
                pads_obj.visible = true
                break;
        }
    }, [elements, option_frame])

    // Apply lab texture
    useEffect(() => {
        if(!elements) return
        let mat = FindMaterial('canvas design', elements)
        if (mat) {
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <>
        <ModelElements elements={elements} forceUpdate={props.variant+option_frame}/>
    </>
}