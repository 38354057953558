import { Box, Container, Stack, styled } from "@mui/material";
import UserNav from "./UserNav";
import MobileNav from "./MobileNav";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DesktopNav from "./DesktopNav";
import Toast from "../UIData/_components/Toast";
import CookieConsent from "./CookieConsent";

const FullLogo = styled('img')(({theme}) => ({
  display: 'block',

  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

const SmallLogo = styled('img')(({theme}) => ({
  display: 'none',
  
  [theme.breakpoints.down('md')]: {
    display: 'block'
  }
}))

export default function Header() {
  const [tR] = useTranslation('routes')

  return <>
    <Box 
      component="div"
      sx={{
        backgroundColor: 'secondary.main',
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 1300,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          pl: {
            xs: 0,
            md: 3,
            lg: 4
          },
          pr: {
            xs: 0,
            md: 1,
            lg: 2
          },
        }}
      >
        <Stack 
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          sx={{
            height: {
              xs: '52px',
              md: '64px',
            }
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1} useFlexGap>
            <MobileNav/>
            <Link to={tR('/')} reloadDocument>
              <FullLogo 
                src={require('@resources/img/logo-new-nav-white.svg?url')}
                width="89"
                height="42"
                alt="Art of Where"
              />
              <SmallLogo 
                src={require('@resources/img/logo-small.svg?url')}
                width="106"
                height="30"
                alt="Art of Where"
              />
            </Link>
          </Stack>
          <UserNav/>
        </Stack>
      </Container>
    </Box>

    <DesktopNav/>

    <Toast/>

    <CookieConsent/>
  </>
}