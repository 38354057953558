import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '../Common/Dialog/Dialog';
import DialogContent from '../Common/Dialog/DialogContent';
import DialogTitle from '../Common/Dialog/DialogTitle';
import { CloseAuthModal, OpenAuthModal } from './_actions/AuthActions';
import { useAppDispatch } from '../Common/_hooks/useAppDispatch';
import { useLocation } from 'react-router-dom';
import AuthForm from './AuthForm';
import { useAppSelector } from '../Common/_hooks/useAppSelector';

export default function AuthDialog() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('auth')
  const location = useLocation()

  const opened = useAppSelector(state => state.get('Auth').get('modalOpened'));
  const signup = useAppSelector(state => state.get('Auth').get('isSignup'));

  const onClose = useCallback(() => {
    dispatch(CloseAuthModal())
  }, [])

  return <>
    <Dialog
      open={opened}
      showCloseButton
      maxWidth="md"
      fullWidth
      onClose={onClose}
      ornaments="shapes-3"
    >
      <DialogTitle
        sx={{
          textAlign: {
            xs: 'left',
            sm: 'center',
          },
          fontSize: '3.25rem'
        }}
      >{signup ? t('Create an account') : t('Login')}</DialogTitle>

      <DialogContent>
        <AuthForm
          signup={signup}
          redirect={location.pathname + location.search}
        />
      </DialogContent>
    </Dialog>
  </>;
}