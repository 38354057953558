import { useContext, useEffect } from "react"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"
import { MeshPhysicalMaterial } from "three"

export default function StainlessSteelTumbler(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/stainless-steel-tumbler.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)
    const option_finish = props.options.get('finish') ?? 'matte'
    
    useEffect(() => {
        if(!elements || !elements[5]?.material || !(elements[5].material instanceof MeshPhysicalMaterial)) return

        if(option_finish === 'glossy') {
            elements[5].material.ior = 2.3;
            elements[5].material.specularIntensity = 0.6;
            elements[5].material.roughness = 0.15;
            elements[5].material.metalness = 0;
        } else {
            elements[5].material.ior = 1.4;
            elements[5].material.specularIntensity = 0.4;
            elements[5].material.roughness = 0.4;
            elements[5].material.metalness = 0.1;
        }
        
    }, [option_finish])

    useEffect(() => {
        if(!elements || !elements[0]?.material || !(elements[0].material instanceof MeshPhysicalMaterial)) return

        elements[0].material.depthWrite = true;
        elements[0].material.transmission = 1.0;
        elements[0].material.roughness = 0.05;
        elements[0].material.metalness = 0;
        elements[0].material.thickness = 1;
        elements[0].material.ior = 1.1;
        elements[0].material.reflectivity = 0.2;
        //elements[0].material.alphaMap = elements[0].material.map;
        elements[0].material.map = null;
    }, [elements])

    // Apply lab texture
    useEffect(() => {
        if(!elements || !elements[5]?.material) return

        elements[5].material.map = productTexture
        elements[5].material.map.flipY = false
        elements[5].material.map.needsUpdate = true
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}