import { SetFixedView } from '../_actions/ModelViewerStoreActions';
import ModelViewerStore from '../_stores/ModelViewerStore';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { useCallback } from 'react';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

export default function useFixedView(): [ModelViewerStore['fixedView'], (view: ModelViewerStore['fixedView']) => void] {
    const dispatch = useAppDispatch();
    const fixedView = useAppSelector(state => state.get('UIData').get('designLab').get('modelViewer').get('fixedView'))

    const setFixedView = useCallback((view: ModelViewerStore['fixedView']) => dispatch(SetFixedView(view)), [])

    return [
        fixedView,
        setFixedView,
    ]
}