import { useTranslation } from 'react-i18next';
import ViewProductStore from '../AppData/_stores/ViewProductStore';
import { ColoredText } from '../Common/_components/Typography';
import { Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import ProductCardImage from './ProductCardImage';
import ErrorBoundary from '../Common/_components/ErrorBoundary';
import { useAppSelector } from '../Common/_hooks/useAppSelector';
import { useMemo } from 'react';

export type Props = {
  product: ViewProductStore
}

const CardBorder = styled('div')({
  boxSizing: 'border-box',
  background: '#FFFFFF',
  height: '100%',
  transition: 'all 350ms',
  borderRadius: '15px',

  'a:hover': {
    textDecoration: 'none',
  }
});

const ImageContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '100%',
  background: '#e5e5e5',

  'img': {
    width: '100%',
    maxWidth: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
  }
});

const BoldTitle = styled('div')({
  fontWeight: 500,
  fontSize: '18px',
  color: '#333333',
});

const ColoredTextSmol = styled(ColoredText)({
  fontSize: '12px',
  color: '#000',

  '&.product-startingat': {
    whiteSpace: 'nowrap',
  }
});

const ProductDescription = styled('div')({
  color: '#000',
});

const ProductPrice = styled('div')({
  position: 'absolute',
  bottom: '15%',
  right: '0',
  backgroundColor: '#fff',
  padding: '10px 20px 10px 30px',
  borderRadius: '50px 0 0 50px',
  textAlign: 'right',

  '.product-startingat, .product-price': {
    transition: 'font-size 250ms',
  }
});


const NewTag = styled('div')(({ theme }) => ({
  color: '#fff',
  backgroundColor: theme.palette.accent.main,
  zIndex: 9,
  padding: '4px 15px 4px 12px',
  position: 'absolute',
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  margin: 'auto',
  borderRadius: '0 0 10px 10px',
  textTransform: 'uppercase',

  'img': {
    width: '20px',
    marginRight: '5px',
  }

}));

const PrintTypeTag = styled('div')(({ theme }) => ({
  color: theme.palette.accent.main,
  textTransform: 'uppercase',
  fontSize: '14px',
  fontWeight: '600',
  borderRadius: '20px',
  display: 'inline-flex',
  alignItems: 'flex-start',

  'img': {
    width: '20px',
    marginRight: '5px',
  }
}));


export default function ProductCard(props: Props) {
  const [t] = useTranslation('products');
  const [tR] = useTranslation('routes');
  const viewProductAttributeTypes = useAppSelector(state => state.get('appData').get('viewProductAttributeTypes'));
  const viewProductAttributeValues = useAppSelector(state => state.get('appData').get('viewProductAttributeValues'));

  const printType = useMemo(() => {
    const printTypeAttrType = viewProductAttributeTypes.find(type => type.get('slug') === 'print-type')
    if (!printTypeAttrType) return

    const valueId = props.product.get('listAttributes').get(String(printTypeAttrType.get('id')))
    if (!valueId) return

    return viewProductAttributeValues.get(String(valueId))
  }, [props.product.get('listAttributes')])

  return <CardBorder className="productcard-wrapper">
    <Link
      to={tR('/products') + '/' + props.product.get('url_slug')}
      reloadDocument={!props.product.get('is_react_page')}
      style={{
        display: 'block',
        height: '100%',
        position: 'relative',
      }}
    >
      {props.product.get('is_new') ? <NewTag>
        <img src={require('@resources/img/all-products/new-icon.svg?url')} alt={t('Megaphone Icon')} />
        {t('New')}
      </NewTag> : null}
      <ImageContainer className="imagecontainer">
        <ErrorBoundary fallback={<></>}>
          <ProductCardImage
            slug={props.product.get('slug')}
            name={props.product.get('name')}
          />
        </ErrorBoundary>
        <ProductPrice className="product-pricewrapper">
          <ColoredTextSmol className="product-startingat" color='#B2B2B2'>{t('Starting at')}</ColoredTextSmol>
          <BoldTitle className="product-price">{props.product.get('price').toDinero().toFormat()}</BoldTitle>
        </ProductPrice>
      </ImageContainer>
      <Stack spacing={0.5} sx={{ p: 2 }}>
        {printType !== undefined ? <PrintTypeTag className="print-type-tag">
          <img src={require('@resources/img/all-products/filters/' + printType.get('slug') + '.svg?url')} alt={t('All-over print Icon')} />
          {printType.get('name')}
        </PrintTypeTag> : null}
        <Stack spacing={1}>
          <BoldTitle className="product-name">{props.product.get('name')}</BoldTitle>

          <ProductDescription className="product-description">
            {props.product.get('description')}
          </ProductDescription>
        </Stack>
      </Stack>
    </Link>
  </CardBorder>
}