import { IconButton } from "@mui/material";
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { GetProductLabData } from "../../UIData/_actions/DesignLabActions";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";

const DEV_MODE = false;

export default function LabDevTool() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('design-lab')

  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));

  return DEV_MODE ? <IconButton
    style={{
      color: '#fff'
    }}
    onClick={() => {
      if (!productData) return;
      dispatch(GetProductLabData(productData.get('slug')));
    }}
    title={t('Refresh Lab Data')}
  >
    <RefreshIcon />
  </IconButton> : null
}