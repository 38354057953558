import Immutable from 'immutable';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import LabOptionTool, { LabOptionValue } from './LabOptionTool';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import { LabOptionData } from '../../AppData/LabData';

type Props = {
    option: string,
    optionData: LabOptionData
}

export default function DetailTool(props:Props) {
    const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
    const detail = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId))?.get('details').get(props.option));

    const listValues = useMemo(() => {
        let tmpListValues = Immutable.OrderedMap<string, LabOptionValue>()
        
        detail?.get('listValues').forEach(value => {
            tmpListValues = tmpListValues.set(value.get('slug'), new LabOptionValue({
                name: value.get('name'),
                slug: value.get('slug'),
                color: value.get('data')?.get('color'),
            }))
        })

        return tmpListValues
    }, [detail]);

    if(!detail) return null;

    return <LabOptionTool
        type="detail"
        layout={props.optionData.layout ?? 'color'}
        name={detail.get('name')}
        option={props.option}
        listValues={listValues}
    />
}