import { Paper, TextField, Typography, styled } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Point from '../../Common/Utils/Math/Point';
import { EditActiveDesign, EditDesignName } from '../../UIData/_actions/DesignLabStoreActions';
import { UserProductDesignStore } from '../../UserData/_stores/UserProductDesignStore';
import SaveDialog from '../SaveDialog/_components/SaveDialog';
import useLabData from '../_hooks/useLabData';
import { SaveState } from '../_components/DesignLab';
import OptionSelectors from '../OptionSelector/OptionSelectors';
import SaveButton from './SaveButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import ValidIndicatorSection from './ValidIndicatorSection';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import useRenderingTrace from '../../Common/_hooks/useRenderingTrace';

const CustomizeContainer = styled('div')({
    flex: 1,
    padding: '20px',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.15)',

    '@media (max-width: 1510px), (max-height: 1070px)': {
        padding: '15px',
    }
})

const SaveBarContainer = styled(Paper)(({theme}) => ({
    margin: '0 30px',
    backgroundColor: '#fff',
    borderRadius: '20px 20px 0 0',
    display: 'flex',
    flexDirection: 'column',

    ['@media (max-width: 1510px), (max-height: 1070px)']: {
        margin: '0 15px',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        ['@media (max-height: '+theme.height_breakpoints.values.lab_mobile+'px) and (min-width: 700px)']: {
            borderRadius: '20px 0 0 20px',
            marginRight: 0,
        }
    },
}))

const BottomSection = styled('div')(({theme}) => ({
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '@media (max-width: 1510px) and (max-height: 1070px)': {
        flexDirection: 'column',
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        ['@media (min-width: 500px)']: {
            flexDirection: 'row',    
        },
        ['@media (max-height: '+theme.height_breakpoints.values.lab_mobile+'px) and (min-width: 700px)']: {
            flexDirection: 'column',    
        }
    },
}));

const SaveButtonContainer = styled('div')(({theme}) => ({
    padding: '20px',
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    '@media (max-width: 1510px) and (max-height: 1070px)': {
        border: 0,
    },

    ['@media (max-width: '+theme.breakpoints.values.lab_mobile+'px), (max-height: '+theme.height_breakpoints.values.lab_mobile+'px)']: {
        ['@media (min-width: 500px)']: {
            borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
        },
        ['@media (max-height: '+theme.height_breakpoints.values.lab_mobile+'px) and (min-width: 700px)']: {
            border: 0,
        }
    },
}));

export default function SaveBar() {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [t] = useTranslation('design-lab');
    const activeDesignId = useAppSelector(state => state.get('UIData').get('designLab').get('activeDesignId'));
    const activeOptions = useAppSelector(state => state.get('UIData').get('designLab').get('activeOptions'));
    const resolutionOk = useAppSelector(state => state.get('UIData').get('designLab').get('warnings').get('resolution').reduce((reduction, value) => value && reduction, true));
    const templatesCoveredOk = useAppSelector(state => state.get('UIData').get('designLab').get('warnings').get('templates_covered').reduce((reduction, value) => value && reduction, true));

    const designName = useAppSelector(state => state.get('UIData').get('designLab').get('designName'));
    const labData = useLabData();

    const [saveState, setSaveState] = useState<SaveState>('none');
    const [similarProducts, setSimilarProducts] = useState([]);
    const [savedProductDesign, setSavedProductDesign] = useState<UserProductDesignStore|null>(null);

    //Update activeDesignId + url if editing a design
    useEffect(() => {
        if(!activeDesignId || !savedProductDesign) return;
        dispatch(EditActiveDesign(savedProductDesign.get('id')));
        setSearchParams({
            design: savedProductDesign.get('id')
        });
    }, [savedProductDesign])

    const optionsOk = useMemo(() => {
        let ok = true;
        
        if(!labData?.options) {
            return ok;
        }
        
        Object.entries(labData.options).forEach(([option, optionData]:[string,unknown]) => {
            ok = ok && Boolean(activeOptions.get(option))
        });

        return ok;
    }, [activeOptions, labData]);

    const onChangeDesignName = useCallback((e:ChangeEvent<HTMLInputElement>) => {
        dispatch(EditDesignName(e.target.value))
    }, [])

    const onBuild = useCallback(() => {
        setSaveState('generating');
    }, [])

    const onClose = useCallback(() => {
        setSaveState('none');
    }, [])

    const onSave = useCallback((productDesign: UserProductDesignStore, similarProducts?: { designData:Record<string, any>, previews: string[] }[]) => {
        setSaveState('done');
        setSavedProductDesign(productDesign);
        //setSimilarProducts(similarProducts)
    }, [])

    return <>
        <SaveBarContainer
            elevation={3}
        >
            <CustomizeContainer>
                <Typography
                    variant="h4"
                    style={{
                        marginBottom: '10px',
                    }}
                >{ t('Customize your product') }</Typography>

                <div style={{
                    display: 'flex'
                }}>
                    <TextField
                        variant="filled"
                        label={t('Name your product')}
                        value={designName}
                        onChange={onChangeDesignName}
                    />
                    <OptionSelectors/>
                </div>
            </CustomizeContainer>
            <BottomSection>
                <ValidIndicatorSection/>
                <SaveButtonContainer>
                    <SaveButton
                        disabled={!resolutionOk || !templatesCoveredOk || !optionsOk}
                        onBuild={onBuild}
                        onSave={onSave}
                        onError={onClose}
                    />
                </SaveButtonContainer>
            </BottomSection>
        </SaveBarContainer>

        <SaveDialog
            saveState={saveState}
            productDesign={savedProductDesign}
            similarProducts={similarProducts}
            onClose={onClose}
        />
    </>
}