import { useAppSelector } from '../../Common/_hooks/useAppSelector';

export default function AutoDesignOverlay() {
    const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));
    const autoDesign = useAppSelector(state => activeSubproduct ? state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct) : false);

    return autoDesign ? <div style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'rgba(220, 220, 220, 0.8)',
        borderRadius: '20px 20px 0 0',
    }} /> : null
}