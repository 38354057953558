import { useTranslation } from "react-i18next";
import PropertyPanel, { PanelCloseButton, PropertyH5, PropertyLabel, PropertyTextInput } from "./PropertyPanel";
import { useAppDispatch } from "../../Common/_hooks/useAppDispatch";
import { useAppSelector } from "../../Common/_hooks/useAppSelector";
import { EditLayer } from "../../UIData/_actions/DesignLabStoreActions";
import { HexColorPicker } from "react-colorful";
import CloseIcon from '@mui/icons-material/Close';
import parseColor from "../../Common/Utils/Color/parseColor";
import rgbToHex from "../../Common/Utils/Color/rgbToHex";
import { useCallback } from "react";

interface Props {
  layerId: string
  onClose: () => void
  opened?: boolean
}

export default function ColorPanel(props: Props) {
  const [t] = useTranslation('design-lab');
  const dispatch = useAppDispatch()
  const layerColor = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId)?.get('color') ?? '#000000');

  const parse = useCallback((value: string) => {
    try {
      const colors = parseColor(value);
      return rgbToHex(colors[0], colors[1], colors[2]);
    } catch (e) {
      //Default to black if invalid color
      return '#000000';
    }
  }, [])

  const onChange = useCallback((newColor: string) => {
    dispatch(EditLayer(props.layerId, {
      color: newColor
    }))
  }, [props.layerId])

  return <PropertyPanel elevation={4} className={props.opened ? 'opened' : ''}>
    <PanelCloseButton
      onClick={props.onClose}
    >
      <CloseIcon />
    </PanelCloseButton>
    <div style={{
      display: 'flex',
      height: '100%',
      gap: '10px',
    }}>
      <div>
        <PropertyH5>{t('Color')}</PropertyH5>
        <PropertyLabel htmlFor={"layer" + props.layerId + "_color"}>{t('Hex code')}</PropertyLabel>
        <PropertyTextInput
          id={"layer" + props.layerId + "_color"}
          value={layerColor}
          parse={parse}
          onChange={onChange}
        />
      </div>
      <HexColorPicker
        style={{
          height: 'auto',
        }}
        color={layerColor}
        onChange={onChange}
      />
    </div>
  </PropertyPanel>
}