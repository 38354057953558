import { useMemo } from 'react';
import { LAB_TEMPLATE_WIDTH, LAB_TEMPLATE_HEIGHT } from '../../config';
import useStageDimensions from './useStageDimensions';

export default function useStageOffset() {
    const { stageWidth, stageHeight } = useStageDimensions();

    const stageOffset = useMemo(() => {
        return {
            x: Math.ceil((stageWidth - LAB_TEMPLATE_WIDTH) / 2),
            y: Math.ceil((stageHeight - LAB_TEMPLATE_HEIGHT) / 2),
        }
    }, [stageWidth, stageHeight])

    return stageOffset;
}