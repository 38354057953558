ClothSimulator.MASS = 0.02;
ClothSimulator.DAMPING = 0.03;
ClothSimulator.DRAG = 1 - ClothSimulator.DAMPING;
ClothSimulator.REST_DISTANCE = 25;
ClothSimulator.TIMESTEP = 10/1000;
ClothSimulator.GRAVITY = new THREE.Vector3(0, -981, 0).multiplyScalar(ClothSimulator.MASS);
ClothSimulator.WIND_ENABLED = true;

function ClothSimulator(_geometry, _pins, _rotateGravity) {
	this.lastTime = null;
	this.geometry = _geometry;
	this.diff = new THREE.Vector3();
	this.pins = _pins;
	this.rotateGravity = typeof _rotateGravity !== 'undefined' ? _rotateGravity : false;
}
window.ClothSimulator = ClothSimulator;

// Static Cloth Generation Functions
ClothSimulator.getClothFunction = function(width, height){
	return ClothSimulator.clothFunction(width * ClothSimulator.REST_DISTANCE, height * ClothSimulator.REST_DISTANCE);
};

ClothSimulator.clothFunction = function(width, height) {
	return function(u, v, vector) {
		var result = vector || new THREE.Vector3();
		var x = (0.5 - u) * width;
		var y = (v - 0.5) * height;
		var z = 0;
		return result.set(x, y, z);
	};
};

ClothSimulator.cloth = function(w, h) {
	w = w || 10;
	h = h || 10;
	this.w = w;
	this.h = h;
	var particles = [];
	var constraints = [];
	var u, v;
	// Create particles
	for(v = 0; v <= h; v++) {
		for(u = 0; u <= w; u++) {
			particles.push(new ClothSimulator.Particle(u/w, v/h, 0, ClothSimulator.MASS, w, h));
		}
	}
	// Structural
	for(v = 0; v < h; v++) {
		for(u = 0; u < w; u++) {
			constraints.push([
				particles[index(u, v)],
				particles[index(u, v + 1)],
				ClothSimulator.REST_DISTANCE
			]);
			constraints.push([
				particles[index(u, v)],
				particles[index(u + 1, v)],
				ClothSimulator.REST_DISTANCE
			]);
		}
	}
	for(u = w, v = 0; v < h; v++) {
		constraints.push([
			particles[index(u, v)],
			particles[index(u, v + 1)],
			ClothSimulator.REST_DISTANCE
		]);
	}
	for(v = h, u = 0; u < w; u ++) {
		constraints.push([
			particles[index(u, v)],
			particles[index(u + 1, v)],
			ClothSimulator.REST_DISTANCE
		]);
	}
	this.particles = particles;
	this.constraints = constraints;
	function index(u, v) {
		return u + v * ( w + 1 );
	}
	this.index = index;
};

// Simulation Code
ClothSimulator.prototype.simulate = function(modelViewer){
	var me = this; 
	var time = Date.now();

	// Wind Math
	var cameramod = new THREE.Vector3(Math.cos(modelViewer.controls.rotationAngle) - 1, 0, Math.sin(modelViewer.controls.rotationAngle));
	var axis = new THREE.Vector3( 0, 1, 0 );
	var angle = modelViewer.camera.rotation.z + Math.PI/2;
	cameramod.applyAxisAngle(axis, angle);
	var windStrength = Math.cos(time / 7000) * 5 + 10;
	var windForce = new THREE.Vector3( 0, 0, 0 );
	windForce.set(Math.sin(time / 2000) / 5, Math.cos(time / 3000) / 5, Math.sin(time / 1000) / 25);
	windForce.sub(cameramod.multiplyScalar(25));
	windForce.multiplyScalar(windStrength);
	var i, il, particles, particle, pt, constraints, constraint;
	var tmpForce = new THREE.Vector3();

	// Wind
	var face, faces = me.geometry.faces, normal;
	particles = me.cloth.particles;
	for (i = 0, il = faces.length; i < il; i ++) {
		face = faces[i];
		normal = face.normal;
		tmpForce.copy(normal).normalize().multiplyScalar(normal.dot(windForce));
		particles[face.a].addForce(tmpForce);
		particles[face.b].addForce(tmpForce);
		particles[face.c].addForce(tmpForce);
	}

	// Gravity
	particles = me.cloth.particles;
	for (i = 0, il = particles.length; i < il; i ++) {
		particle = particles[i];
		var g = ClothSimulator.GRAVITY;
		if(me.rotateGravity) g = new THREE.Vector3(g.y, 0, 0);
		particle.addForce(g);
		particle.integrate(ClothSimulator.TIMESTEP * ClothSimulator.TIMESTEP);
	}

	// Constraints
	constraints = me.cloth.constraints;
	il = constraints.length;
	for (i = 0; i < il; i ++) {
		constraint = constraints[i];
		me.satisfyConstraints(constraint[0], constraint[1], constraint[2]);
	}

	// Pins
	particles = me.cloth.particles;
	for (i = 0, il = me.pins.length; i < il; i ++) {
		var xy = me.pins[i];
		var p = particles[xy];
		p.position.copy(p.original);
		p.previous.copy(p.original);
	}
};

ClothSimulator.prototype.satisfyConstraints = function(p1, p2, distance) {
	this.diff.subVectors(p2.position, p1.position );
	var currentDist = this.diff.length();
	if (currentDist === 0) return; // prevents division by 0
	var correction = this.diff.multiplyScalar(1 - distance / currentDist);
	var correctionHalf = correction.multiplyScalar(0.5);
	p1.position.add(correctionHalf);
	p2.position.sub(correctionHalf);
};

// Particle Code
ClothSimulator.Particle = function(x, y, z, mass, w, h) {
	this.clothFunction = ClothSimulator.getClothFunction(w, h);
	this.position = this.clothFunction(x, y);
	this.previous = this.clothFunction(x, y);
	this.original = this.clothFunction(x, y);
	this.a = new THREE.Vector3(0, 0, 0);
	this.mass = mass;
	this.invMass = 1 / mass;
	this.tmp = new THREE.Vector3();
	this.tmp2 = new THREE.Vector3();
};

ClothSimulator.Particle.prototype.addForce = function(force) {
	this.a.add(this.tmp2.copy(force).multiplyScalar(this.invMass));
};

ClothSimulator.Particle.prototype.integrate = function(timesq) {
	var newPos = this.tmp.subVectors(this.position, this.previous);
	newPos.multiplyScalar(ClothSimulator.DRAG).add(this.position);
	newPos.add(this.a.multiplyScalar(timesq));
	this.tmp = this.previous;
	this.previous = this.position;
	this.position = newPos;
	this.a.set(0, 0, 0);
};