import { ChangeEvent, useCallback, useMemo } from 'react';
import { Checkbox, FormControlLabel, styled } from "@mui/material"
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WebsiteStore from '../../WebsiteStore';
import { EditAutoDesignSubproduct } from '../../UIData/_actions/DesignLabStoreActions';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

const ToolFormControlLabel = styled(FormControlLabel)({
    height: '40px',
    borderRadius: '30px',
    background: '#00aced',
    color: '#fff',
    padding: '0 15px 0 5px',
    marginRight: '0px',
})

type Props = {}

export default function AutoDesignTool(props:Props) {
    const dispatch = useAppDispatch();
    const [t] = useTranslation('design-lab');
    const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
    const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));
    const activeSubproduct = useAppSelector(state => state.get('UIData').get('designLab').get('activeSubproduct'));
    const autoDesign = useAppSelector(state => activeSubproduct ? state.get('UIData').get('designLab').get('autoDesignSubproducts').get(activeSubproduct) : false);

    const available = useMemo(() => {
        const productLabData = productData?.get('labData');
        if(!productLabData) return false;

        return productLabData.has_subproducts && activeSubproduct !== Object.keys(productLabData.subproducts)[0];
    }, [productData, activeSubproduct]);

    const onChange = useCallback((e:ChangeEvent<HTMLInputElement>) => {
        if(!activeSubproduct) return;
        dispatch(EditAutoDesignSubproduct(activeSubproduct, e.target.checked));
    }, [activeSubproduct])

    return available ? <ToolFormControlLabel
            label={t('Auto design')}
            value={true}
            control={<Checkbox 
                checked={Boolean(autoDesign)}
                onChange={onChange}
                sx={{
                    color: '#fff',
                    '&.Mui-checked': {
                        color: '#fff'
                    }
                }}
            />}
        /> : null
}