import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CanvasTexture } from 'three';
import ModelViewerCanvas from './ModelViewerCanvas';
import ProductTextureContext from './_contexts/ProductTextureContext';
import ModelViewerTextureUpdater from './ModelViewerTextureUpdater';
import { LabData } from '../AppData/LabData';

interface Props {
    renderTexture: () => HTMLCanvasElement | OffscreenCanvas | HTMLImageElement | undefined
    viewerId: string,
    productId: number,
    labData: LabData,
    options:Immutable.Map<string,string>
    variant: string,
}

export default function ModelViewer(props:Props) {
    const [productTexture,setProductTexture] = useState<CanvasTexture|null>(null)
    const fxTexture = useRef<CanvasTexture>()

    const setFxTexture = useCallback((texture:CanvasTexture) => {
        fxTexture.current = texture;
    }, [])
    
    const textureContextValue:[CanvasTexture,CanvasTexture|undefined] = useMemo(() => {
        return [productTexture ?? new CanvasTexture(new OffscreenCanvas(1024, 1024)), fxTexture.current];
    }, [productTexture])

    if(!props.labData) {
        return null;
    }

    return <ProductTextureContext.Provider value={textureContextValue}>
        <ModelViewerTextureUpdater
            productId={props.productId}
            labData={props.labData}
            options={props.options}
            variant={props.variant}
            renderTexture={props.renderTexture}
            setProductTexture={setProductTexture}
            setFxTexture={setFxTexture}
        />
        <ModelViewerCanvas
            viewerId={props.viewerId}
            productId={props.productId}
            labData={props.labData}
            options={props.options}
            variant={props.variant}
        />
    </ProductTextureContext.Provider>
}