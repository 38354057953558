import { isBrowser } from 'browser-or-node'

export default (fps: number) => (() => {
    if(!isBrowser) {
        return (callback:(timestamp:number) => void) => {
            callback(Date.now());
        }
    }

    //@ts-ignore
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame ||
    function(callback:(timestamp:number) => number){
        return window.setTimeout(callback.bind(null, Date.now()), 1000 / fps);
    }
})()