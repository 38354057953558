import { West as WestIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { Button, CssBaseline, FormControl, GlobalStyles, InputBase, MenuItem, Paper, Select, SelectChangeEvent, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { GetProductLabData } from '../../UIData/_actions/DesignLabActions';
import { ClearLab, EditActiveDesign, EditActiveOption, EditActiveProduct, EditActiveVariant, EditAutoDesignSubproduct, EditDesignName, EditLayer, EditMirrorMode, ResetActiveOptions, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import { EditLoader } from '../../UIData/_actions/UIDataActions';
import { FetchOneItem } from '../../UserData/_actions/UserDataActions';
import { LAB_FONTS } from '../../config';
import FabricLab from '../FabricLab/_components/FabricLab';
import AutoDesignOverlay from './AutoDesignOverlay';
import AutoDesignTool from './AutoDesignTool';
import ExitLab from './ExitLab';
import FullPageLabLoader from './FullPageLabLoader';
import LabStage from '../Stage/LabStage';
import MirrorTool from './MirrorTool';
import PreviewViewer from './PreviewViewer';
import ProductSelector from '../ProductSelector/ProductSelector';
import SaveBar from '../Save/SaveBar';
import SceneSelector from '../SceneSelector/SceneSelector';
import SubproductSelector from '../SubproductSelector/SubproductSelector';
import ZoomControls from './ZoomControls';
import IconButton from '../../Common/Buttons/IconButton';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';
import LayerSection from '../Layer/LayerSection';
import LabFabricViewer from '../FabricLab/_components/LabFabricViewer';
import CookieConsent from '../../Header/CookieConsent';
import useRenderingTrace from '../../Common/_hooks/useRenderingTrace';
import ProductVariantStore from '../../AppData/_stores/ProductVariantStore';
import VariantSelector from './VariantSelector';
import useLabInit from '../_hooks/useLabInit';
import LabDevTool from '../DevTool/LabDevTool';


const Interface = styled('div')({
  display: 'flex',
  flex: '1',
  transition: 'opacity 300ms',

  '&.hidden': {
    opacity: 0,
  },

  '*': {
    userSelect: 'none',
    userDrag: 'none',
  }
})

const PreviewScreen = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  transition: 'transform 300ms, opacity 300ms',

  '&.hidden': {
    transform: 'translateX(100px)',
  },

  ['@media (max-width: ' + theme.breakpoints.values.lab_mobile + 'px), (max-height: ' + theme.height_breakpoints.values.lab_mobile + 'px)']: {
    position: 'absolute',
    top: '74px',
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    pointerEvents: 'none',

    '&.active': {
      opacity: 1,
      pointerEvents: 'auto',
    },

    ['@media (max-height: ' + theme.height_breakpoints.values.lab_mobile + 'px) and (min-width: 700px)']: {
      flexDirection: 'row',
      alignItems: 'center',
    }
  }
}));

const TemplateTools = styled('div')({
  position: 'absolute',
  top: '20px',
  right: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '10px',
})

const BackToTemplateButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  left: '20px',
  zIndex: 30,
  display: 'none',
  backgroundColor: '#05aced',
  color: '#fff',
  borderRadius: '30px',
  fontWeight: 400,
  alignItems: 'center',
  gap: '10px',
  padding: '10px 20px 10px 15px',

  '&:hover': {
    backgroundColor: '#05aced',
  },

  ['@media (max-width: ' + theme.breakpoints.values.lab_mobile + 'px), (max-height: ' + theme.height_breakpoints.values.lab_mobile + 'px)']: {
    display: 'flex',
  },
}));

export const LabWrapper = styled('div')({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',

  '&:before': {
    content: '""',
    height: '150px',
    width: '150px',
    borderRadius: '50%',
    border: '20px solid #7FD3F3',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    right: '-70px',
    top: '-30px',
  },

  '&:after': {
    content: '""',
    height: '150px',
    width: '150px',
    border: '20px solid #FCF57F',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    left: '-80px',
    top: 0,
    bottom: 0,
    margin: 'auto',
    transform: 'rotate(-20deg)',
  }
})

const StageContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  transition: 'transform 300ms, opacity 300ms',
  position: 'relative',
  opacity: 1,

  '&.hidden': {
    transform: 'translateY(100px)',
    opacity: 0,
  },

  '@media (max-width: 1510px)': {
    paddingLeft: '15px',
  },

  ['@media (max-width: ' + theme.breakpoints.values.lab_mobile + 'px), (max-height: ' + theme.height_breakpoints.values.lab_mobile + 'px)']: {
    paddingRight: '15px',
    transform: 'translateY(100px)',
    opacity: 0,

    '&.active': {
      opacity: 1,
      transform: 'none',
    }
  },
}));

export const LeftContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,

  ['@media (max-width: 1510px) and (min-height: ' + theme.height_breakpoints.values.lab_mobile + 'px), (max-height: 1070px) and (max-width: 953px)']: {
    flexDirection: 'column',
  },

  ['@media (max-width: ' + theme.breakpoints.values.lab_mobile + 'px), (max-height: ' + theme.height_breakpoints.values.lab_mobile + 'px)']: {
    pointerEvents: 'none',

    '&.active': {
      pointerEvents: 'auto',
    },
  }
}));

const SeePreviewButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: '15px',
  zIndex: 30,
  padding: '6px 20px',
  display: 'none',
  background: 'linear-gradient(0deg, #DA3192 0%, #F600BE 100%)',
  color: '#fff',
  borderRadius: '30px',
  fontWeight: 400,

  ['@media (max-width: ' + theme.breakpoints.values.lab_mobile + 'px), (max-height: ' + theme.height_breakpoints.values.lab_mobile + 'px)']: {
    display: 'flex',
    alignItems: 'center',
  },

  '@media (max-width: 953px)': {
    bottom: '15px',
    top: 'auto',
    margin: 'auto',
    left: 0,
    right: 0,
    width: 'fit-content',
  },
}));

const ArrowWhite = styled('img')({
  marginLeft: '15px',

  '@media (max-width: 1200px), (max-height: 900px)': {
    width: '30px',
  },
})

export default function LabInterface() {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('design-lab');

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedDesignId = useMemo(() => {
    return searchParams.has('design') ? searchParams.get('design') : undefined;
  }, [searchParams])

  const isEditing = useMemo(() => {
    return searchParams.has('design');
  }, [searchParams]);

  const designLoader = useAppSelector(state => state.get('UIData').get('loaders').get('design-lab_design'));
  const isFabricLab = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId') === 50);
  const hasLabData = useAppSelector(state => Boolean(state.get('appData').get('products').get(String(state.get('UIData').get('designLab').get('activeProductId')))?.get('labData')));
  const activeDesignId = useAppSelector(state => state.get('UIData').get('designLab').get('activeDesignId'));
  const productDesign = useAppSelector(state => selectedDesignId ? state.get('userData').get('productDesigns').get('data').get(selectedDesignId) : undefined);
  const labState = useAppSelector(state => state.get('UIData').get('designLab').get('state'))

  const hide = useMemo(() => {
    return labState !== 'lab'
  }, [labState])

  const [screenState, setScreenState] = useState<'lab' | 'preview'>('lab');

  const isLoadingDesign = useMemo(() => {
    return isEditing && (
      !activeDesignId ||
      !productDesign ||
      designLoader
    )
  }, [isEditing, activeDesignId, productDesign, designLoader])

  useLabInit()

  const onClickSeePreview = useCallback(() => {
    setScreenState('preview');
  }, [])

  const onClickSeeLab = useCallback(() => {
    setScreenState('lab');
  }, [])

  return <HotkeysProvider initiallyActiveScopes={['design-lab']}>
    {hasLabData ? <Interface className={hide || isLoadingDesign ? 'hidden' : ''}>
      {!isFabricLab ? <LeftContainer className={screenState === 'lab' ? 'active' : ''}>
        <LayerSection
          hidden={hide}
          active={screenState === 'lab'}
        />

        <StageContainer className={hide ? 'hidden' : (screenState === 'lab' ? 'active' : '')}>
          <SubproductSelector />
          <SceneSelector />

          <SeePreviewButton
            onClick={onClickSeePreview}
          >{t('Next')}<ArrowWhite src={require('@resources/img/create/design-lab-v4/icons/white-arrow.svg?url')} /></SeePreviewButton>

          <LabWrapper>
            <Paper
              elevation={3}
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '20px 20px 0 0',
                overflow: 'hidden',
              }}
            >
              <LabStage />
              <AutoDesignOverlay />
            </Paper>
            <TemplateTools>
              <AutoDesignTool />
              <MirrorTool />
            </TemplateTools>
            <ZoomControls />
          </LabWrapper>
        </StageContainer>
      </LeftContainer> : <FabricLab
        hide={hide}
        active={screenState === 'lab'}
        onNext={onClickSeePreview}
      />}

      <PreviewScreen
        className={hide ? 'hidden' : (screenState === 'preview' ? 'active' : '')}
        style={{
          flexGrow: 0,
        }}
      >
        <BackToTemplateButton
          onClick={onClickSeeLab}
        ><WestIcon />{t('Back to Template')}</BackToTemplateButton>

        {!isFabricLab
          ? <PreviewViewer />
          : <LabFabricViewer />}
        <SaveBar />
      </PreviewScreen>
    </Interface > : null}
    <FullPageLabLoader hide={(hasLabData && !isLoadingDesign) || hide} />
  </HotkeysProvider>
}