import { useCallback, useEffect, useMemo } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import Point from '../../Common/Utils/Math/Point';
import { EditActiveSubproduct, EditAutoDesignSubproduct, EditWarnings, RemoveLayer, SelectLayer } from '../../UIData/_actions/DesignLabStoreActions';
import WebsiteStore from '../../WebsiteStore';
import useCheckDpi from '../_hooks/useCheckDpi';
import useCheckWarningPoint from '../_hooks/useCheckWarningPoint';
import useLabData from '../_hooks/useLabData';
import { SceneTabsContainer } from '../SceneSelector/SceneSelector';
import ValidIndicator from '../_components/ValidIndicator';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import { ButtonTab } from '../SceneSelector/SceneTab';
import SubproductTab from './SubproductTab';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

type Props = {}

export default function SubproductSelector(props: Props) {
  const dispatch = useAppDispatch();

  const activeProductId = useAppSelector(state => state.get('UIData').get('designLab').get('activeProductId'));
  const activeVariant = useAppSelector(state => state.get('UIData').get('designLab').get('activeVariant'));
  const productData = useAppSelector(state => state.get('appData').get('products').get(String(activeProductId)));
  const layers = useAppSelector(state => state.get('UIData').get('designLab').get('layers'));
  const autoDesignSubproducts = useAppSelector(state => state.get('UIData').get('designLab').get('autoDesignSubproducts'));
  const labData = useLabData();
  const checkWarningPoint = useCheckWarningPoint();
  const checkDpi = useCheckDpi();

  //Cleanup layers for scenes that don't exist when switching to a new product
  useEffect(() => {
    const baseLabData = productData?.get('labData');
    if (!baseLabData) return;

    if (!baseLabData.has_subproducts) {
      layers.forEach(layer => {
        if (layer.subproduct) {
          dispatch(RemoveLayer(layer.get('id')));
        }
      });
      return;
    }

    const tmpSubproducts = Object.keys(baseLabData.subproducts);

    layers.forEach(layer => {
      if (!layer.subproduct || !tmpSubproducts.includes(layer.subproduct)) {
        dispatch(RemoveLayer(layer.get('id')));
      }
    });
  }, [productData]);

  const subproducts = useMemo(() => {
    const listSubproducts = productData?.get('subproducts');
    if(!listSubproducts || listSubproducts.count() === 0) return [];

    //TODO: This will require some change if we implement subproducts based on multiple option types
    let subproductTabs:{slug:string, name:string}[] = [];
    listSubproducts.forEach(subproduct => {
      const optionType = productData?.get('availableOptionTypes').get(String(subproduct.get('id_product_option_type')))
      if(!optionType) return;

      optionType.get('options').forEach(option => {
        subproductTabs.push({
          slug: option.get('slug'),
          name: option.get('name'),
        })
      })
    });

    return subproductTabs;
  }, [productData]);

  useEffect(() => {
    if (subproducts.length < 1) return;

    dispatch(EditActiveSubproduct(subproducts[0].slug));

    subproducts.forEach((subproduct, index) => {
      dispatch(EditAutoDesignSubproduct(subproduct.slug, !(index === 0)));
    })
  }, [subproducts]);

  useEffect(() => {
    const baseLabData = productData?.get('labData');
    if (!baseLabData?.has_subproducts) return;

    let tmpWarnings: Record<string, Record<string, boolean>> = {};
    subproducts.forEach(subproduct => {
      tmpWarnings[subproduct.slug] = {
        'templates_covered': false,
        'resolution': true,
      };
      let templatesCoveredOk = true;
      let resolutionOk = true;

      //Ignore checks if autoDesign is on for that subproduct
      if (!autoDesignSubproducts.get(subproduct.slug)) {
        //Check templates_covered
        baseLabData.subproducts[subproduct.slug].originalData.variants[activeVariant].scenes['main'].warnings.forEach((warning: Point) => {
          templatesCoveredOk = templatesCoveredOk && checkWarningPoint(warning, 'main', subproduct.slug);
        });

        //Check resolution
        layers.forEach(layer => {
          if (layer.get('subproduct') !== subproduct.slug) {
            return;
          }

          const { dpiIndicator } = checkDpi(layer);

          resolutionOk = resolutionOk && dpiIndicator !== 'bad';
        })
      }

      tmpWarnings[subproduct.slug].templates_covered = templatesCoveredOk;
      tmpWarnings[subproduct.slug].resolution = resolutionOk;
    })

    dispatch(EditWarnings(tmpWarnings));
  }, [subproducts, productData, layers, activeVariant, autoDesignSubproducts, checkWarningPoint, checkDpi]);

  const renderSubproduct = useCallback((subproduct: { slug: string, name: string }) => {
    return <SubproductTab
      key={subproduct.slug}
      slug={subproduct.slug}
      name={subproduct.name}
    />
  }, [])

  return productData?.get('labData')?.has_subproducts ? <SceneTabsContainer>
    {subproducts.map(renderSubproduct)}
  </SceneTabsContainer> : null;
}