import { Grid, IconButton, Menu, MenuItem, Select, Tooltip, styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DesignLabLayerStore, { LayerPatternMode } from '../../UIData/_stores/DesignLab/DesignLabLayerStore';
import { useDispatch } from 'react-redux';
import { EditLayer } from '../../UIData/_actions/DesignLabStoreActions';
import { Close as CloseIcon } from '@mui/icons-material';
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch';
import PropertyPanel, { PropertyColumnWrap, PropertyH5 } from './PropertyPanel';
import { useAppSelector } from '../../Common/_hooks/useAppSelector';

type RepeatMode = 'none'|'repeat'|'repeat-x'|'repeat-y'|'half-drop'|'half-brick';
type MirrorMode = 'none'|'mirror'|'mirror-x'|'mirror-y';

const REPEAT_MODES:RepeatMode[] = [
    'none',
    'repeat',
    'repeat-x',
    'repeat-y',
    'half-drop',
    'half-brick',
];

const MIRROR_MODES:Record<string, MirrorMode[]> = {
    none: [],
    repeat: ['none','mirror','mirror-x','mirror-y'],
    'repeat-x': ['none','mirror-x'],
    'repeat-y': ['none','mirror-y'],
    'half-drop': [],
    'half-brick': [],
};

const RepeatButton = styled(IconButton)({
    background: 'rgba(255, 255, 255, 0.5)',
    width: 33,
    height: 33,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
        background: 'rgba(255, 255, 255, 0.75)',
    },

    '&.active': {
        background: 'rgba(255, 255, 255, 1)',
    }
})

const ButtonContainer = styled('div')({
    display: 'flex',
    gap: 5,
    flexWrap: 'wrap',
})

const PanelCloseButton = styled(IconButton)({
    position: 'absolute',
    top: '-6px',
    right: '-6px',
    color: '#fff',
})

type Props = {
    layerId: string
    opened?: boolean
    onOpen?: () => void
    onClose?: () => void
}

export default function RepeatTool(props: Props) {
    const [t] = useTranslation('design-lab');
    const dispatch = useAppDispatch();

    const layerPatternMode = useAppSelector(state => state.get('UIData').get('designLab').get('layers').get(props.layerId)?.get('patternMode') ?? 'none');
    
    const repeatMode = useMemo(() => {
        const patternMode = layerPatternMode;

        if(['repeat','repeat-mirror','repeat-mirror-x','repeat-mirror-y'].includes(patternMode)) {
            return 'repeat';
        }

        if(['repeat-x','repeat-x-mirror-x'].includes(patternMode)) {
            return 'repeat-x';
        }

        if(['repeat-y','repeat-y-mirror-y'].includes(patternMode)) {
            return 'repeat-y';
        }

        return patternMode
    }, [layerPatternMode]);

    const mirrorMode = useMemo(() => {
        const patternMode = layerPatternMode;

        if(['none','repeat','repeat-x','repeat-y','half-drop','half-brick'].includes(patternMode)) {
            return 'none'
        }

        if(['repeat-mirror'].includes(patternMode)) {
            return 'mirror';
        }

        if(['repeat-mirror-x','repeat-x-mirror-x'].includes(patternMode)) {
            return 'mirror-x';
        }

        if(['repeat-mirror-y','repeat-y-mirror-y'].includes(patternMode)) {
            return 'mirror-y';
        }

        return patternMode;
    }, [layerPatternMode]);

    const editRepeat = useCallback((repeat:LayerPatternMode) => {
        dispatch(EditLayer(props.layerId, {
            patternMode: repeat
        }))
    }, [props.layerId]);

    const repeatModeNames = useMemo(() => {
        return {
            'none': t('No repeat'),
            'repeat': t('Block'),
            'repeat-x': t('Horizontal'),
            'repeat-y': t('Vertical'),
            'half-drop': t('Half-Drop'),
            'half-brick': t('Half-Brick'),
        }
    }, [t]);

    const mirrorModeNames = useMemo(() => {
        return {
            'none': t('No mirror'),
            'mirror': t('Full Mirror'),
            'mirror-x': t('Mirror Horizontal'),
            'mirror-y': t('Mirror Vertical'),
        }
    }, [t]);

    const onRepeatClick:Record<LayerPatternMode, () => void> = useMemo(() => {
        let tmpList:Record<string, () => void> = {};
        REPEAT_MODES.forEach(mode => {
            tmpList[mode] = () => editRepeat(mode);
        });
        return tmpList;
    }, [editRepeat]);

    const onMirrorClick:Record<MirrorMode, () => void> = useMemo(() => {
        let tmpList:Record<string, () => void> = {};
        
        MIRROR_MODES[repeatMode].forEach(mode => {
            tmpList[mode] = () => editRepeat(repeatMode+(mode !== 'none' ? '-'+mode:'') as LayerPatternMode);
        });
        return tmpList;
    }, [editRepeat, repeatMode]);

    const renderRepeatMode = useCallback((mode:RepeatMode) => {
        const active = mode === repeatMode ? 'active':'';
        return <Tooltip
            key={mode}
            title={repeatModeNames[mode]}
            placement="bottom"
            disableInteractive
        >
            <RepeatButton 
                onClick={onRepeatClick[mode]}
                className={mode === repeatMode ? 'active':''}
            >
                <img src={require('@resources/img/create/design-lab-v4/icons/repeat/'+mode+(active ? '-'+active:'')+'.svg?url')}/>
            </RepeatButton>
        </Tooltip>
    }, [repeatMode, onRepeatClick, repeatModeNames]);

    const renderMirrorMode = useCallback((mode:MirrorMode) => {
        const iconName = mode === 'none' ? 'none' : repeatMode+'-'+mode;
        const active = mode === mirrorMode ? 'active':'';
        return <Tooltip
            key={iconName}
            title={mirrorModeNames[mode]}
            placement="bottom"
            disableInteractive
        >
            <RepeatButton 
                onClick={onMirrorClick[mode]}
                className={active}
            >
                <img src={require('@resources/img/create/design-lab-v4/icons/repeat/'+iconName+(active ? '-'+active:'')+'.svg?url')}/>
            </RepeatButton>
        </Tooltip>
    }, [repeatMode, mirrorMode, onMirrorClick, mirrorModeNames]);

    return <>
        <Tooltip
            title={t('Pattern Repeat')}
        >
            <IconButton
                onClick={props.onOpen}
                style={{
                    width: 40,
                    height: 40,
                    border: '1px solid rgba(0, 0, 0, 0.1)'
                }}
            ><img src={require('@resources/img/create/design-lab-v4/icons/repeat/'+layerPatternMode+(layerPatternMode !== 'none' ? '-active':'')+'.svg?url')}/></IconButton>
        </Tooltip>
        <PropertyPanel
            elevation={4}
            className={props.opened ? 'opened':''}
            style={{
                paddingRight: 0,
            }}
        >
            <PanelCloseButton
                onClick={props.onClose}
                size="large"
            >
                <CloseIcon/>
            </PanelCloseButton>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <PropertyColumnWrap className="left">
                        <PropertyH5>{ t('Repeat') }</PropertyH5>
                        <ButtonContainer>
                            { REPEAT_MODES.map(renderRepeatMode) }
                        </ButtonContainer>
                    </PropertyColumnWrap>
                </Grid>
                <Grid item xs={6}>
                    <PropertyColumnWrap className="right">
                        { MIRROR_MODES[repeatMode].length > 0 ? <>
                            <PropertyH5>{ t('Mirror') }</PropertyH5>
                            <ButtonContainer>
                                { MIRROR_MODES[repeatMode].map(renderMirrorMode) }
                            </ButtonContainer>
                        </> : null }
                    </PropertyColumnWrap>
                </Grid>
            </Grid>
        </PropertyPanel>

    </>
}