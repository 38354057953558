import { Alert, Paper, Box, Button, Collapse, Divider, Grid, Stack, Typography, Link as MuiLink, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { ExpandLessRounded as ExpandLessRoundedIcon } from '@mui/icons-material'
import { useCallback, useMemo, useState } from 'react'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CartStack } from '../../Cart/CartContent/CartStack'
import { DateTime, type DateTimeFormatOptions } from 'luxon'
import tKey from '../../Common/Utils/tKey'
import { Link } from 'react-router-dom'
import type Immutable from 'immutable'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import CheckoutSummaryContent from './CheckoutSummaryContent'
import DineroFactory, { type Dinero } from 'dinero.js'
import Dialog from '../../Common/Dialog/Dialog'
import DialogTitle from '../../Common/Dialog/DialogTitle'
import DialogContent from '../../Common/Dialog/DialogContent'

const DELIVERY_DATE_STRING: DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric'
}

export interface Props {
  carts: Immutable.OrderedMap<string, UserCartStore>
  alert?: React.ReactNode
  action?: React.ReactNode
  disabled?: boolean
}

export default function CheckoutSummary(props: Props) {
  const [t] = useTranslation('cart')
  const [tR] = useTranslation('routes')

  const [summaryOpened, setSummaryOpened] = useState(false)
  const [holidayDialogOpened,setHolidayDialogOpened] = useState(false);

  const grandTotal = useMemo(() => {
    let grandTotal: Dinero | undefined
    for (const [, cart] of props.carts) {
      const price = {
        amount: cart.get('total').get('amount'),
        currency: cart.get('total').get('currency')
      }
      grandTotal = grandTotal ? grandTotal.add(DineroFactory(price)) : DineroFactory(price)
    }
    return grandTotal
  }, [props.carts])

  const firstCart = useMemo(() => {
    return props.carts.first()
  }, [props.carts])

  const [firstShipbyDate, firstArrivalDateMin, firstArrivalDateMax] = useMemo(() => {
    return [
      firstCart?.get('shipbyDate'),
      firstCart?.get('arrivalDateMin'),
      firstCart?.get('arrivalDateMax')
    ]
  }, [firstCart])

  const [hasMaxShipbyDate, hasVeryLargeOrder] = useMemo(() => {
    let hasMaxShipbyDate = false
    let hasVeryLargeOrder = false
    const now = DateTime.now()

    props.carts.forEach(cart => {
      const shipbyDate = cart.get('shipbyDate')
      if (shipbyDate && DateTime.fromISO(shipbyDate).diff(now).as('weeks') >= 4.9) {
        hasMaxShipbyDate = true
      }

      let totalQuantity = 0
      cart.get('items').forEach(item => {
        if (item.get('type') !== 'product') return
        totalQuantity += item.get('quantity')
      })

      if (totalQuantity >= 100) {
        hasVeryLargeOrder = true
      }
    })

    return [hasMaxShipbyDate, hasVeryLargeOrder]
  }, [props.carts])

  const onSummaryToggle = useCallback(() => {
    setSummaryOpened(value => !value)
  }, [])

  const onHolidayDialogOpen = useCallback(() => {
    setHolidayDialogOpened(true);
  }, [])

  const onHolidayDialogClose = useCallback(() => {
    setHolidayDialogOpened(false);
  }, [])

  // Stop here if there's no cart
  // TODO: Should probably show a 404 or something
  if (!firstCart) {
    return null
  }

  return <Grid item xs={12} md={4} lg={3}>
    <Stack
      spacing={2}
      direction={{
        xs: 'column-reverse',
        md: 'column'
      }}
    >
      <Paper
        elevation={0}
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          }
        }}
      >
        <CheckoutSummaryContent
          carts={props.carts}
          disabled={props.disabled}
        />
      </Paper>

      { props.action !== undefined ? <Box
        component="div"
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          },
          pb: 1
        }}
      >
        <Stack spacing={2} alignItems="center">
          {props.alert}
          {props.action}
        </Stack>
      </Box> : null }

      { firstCart.get('order_from') !== 'invoice' ? <Paper elevation={0}>
        <CartStack spacing={2}>
          <Typography variant="h3" color="primary">{t('Delivery estimates')}</Typography>

          {props.carts.count() > 1 ? <Stack spacing={2} divider={<Divider />}>
            {firstShipbyDate ? props.carts.valueSeq().map(cart => {
              const shipbyDate = cart.get('shipbyDate')
              if (!shipbyDate) return null

              const arrivalDateMin = cart.get('arrivalDateMin')
              const arrivalDateMax = cart.get('arrivalDateMax')

              return <Stack
                key={cart.get('id')}
                spacing={1}
              >
                <Typography variant="h4">{cart.get('cart_name')}</Typography>
                <Typography variant="body2">
                  {t('Expected to ship on: ')}<strong>{DateTime.fromISO(shipbyDate).toLocaleString(DELIVERY_DATE_STRING).replace(' ', '\u00a0')}</strong>
                </Typography>
                <Typography variant="body2">
                  {arrivalDateMin && arrivalDateMax ? <>
                    {t('Should arrive between: ')}
                    <strong>{arrivalDateMin ? DateTime.fromISO(arrivalDateMin).toLocaleString(DELIVERY_DATE_STRING).replace(' ', '\u00a0') : null}</strong>
                    {t(' and ')}
                    <strong>{arrivalDateMax ? DateTime.fromISO(arrivalDateMax).toLocaleString(DELIVERY_DATE_STRING).replace(' ', '\u00a0') : null}</strong>
                  </> : null}
                </Typography>
              </Stack>
            }) : <Typography variant="body2">{t('Proceed to checkout to get an estimate on shipping.')}</Typography>}
          </Stack> : firstShipbyDate ? <Stack spacing={1}>
            <Typography variant="body2">
              {t('Your order is expected to ship on: ')}<strong>{DateTime.fromISO(firstShipbyDate).toLocaleString(DELIVERY_DATE_STRING).replace(' ', '\u00a0')}</strong>
            </Typography>
            <Typography variant="body2">
              {firstArrivalDateMin && firstArrivalDateMax ? <>
                {t('Your order should arrive between: ')}
                <strong>{DateTime.fromISO(firstArrivalDateMin).toLocaleString(DELIVERY_DATE_STRING).replace(' ', '\u00a0')}</strong>
                {t(' and ')}
                <strong>{DateTime.fromISO(firstArrivalDateMax).toLocaleString(DELIVERY_DATE_STRING).replace(' ', '\u00a0')}</strong>
              </> : <>{t('Proceed to checkout to get an estimate on shipping.')}</>}
            </Typography>
          </Stack> : null}

          { /*<Alert
            severity="warning"
          >
            <Trans
              t={t}
              i18nKey={tKey('Due to the large volume of orders during the holidays, orders may take a bit longer. <a>Click here</a> to check out our holiday cut-off dates if you need to receive your order before December 25th.')}
              components={{
                a: <MuiLink component="button" onClick={onHolidayDialogOpen}/>
              }}
            />
          </Alert> */ }

          { hasVeryLargeOrder ? <Alert
            severity="warning"
          >
            <Trans
              t={t}
              i18nKey={tKey('For orders of over 100 items, it may take longer to ship than our estimate. If you have any questions or need a rush on your order, <a>contact us</a> and we\'ll do our best to help you.')}
              components={{
                a: <Link to={tR('/contact-us')} reloadDocument/>
              }}
            />
          </Alert> : (hasMaxShipbyDate ? <Alert
              severity="info"
            >
              <Trans
                t={t}
                i18nKey={tKey('If you need a rush on your order, <a>contact us</a> and we\'ll do our best to help you.')}
                components={{
                  a: <Link to={tR('/contact-us')} reloadDocument/>
                }}
              />
            </Alert> : null)
          }
        </CartStack>
      </Paper> : null }
    </Stack>

    <Box
      component="div"
      sx={{
        display: {
          xs: 'flex',
          md: 'none'
        },
        position: 'fixed',
        bottom: 0,
        zIndex: 10,
        left: '8px',
        right: '8px',
        maxHeight: '80vh'
      }}
    >
      <Paper
        elevation={16}
        sx={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          display: 'flex',
          flexDirection: 'column',
          flex: '1'
        }}
      >
        <Button
          color="secondary"
          fullWidth
          onClick={onSummaryToggle}
          sx={{
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0
          }}
        ><ExpandLessRoundedIcon
          fontSize="large"
          sx={{
            transform: summaryOpened ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 250ms'
          }}
        /></Button>
        <div style={{ overflow: 'auto', flex: 1 }}>
          <Stack
            spacing={2}
            sx={{
              alignItems: 'center',
              px: 2,
              pb: 2
            }}
          >
            <Stack sx={{ alignItems: 'center', alignSelf: 'stretch' }}>
              <Collapse
                in={summaryOpened}
                sx={{
                  alignSelf: 'stretch'
                }}
              >
                <CheckoutSummaryContent
                  carts={props.carts}
                  disabled={props.disabled}
                />
              </Collapse>

              <Collapse in={!summaryOpened}>
                <Typography variant="h3">{t('Total: ')}<Box component="span" sx={{ color: 'accent.main' }}>{grandTotal?.toFormat()}</Box></Typography>
              </Collapse>
            </Stack>
            { props.action !== undefined ? <Stack spacing={2} alignItems="center">
              { props.alert }
              { props.action }
            </Stack> : null }
          </Stack>
        </div>
      </Paper>
    </Box>

    { /*<Dialog
      open={holidayDialogOpened}
      maxWidth="sm"
      fullWidth
      onClose={onHolidayDialogClose}
      showCloseButton
    >
      <DialogTitle>{t('Holiday cut-off times')}</DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <Typography variant="body1">{t('Order before the following dates if you need to receive your order before December 25th.')}</Typography>

          <Stack spacing={1}>
            <Typography variant="h3">{t('Canada & US')}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Order size')}</TableCell>
                  <TableCell>{t('Regular shipping')}</TableCell>
                  <TableCell>{t('Express shipping')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{t('1 - 5 items')}</TableCell>
                  <TableCell>{DateTime.fromObject({year: 2024, month: 12, day: 5}).toLocaleString(DateTime.DATE_MED)}</TableCell>
                  <TableCell>{DateTime.fromObject({year: 2024, month: 12, day: 13}).toLocaleString(DateTime.DATE_MED)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('6 - 35 items')}</TableCell>
                  <TableCell>{DateTime.fromObject({year: 2024, month: 12, day: 1}).toLocaleString(DateTime.DATE_MED)}</TableCell>
                  <TableCell>{DateTime.fromObject({year: 2024, month: 12, day: 5}).toLocaleString(DateTime.DATE_MED)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('36+ items')}</TableCell>
                  <TableCell><em>{t('Date has passed')}</em></TableCell>
                  <TableCell><em>{t('Date has passed')}</em></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="h3">{t('International and Military addresses')}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t('Order size')}</TableCell>
                  <TableCell>{t('Regular shipping')}</TableCell>
                  <TableCell>{t('Express shipping')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{t('1 - 5 items')}</TableCell>
                  <TableCell><em>{t('Date has passed')}</em></TableCell>
                  <TableCell>{DateTime.fromObject({year: 2024, month: 12, day: 10}).toLocaleString(DateTime.DATE_MED)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('6 - 35 items')}</TableCell>
                  <TableCell><em>{t('Date has passed')}</em></TableCell>
                  <TableCell><em>{t('Date has passed')}</em></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t('36+ items')}</TableCell>
                  <TableCell><em>{t('Date has passed')}</em></TableCell>
                  <TableCell><em>{t('Date has passed')}</em></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog> */ }
  </Grid>
}
